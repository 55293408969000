import React, { useEffect, useRef, useState } from 'react'
import { Button, Col, Row, Table, Tag, Typography } from 'antd';
import Column from 'antd/es/table/Column';
import {useTranslation} from "react-i18next";
import axios from 'axios';
import { useQuery, useQueryClient } from 'react-query';
import { DASHBOARD_SECTION_TITLES } from '../common/utils/styles';
import dayjs from 'dayjs';
import { useBreakpoint } from 'use-breakpoint';
import { RightOutlined, LoadingOutlined, DownloadOutlined, CloseOutlined } from '@ant-design/icons';
import { Popup, SwipeAction, SwipeActionRef } from 'antd-mobile';

const BREAKPOINTS = { mobile: 0, tablet: 768, desktop: 1280 }

type Props = {}

const BillingHistory = (props: Props) => {

    const { t } = useTranslation();
    const { breakpoint, maxWidth, minWidth } = useBreakpoint(BREAKPOINTS);
    const queryClient = useQueryClient()

    const baseUrl = process.env.REACT_APP_BASE_URL

    const token = JSON.parse(localStorage.getItem("token") || '{}')

    const config = {
        headers: {Authorization: `Bearer ${token}`}
    }   

    const fetchData = async () => {
        const res: any = await axios.get(`${baseUrl}payment/get-all-user`, config)
        return res.data
      }

    const { data, status } = useQuery(`billingHistory`, fetchData, {suspense: false, initialData: undefined})

    //Show the pdf in a new tab
    const showPdf = async (id) => {
    await axios.get(`${baseUrl}payment/get-file/${id}`, {
      headers: {Authorization: `Bearer ${token}`},
      responseType: 'blob'
    }).then(response => {
    //Create a Blob from the PDF Stream
      const file = new Blob(
        [response.data],
        {type: 'application/pdf'});
        //Build a URL from the file
        const fileURL = URL.createObjectURL(file);
        //Open the URL on new Window
        window.open(fileURL);
    })
    .catch(error => {
        console.log(error);
    });
  };

    //Popup mobile
    const [popupHistoryVisible, setPopupHistoryVisible] = useState(false)
    const loadingIcon = <LoadingOutlined style={{ fontSize: 50, color: "#03b9ac" }} spin />;

    //Swipe Action
    const ref = useRef<SwipeActionRef>(null)


    return (
        <>
            {
            (breakpoint === "mobile" || breakpoint === "tablet") 
            ?
            <div onClick={() => setPopupHistoryVisible(true)} style={{display: "flex"}}>
                <Row style={{height: "135px", justifyContent: "center", paddingLeft: "25px", width: "98vw", backgroundColor: "#F2F0F0", borderRadius: "4px", display: "flex", flexDirection: "row", alignItems: "center"}}>
                    <Col span={22}>
                        <Row>
                            <Typography style={{fontWeight: "800", fontFamily: "Poppins"}}>{t("billing.billingHistory.title")}</Typography>
                        </Row>
                    </Col>
                    <Col span={2}>
                        <RightOutlined />
                    </Col>
                </Row>
                <Popup showCloseButton visible={popupHistoryVisible}  onMaskClick={() => setPopupHistoryVisible(false)} onClose={() => setPopupHistoryVisible(false)} bodyStyle={{ height: '87vh', overflow: "auto" }} mask={true} closeOnSwipe>
                    <div style={{ marginTop: "15px", paddingBottom: "15px"}}>
                        {
                        (data === undefined || data.lenght === 0 || status === "loading")
                        ?
                        <>{loadingIcon}</>
                        :
                        (data.length === 0 && status === "success") ?
                        <div style={{fontFamily: "Poppins", textAlign: "center", paddingTop: "30px"}}>{"No hay recibos"}</div>
                        :
                        <div style={{display: "flex", flexDirection: "column", gap: "15px", alignItems: "center"}}>
                            {
                            data.map((item: any) => (
                            <SwipeAction
                                ref={ref}
                                closeOnAction={false}
                                closeOnTouchOutside={false}
                                rightActions={
                                    [
                                        {
                                            key: 'download',
                                            text: (item.payment_status === "success" && (item.file_name !== undefined && item.file_name !== null)) ? <div style={{display: "flex", flexDirection: "column", alignItems: "center", gap: "15px"}}><DownloadOutlined style={{fontSize: "2rem"}}/><span>{t('billing.billingHistory.tableShowPdf')}</span></div> : <div style={{display: "flex", flexDirection: "column", alignItems: "center", gap: "15px"}}><CloseOutlined style={{fontSize: "2rem"}} /><span>{t('billing.billingHistory.receiptNotAvailable')}</span></div>,
                                            color: (item.payment_status === "success" && (item.file_name !== undefined && item.file_name !== null)) ? '#029389' : 'lightgrey',
                                            onClick: (item.payment_status === "success" && (item.file_name !== undefined && item.file_name !== null)) ? () => {showPdf(item.id)} : () => {},
                                        }
                                    ]
                                }
                            >
                                <Row style={{height: "130px", width: "95vw", backgroundColor: "#F2F0F0", borderRadius: "4px", display: "flex", justifyContent: "space-evenly", fontFamily: "Poppins"}}>
                                    <Col span={22} style={{fontFamily: "Poppins", display: "flex", flexDirection: "column", justifyContent: "space-evenly", paddingTop: "5px", paddingBottom: "5px"}}>
                                    <Row>
                                        <Typography style={{fontSize: "0.85rem", fontWeight: "600", fontFamily: "Poppins"}}>{t("billing.billingHistory.tableDate") + ":"}</Typography><Typography style={{fontSize: "0.85rem", fontWeight: "300", paddingLeft: "6px", fontFamily: "Poppins"}}>{<>{dayjs.unix(item.date_created).format("DD/MM/YYYY")}</>}</Typography>
                                    </Row>
                                    <Row>
                                        <Typography style={{fontSize: "0.85rem", fontWeight: "600", fontFamily: "Poppins"}}>{t("billing.billingHistory.tableDescription") + ":"}</Typography><Typography style={{fontSize: "0.85rem", fontWeight: "300", paddingLeft: "6px", fontFamily: "Poppins"}}>{item.description}</Typography>
                                    </Row>
                                    <Row>
                                        <Typography style={{fontSize: "0.85rem", fontWeight: "600", fontFamily: "Poppins"}}>{t("billing.billingHistory.tableAmount") + ":"}</Typography><Typography style={{fontSize: "0.85rem", fontWeight: "300", paddingLeft: "6px", fontFamily: "Poppins"}}>{Intl.NumberFormat( "es-AR", {style: "currency", currency: "ARS"}).format(item.transaction_amount)}</Typography>
                                    </Row>
                                    <Row>
                                        <Tag color={(item.payment_status === "success") ? "blue" : (item.payment_status === "failed") ? "red" : "grey"}>
                                            <span style={{textTransform: "uppercase", fontFamily: "Poppins", fontWeight: "600", paddingRight: "10px", paddingLeft: "10px"}}>{((item.payment_status === "success") ? "Completado" : (item.payment_status === "failed") ? "No completado" : "Cancelado")}</span>
                                        </Tag>
                                    </Row>
                                    </Col>
                                </Row>
                            </SwipeAction>
                            )
                            )}
                        </div>
                        }
                    </div>

                </Popup>
            </div>
            :
            <div className='sectionDiv' style={{width: "100%"}}>
                <Row style={{marginTop: "20px", marginBottom: "20px"}}>
                    <Col span={24}>
                        <Typography style={DASHBOARD_SECTION_TITLES}>{t("billing.billingHistory.title")}</Typography>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Table style={{overflow: "auto"}} dataSource={( data === undefined ) ? [] : data}  size="large">
                            <Column title={t("billing.billingHistory.tableDate")} dataIndex="date_created" key="date_created" render={(_: any, record: any) => <>{dayjs.unix(record.date_created).format("DD-MM-YYYY")}</>} />
                            <Column title={t("billing.billingHistory.tableDescription")} dataIndex="description" key="description" />
                            <Column title={t("billing.billingHistory.tableAmount")} dataIndex="transaction_amount" key="transaction_amount" render={(_:any, record: any) => <>{"$ " + record.transaction_amount}</>} />
                            <Column width={"70px"} title={t("billing.billingHistory.tableStatus")} dataIndex="payment_status" key="payment_status" render={(_: any, record: any) =>
                                <Tag color={(record.payment_status === "success") ? "blue" : (record.payment_status === "failed") ? "red" : "grey"}>
                                    {((record.payment_status === "success") ? "Completado" : (record.payment_status === "failed") ? "No completado" : "Cancelado")}
                                </Tag>} />
                            <Column title={t("billing.billingHistory.tableDownload")} dataIndex="download" key="download" render={(_: any, record: any) => 
                                ((record.payment_status === "success" && (record.file_name !== undefined && record.file_name !== null)) ?
                                <>
                                    <Button id='button-small-outline-green-bg' onClick={() => showPdf(record.id)}>{t("billing.billingHistory.tableShowPdf")}</Button>
                                </>
                                :
                                <></>)} />
                        </Table>
                    </Col>
                </Row>
            </div>
            }
        </>
    )
}

export default BillingHistory