import React, { useEffect, useState } from 'react'
import { DatePicker, Form, Popover, Select } from 'antd';
import { Option } from 'antd/es/mentions';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import useGetData from '../../hooks/useGetData';
import { useQueryClient } from 'react-query';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';



const Step1: React.FC<{ date?: any, userId?: any }> = (props) => {

    const { t } = useTranslation()
    const queryClient = useQueryClient()
    const form = useFormInstance()

    const date_not_timestamp = dayjs.unix(props.date?.date)

    const [refetchPointOfSale, setRefetchPointOfSale] = useState<number | boolean>(false)

    useEffect(() => {
        setRefetchPointOfSale(!refetchPointOfSale)
        if (queryClient.getQueryData("isFirstLogin")) setRefetchPointOfSale(5000)
    }, [])
    
    const disabledDate = (current: any) => {
        const tooEarlyFromDeclaredDate = dayjs(date_not_timestamp).subtract(9, 'day')
        const tooLateFromDeclaredDate = dayjs(date_not_timestamp).add(10, 'day')
        const tooEarly = dayjs().subtract(9, 'day')
        const tooLate = dayjs().add(10, 'day')
        if (props.date?.date === undefined)
            return (current < tooEarly.endOf('day')) || (current > tooLate.endOf('day'))
        else
            return (current < tooEarlyFromDeclaredDate.endOf('day')) || (current > tooLateFromDeclaredDate.endOf('day'))
      };


    const userData: any = queryClient.getQueryData("userData")
    const pointOfSaleData = useGetData((localStorage.getItem("role") === "admin") || queryClient.getQueryData("role") === "admin" ? `point-of-sale/by-user/${props.userId}` : "point-of-sale/me", "pointOfSale")
    const voucherTypesData = useGetData("information/voucher-types", "voucherType")
    const ivaConditionData = useGetData("information/iva-condition", "ivaCondition")
    const docTypesData: string[] = useGetData("information/doc-types", "docTypes" )
    const associatedVouchersTypes: string[] = useGetData("information/associated-voucher-types", "associatedVouchersTypes" )

    const conceptoData = ["Productos", "Servicios", "Productos y Servicios"]

    const stateData = ["pending", "completed", "inProccess"]

    const isDemo = () => {
        if (userData.subscription === null || userData.subscription.authorized === false) {
            if (localStorage.getItem("role") === "admin") return false
            else return true
        }
        else {
          if (
            (userData.subscription.status === "authorized" ||
              userData.subscription.status === "oneshoot" || userData.subscription.status === "manual") &&
            userData.subscription.authorized === true
          )
            return false;
          else return true;
        }
      }
    
    const popoverContent = (
        <div>
            <a>{t('vouchers.table.point_of_sale_popover_message') + ' '}</a><a href='/dashboard/user' style={{color: '#69b2a8'}}>{'aquí'}</a>
        </div>
    )
    
  return (
    <>  
        <Form.Item  hidden={queryClient.getQueryData("role") === "user" || localStorage.getItem("role") === "user" || form.getFieldValue("status") === "demo" } label={t("vouchers.form.status")} name="status">
            <Select style={{height: "58px"}}>
                {(stateData === undefined) ? <Option disabled></Option> : Object.values(stateData).map((data: any) => <Option key={data} value={data} >{data}</Option>)}
            </Select>
        </Form.Item>
        <Form.Item style={{fontFamily: "Dosis"}} initialValue={(isDemo()) ? 'demo' : ''} label={<Popover content={popoverContent}>{t("vouchers.form.point_of_sale")}</Popover>} name="point_of_sale" rules={[{ required: true, message: `${t("vouchers.form.point_of_sale")} ${" "} ${t("isRequired")}` }]}>
            <Select id='step3' style={{height: "58px"}} defaultValue={(isDemo()) ? 'demo' : ''}>
                {(isDemo()) ? <Option value='demo'>demo</Option> : (pointOfSaleData === undefined) ? [] : Object.values(pointOfSaleData).map((data: any) => <Option key={data.id} value={data.name} >{data.name}</Option>)}
            </Select>
        </Form.Item>
        <Form.Item label={t("vouchers.form.type")} name="type" rules={[{ required: true, message: `${t("vouchers.form.type")} ${" "} ${t("isRequired")}` }]}>
            <Select id='step4' style={{height: "58px"}} >
                {(voucherTypesData === undefined) ? <Option  disabled></Option> : Object.values(voucherTypesData).map((data: any) => <Option key={data} value={data} >{data}</Option>)}
            </Select>
        </Form.Item>
        <Form.Item initialValue={(props.date?.date === undefined) ? dayjs() : dayjs(date_not_timestamp)} label={t("vouchers.form.date")} name="date_not_formatted" rules={[{ required: true, message: "required" }]}>
            <DatePicker id='step5' disabledDate={disabledDate} format='DD-MM-YYYY' style={{height: "58px", fontFamily: "Dosis", fontSize: "18px", borderRadius: "4px"}}></DatePicker>
        </Form.Item>
        <Form.Item label={t("vouchers.form.concept_selector")} name="concept" rules={[{required: true, message: "Tienes que elegir un concepto" }]}>
            <Select id='step6' style={{height: "58px"}}>
                {conceptoData.map((data) => <Option key={data} value={data} >{data}</Option>)}
            </Select>
        </Form.Item>
  </>
  )
}

export default Step1