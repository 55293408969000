import { Button, Col, DatePicker, Drawer, Dropdown, Input, List, Modal, Row, Select, Space, Table, Tag, Tooltip, Typography, message } from 'antd';
import Column from 'antd/es/table/Column';
import axios from 'axios';
import dayjs from 'dayjs';
import React, { useEffect, useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { errorMessageLogin } from '../../../common/errors';
import { DASHBOARD_TITLES, RESUME_TITLES, RESUME_TITLES_WHITE, RESUME_VALUES, RESUME_VALUES_WHITE } from '../../../components/common/utils/styles';
import { CaretUpOutlined, CaretDownOutlined, LoadingOutlined, LoginOutlined, EyeOutlined, EllipsisOutlined } from '@ant-design/icons';
import duration from 'dayjs/plugin/duration';
import useGetData from '../../../hooks/useGetData';

function Users() {
    const queryClient = useQueryClient();

    //Notas
    const [notes, setNotes] = useState<any>()
    const [items, setItems] = useState<any>()
    const [editingIndex, setEditingIndex] = useState<number | null>(null);
    const [editValue, setEditValue] = useState('');
  
    const handleEditClick = (index: number) => {
      setEditingIndex(index);
      setEditValue(items[index]);
    };
  
    const handleCancelClick = () => {
      setEditingIndex(null);
      setEditValue('');
    };
  
    const handleSaveClick = (index: number) => {
        const updatedItems = [...items];
        updatedItems[index] = editValue;
        setItems(updatedItems);
        axios.put(`${baseUrl}user/update-note/${singleUserData.id}`, {note: updatedItems.join("\n")}, config).then((res) => {setNotes(""); setSingleUserData(res.data); setItems(res.data.note.split("\n"))})
        handleCancelClick();
    };

    const handleDeleteClick = (index: number) => {
        const updatedItems = [...items];
        updatedItems.splice(index, 1);
        setItems(updatedItems);
        axios.put(`${baseUrl}user/update-note/${singleUserData.id}`, {note: updatedItems.join("\n")}, config).then((res) => {setNotes(""); setSingleUserData(res.data); setItems(res.data.note.split("\n"))})
        handleCancelClick();
      };
    
    const [singleUserData, setSingleUserData] = useState<any>(null)
    const [open, setOpen] = useState(false);

    const showDrawer = (record: any) => {
        setSingleUserData(record)
        setOpen(true)
        if (record.note === null) setItems(null)
        else setItems(record.note.split("\n"))
    }
  
    const closeDrawer = () => {
      setOpen(false);
      queryClient.invalidateQueries()
      setSingleUserData(null)
    }


    dayjs.extend(duration)
    const navigate = useNavigate();
    const baseUrl = process.env.REACT_APP_BASE_URL

    const { RangePicker } = DatePicker;
    
    const { t } = useTranslation();

    const token = JSON.parse(localStorage.getItem("token") || '{}')
  
    const config = {
      headers: {Authorization: `Bearer ${token}`}
    }

    const loadingIcon = <LoadingOutlined style={{ fontSize: 50, color: "#03b9ac" }} spin />;

    //Filters states
    const [dateRange, setDateRange] = useState<any>(["",""])
    const [page, setPage] = useState<number>(1)
    const [pageSize, setPageSize] = useState<number>(10)
    const [query, setQuery] = useState("")
    const [order, setOrder] = useState("desc")
    const [orderBy, setOrderBy] = useState("id")
    const [statusFilter, setStatusFilter] = useState<string>("")
    const [isSiradigfilter, setIsSiradigFilter] = useState<boolean|string>("")
    const [createDays, setCreateDays] = useState<number|string>("")
    const [subStatus, setSubStatus] = useState<any>("")

    //Filter users by date of creation
    const handleDateRange = (dates: any) => {
        if (dates[0] !== ""  && dates[1] !== "") setDateRange([dayjs(dates[0]).unix(), dayjs(dates[1]).unix()])
        else {}
    }
    
    //Filter by word
    const handleInputWordToFilter = (e) => {
        setQuery(e.target.value)
    }

    //Clear Filters
    const clearFilters = () => {
        setQuery("")
        setDateRange(["", ""])
        setSubStatus("")
    }
  
    const fetchData = async () => {
      const res: any = await axios.get(`${baseUrl}user/get-all/?page=${page}&pageSize=${pageSize}&query=${query}&createAtFrom=${dateRange[0]}&createAtTo=${dateRange[1]}&order=${order}&orderBy=${orderBy}&cStatus=${statusFilter}&siradig=${isSiradigfilter}&createDays=${createDays}&subStatus=${subStatus}`, config)
      setTotalItems(res.total)
      return res.data
    }
    
    const { data, status } = useQuery(["get-all-users", [dateRange, query, order, orderBy, page, pageSize, statusFilter, isSiradigfilter, createDays, subStatus]], fetchData, {suspense: false, retry: 1})

    const [totalItems, setTotalItems] = useState(10)

    useEffect(() => {
      axios.get(`${baseUrl}user/get-all/?pageSize=1000000`, config).then(res => setTotalItems(res.data.length))
    }, [])

    //LoginAs hook
    const useLoginAs = () => {
        return useMutation(async (loginData: number) => {
            return await axios.post(`${baseUrl}auth/login-as/${loginData}`, {} , config )
        },
        {
            onSuccess: async (res) => {
                queryClient.setQueryData("adminToken", localStorage.getItem("token")||"")
                localStorage.removeItem("token")
                localStorage.removeItem("role")
                const response = JSON.stringify(res.data.token)
                queryClient.setQueryData("token", response)
                queryClient.setQueryData("showButtonBackToAdmin", true)
                localStorage.setItem("token", response)
                const userToken = JSON.parse(response)
                await axios.get(`${baseUrl}user/me`, {headers: {Authorization: `Bearer ${userToken}`}}).then( res => queryClient.setQueryData('userData', res.data)).catch(e => console.log(e))
                if (res.data.role?.type) {queryClient.setQueryData('role', res.data.role.type); localStorage.setItem("role", res.data.role.type); (res.data.role.type === "admin") ? navigate('/admin/users') : navigate('/dashboard/')}
                else {queryClient.setQueryData('role', 'user'); localStorage.setItem("role", "user"); navigate('/dashboard/')}
            },
            onError: (err) => {console.log('Error:', err); errorMessageLogin()}
        })
    }

    const { mutate : loginAs} = useLoginAs()

    const onLoginAs = (loginData: number) => {
        loginAs(loginData)
    }

    //Error Modal Notification
    const modalSuccess = () => {
        Modal.success({title: `${t("adminDashboardPage.users.table.fiscalKeyModalSuccess.title")}`, content: `${t("adminDashboardPage.users.table.fiscalKeyModalSuccess.description")}`, okButtonProps: {type: "text"}})
    }

    //Success Modal Notification
    const modalError = () => {
        Modal.error({title: `${t("adminDashboardPage.users.table.fiscalKeyModalError.title")}`, content: `${t("adminDashboardPage.users.table.fiscalKeyModalError.description")}`, okButtonProps: {type: "text"}})
    }

    //Send Fiscal Key to admin email
    const useSendUserFiscalKey = () => {
        return useMutation(async (id: number) => {
            return await axios.get(`${baseUrl}user/get-fiscal-key/${id}`, config)
        },
        {   
            onSuccess: (res: any) => modalSuccess(),
            onError: (e) => modalError()
        }
        )
    }

    const {mutate :sendUserFiscalKey} = useSendUserFiscalKey()

    const onSendUserFiscalKey = (id: number) => {
        sendUserFiscalKey(id)
    }

    const showLogo = async (id) => {
        await axios.get(`${baseUrl}user/get-logo-admin/${id}`, {
            method: "GET",
            headers: {Authorization: `Bearer ${token}`}
          }).then(res =>{
            const url = res
            window.open(url.data.logo, "_parent")
          }).catch(err => {
            console.log(err);
          });
      };

    enum clientStatus {
        NO_CONTACT = 'Sin contacto',
        ONE_CONTACT = '1 Contacto',
        TWO_CONTACT = '2 Contacto',
        NO_RESPONSE = 'Sin respuesta',
        IN_CONTACT = 'En contacto',
        CONVERTED = 'Convertido',
        CERRADO = 'Cerrado'
      }


    //Status Filter
    const handleStatusFilter = (value: any) => {
        setPage(1)
        if (value === clientStatus.NO_CONTACT) setStatusFilter(clientStatus.NO_CONTACT)
        else if (value === clientStatus.ONE_CONTACT) setStatusFilter(clientStatus.ONE_CONTACT)
        else if (value === clientStatus.TWO_CONTACT) setStatusFilter(clientStatus.TWO_CONTACT)
        else if (value === clientStatus.NO_RESPONSE) setStatusFilter(clientStatus.NO_RESPONSE)
        else if (value === clientStatus.IN_CONTACT) setStatusFilter(clientStatus.IN_CONTACT)
        else if (value === clientStatus.CONVERTED) setStatusFilter(clientStatus.CONVERTED)
        else if (value === clientStatus.CERRADO) setStatusFilter(clientStatus.CERRADO)
        else if (value === "Todos") setStatusFilter("")
        else setStatusFilter("")
    }

    const handleProductFilter = (value: any) => {
        setPage(1)
        if (value === "monotributo") setIsSiradigFilter(false)
        else if (value === "siradig") setIsSiradigFilter(true)
        else setIsSiradigFilter("")
    }

    const handleIsNewUserFilter = (value: any) => {
        setPage(1)
        if (value === "Todos") setCreateDays("")
        else if (value === t("adminDashboardPage.users.table.new")) setCreateDays(0)
        else if (value === t("adminDashboardPage.users.table.2days")) setCreateDays(1)
        else if (value === t("adminDashboardPage.users.table.3days")) setCreateDays(2)
        else if (value === t("adminDashboardPage.users.table.4days")) setCreateDays(3)
        else if (value === t("adminDashboardPage.users.table.5days")) setCreateDays(4)
    }

    const clientStatusOptions = [{label: "Todos", value: ""}, {label: "Sin Contacto", value: clientStatus.NO_CONTACT}, {label: "1 Contacto", value: clientStatus.ONE_CONTACT}, {label: "2 Contacto", value: clientStatus.TWO_CONTACT}, {label: "Sin Respuesta", value: clientStatus.NO_RESPONSE}, {label: "En Contacto", value: clientStatus.IN_CONTACT}, {label: "Convertido", value: clientStatus.CONVERTED}, {label: "Cerrado", value: clientStatus.CERRADO}]
    const productOptions = [{label: "Todos", value: "Todos"}, {label: "Monotributo", value: "monotributo"}, {label: "Siradig", value: "siradig"}]
    const isNewUserOptions = [{label: "Todos", value: "Todos"}, {label: t("adminDashboardPage.users.table.new"), value: t("adminDashboardPage.users.table.new")}, {label: t("adminDashboardPage.users.table.2days"), value: t("adminDashboardPage.users.table.2days")}, {label: t("adminDashboardPage.users.table.3days"), value: t("adminDashboardPage.users.table.3days")}, {label: t("adminDashboardPage.users.table.4days"), value: t("adminDashboardPage.users.table.4days")}, {label: t("adminDashboardPage.users.table.5days"), value: t("adminDashboardPage.users.table.5days")}]

    const selectStatus = <Select onChange={handleStatusFilter} showArrow defaultValue={["Todos"]} style={{ width: '100%' }} options={clientStatusOptions}/>
    const selectProduct = <Select onChange={handleProductFilter} showArrow defaultValue={["Todos"]} style={{ width: '100%' }} options={productOptions} ></Select>
    const selectIsNewUser = <Select onChange={handleIsNewUserFilter} showArrow defaultValue={["Todos"]} style={{ width: '100%' }} options={isNewUserOptions}></Select>

    const isSiradig = (data: any) => {
        if (data !== null && data.siradig !== null) return false
        else return true
    }

    const subStatusOptions = [{label: "Todos", value: ""}, {label: "Autorizado", value: "authorized"}, {label: "En Pausa", value: "paused"}, {label: "Cancelado", value: "cancelled"}, {label: "One shoot", value: "oneshoot"},{label: "Manual", value: "manual"}, {label: "Pendiente", value: "pending"}]

    const handleSubStatusOptions = (value: any) => {
        setPage(1)
        setSubStatus(value)
    }

    const premiumPlanData = useGetData("plan/current-plan", "premiumPlanData");

    const createSub = async(id: string|number) => {
        await axios.post(`${baseUrl}subscription/create-manual?userId=${id}&planId=${premiumPlanData.id}`, {} , config)
        .then(res => {message.success(t("adminDashboardPage.users.table.successMessage")); queryClient.invalidateQueries()})
        .catch(err => {
            console.log(err);
            message.error(t("adminDashboardPage.users.table.errorMessage"))
          });
    }

    const updateAuthorization = async(subscription: any) => {
        await axios.put(`${baseUrl}subscription/update-authorization?authorization=${!subscription.authorized}&subId=${subscription.id}`, {} , config)
        .then(res => {message.success((subscription.authorized) ? t("adminDashboardPage.users.table.successMessageUnauthorized") : t("adminDashboardPage.users.table.successMessageAuthorized")); queryClient.invalidateQueries()})
        .catch(err => {
            console.log(err);
            message.error(t("adminDashboardPage.users.table.errorMessage"))
          });
    }

    const [actualRecord, setActualRecord] = useState<any>(null)
    const [moreActionsModalIsOpen, setMoreActionModalIsOpen] = useState(false)
    const [xPointerLocation, setXPointerLocation] = useState(50)
    const [yPointerLocation, setYPointerLocation] = useState(50)


    const moreActions = (record, event) => {
        setActualRecord(record);
        setMoreActionModalIsOpen(true)
        setYPointerLocation(event.clientY)
        setXPointerLocation(event.clientX)

    }

    const [paymentId, setPaymentId] = useState("")

    const [esSoloNumeros, setEsSoloNumeros] = useState(false);

    const verificarNumeros = (input: string) => {
      const expresionRegular = /\D/;
      const contieneSoloNumeros = !expresionRegular.test(input);
      setEsSoloNumeros(contieneSoloNumeros);
    };

    const subRelationAdmin = async() => {
        try {
            await axios.put(`${baseUrl}subscription/sub-relation-admin`, {userId: actualRecord.id, paymentId: paymentId} , config).then((res) => {message.success(t("adminDashboardPage.users.table.drawer.successMessagePayment")); queryClient.invalidateQueries(); setMoreActionModalIsOpen(false)})
            
        } catch (error) {
            message.error(t("adminDashboardPage.users.table.errorMessage"))
            
        }
        
    }

return (
    <div style={{width: "100%"}}>
        <Row style={{marginTop: "20px", marginBottom: "20px"}}>
            <Col span={24}>
                <Typography style={DASHBOARD_TITLES}>{t("adminDashboardPage.users.title")}</Typography>
            </Col>
        </Row>
        <Row >
            <Col span={24}>
                <Row style={{display: "flex", justifyContent: "normal", gap: "5px", width: "fit-content", paddingBottom: "15px"}}>
                    <Col style={{display: "flex", gap: "20px"}}>
                        <RangePicker value={(dateRange[0] !== "") ? [dayjs.unix(dateRange[0]), dayjs.unix(dateRange[1])] : [null, null]} format={"DD-MM-YYYY"} style={{height: "38px", borderRadius: "4px"}} onChange={(dates) => handleDateRange(dates)}></RangePicker>
                    </Col>
                    <Col style={{display: "flex", gap: "10px"}}>
                        <Input value={query} style={{fontFamily: "Dosis", fontSize: "18px", height: "38px",}} placeholder={t("vouchers.table.filterInputPlaceholder") || ""} onChange={(e) => handleInputWordToFilter(e)}/>
                    </Col>
                    <Col>
                        <Select placeholder={<div style={{lineHeight: "38px", fontSize: "18px", fontFamily: "Dosis"}}>{"Tipo Suscripción"}</div>} style={{height: "38px", minWidth: "200px"}} onChange={handleSubStatusOptions} options={subStatusOptions}></Select>
                    </Col>
                    <Col style={{display: "flex"}}>
                        <Button id='btn-secondary-outline' style={{height: "38px", lineHeight: "17px"}} onClick={clearFilters}>{t("vouchers.table.buttonClearFilters")}</Button>
                    </Col>
                </Row>
                <Table
                    loading={{spinning: (status === "loading"), indicator: loadingIcon}}
                    dataSource={( data === undefined || data.length === 0 ) ? [] : data.items}
                    size="large"
                    style={{overflow: "hidden"}} scroll={{x: true}}
                    pagination={{showSizeChanger: true, size: "small", showQuickJumper: false, total: (data === undefined) ? totalItems : data.total , responsive: true, onChange(page, pageSize) {setPage(page); setPageSize(pageSize)}}}
                    >
                    <Column title={t("adminDashboardPage.users.table.email")} dataIndex="email" key="email" />
                    <Column title={t("adminDashboardPage.users.table.name")} dataIndex="name" key="name" />
                    <Column title={t("adminDashboardPage.users.table.role")} dataIndex="role" key="role" render={(_: any, record: any) => ((record.role.type === "user") ? <Tag style={{fontFamily: "Poppins"}} color="blue" key={record.id}>{record.role.type}</Tag> : <Tag color="orange" key={record.id}>{record.role.type}</Tag> )} />
                    <Column title={t("adminDashboardPage.users.table.cuit")} dataIndex={"cuit"} key={"cuit"} />
                    <Column title={t("adminDashboardPage.users.table.phone")} dataIndex={"phone"} key={"phone"} />
                    <Column filterDropdown={selectIsNewUser} title={t("adminDashboardPage.users.table.isNewUser")} key={"isNewUser"} render={
                        (_: any, record: any) => (
                            (dayjs.duration(dayjs().diff(dayjs.unix(record.createAt))).asDays() <= 1) ? 
                            <Tag style={{fontFamily: "Poppins"}} color='green'>{t("adminDashboardPage.users.table.new")} </Tag>
                            :
                            (dayjs.duration(dayjs().diff(dayjs.unix(record.createAt))).asDays() <= 2 && dayjs.duration(dayjs().diff(dayjs.unix(record.createAt))).asDays() > 1) ?
                            <Tag style={{fontFamily: "Poppins"}} color='purple'>{t("adminDashboardPage.users.table.2days")} </Tag>
                            :
                            (dayjs.duration(dayjs().diff(dayjs.unix(record.createAt))).asDays() <= 3 && dayjs.duration(dayjs().diff(dayjs.unix(record.createAt))).asDays() > 2) ?
                            <Tag style={{fontFamily: "Poppins"}} color='pink'>{t("adminDashboardPage.users.table.3days")} </Tag>
                            :
                            (dayjs.duration(dayjs().diff(dayjs.unix(record.createAt))).asDays() <= 4 && dayjs.duration(dayjs().diff(dayjs.unix(record.createAt))).asDays() > 3) ?
                            <Tag style={{fontFamily: "Poppins"}} color='orange'>{t("adminDashboardPage.users.table.4days")} </Tag>
                            :
                            (dayjs.duration(dayjs().diff(dayjs.unix(record.createAt))).asDays() <= 5 && dayjs.duration(dayjs().diff(dayjs.unix(record.createAt))).asDays() > 4) ?
                            <Tag style={{fontFamily: "Poppins"}} color='red'>{t("adminDashboardPage.users.table.5days")} </Tag>
                            :
                            <></>
                        )
                    } />
                    <Column filterDropdown={selectStatus} title={t("adminDashboardPage.users.table.clientStatus")} key={"clientStatus"} render={(_: any, record: any) => (
                        <Tag style={{fontFamily: "Poppins"}} color={(record.client_status === "Sin contacto") ? 
                        "darkgrey" 
                        : 
                        (record.client_status === "1 Contacto") ?
                        "geekblue"
                        :
                        (record.client_status === "2 Contacto") ?
                        "cyan"
                        :
                        (record.client_status === "Sin respuesta") ?
                        "volcano"
                        :
                        (record.client_status === "En Contacto") ?
                        "purple"
                        :
                        (record.client_status === "Convertido") ?
                        "green"
                        :
                        (record.client_status === "Cerrado") ?
                        "black"
                        :
                        "grey"
                    
                    }>{record.client_status}</Tag>
                    )}/>
                    <Column title="Producto" filterDropdown={selectProduct} render={(_: any, record: any) => (
                        (record.siradig !== null) ?
                        <Tag style={{fontFamily: "Poppins"}} color='#4E7A99'>{"Siradig"}</Tag>
                        : 
                        <Tag style={{fontFamily: "Poppins"}} color='#69B2A8'>{"Monotributo"}</Tag>
                        
                    )}/>
                    <Column render={(_: any,  record: any) => (
                        (record.role.type === "user") ?
                        <Button id='button-small-outline-green-bg' onClick={(event) => moreActions(record, event)}><EllipsisOutlined /></Button>
                        :
                        <></>
                        )}></Column>
                </Table>
                <Modal
                open={moreActionsModalIsOpen}
                onCancel={() => setMoreActionModalIsOpen(false)}
                okButtonProps={{style:{display: "none"}}}

                cancelButtonProps={{style: {display: "none"}}}
                style={{maxWidth: "250px", top: yPointerLocation + ((yPointerLocation+300 >= window.innerHeight) ? -300 : 20), left: xPointerLocation-20, paddingRight: "5px"}}
                onOk={() => setMoreActionModalIsOpen(false)}
                destroyOnClose
                
                >
                    {
                        <Row style={{display: "flex", flexDirection: "column", gap: "20px", paddingTop: "30px", width: "100%"}}>
                            <Row style={{display: "flex", gap: "20px", width: "100%", justifyContent: "space-between"}}>
                                <Col>
                                    {
                                        (actualRecord !== null && actualRecord.role.type === "user") ?
                                        <Button style={{width: "100%", display: "flex"}} id='button-small-outline-green-bg' onClick={() => {onLoginAs(actualRecord.id); setMoreActionModalIsOpen(false)}} ><LoginOutlined /></Button>
                                        :
                                        <></>
                                    }
                                </Col>
                                <Col>
                                    {
                                        (actualRecord !== null && actualRecord.role.type === "user") ?
                                        <Button style={{width: "100%", display: "flex"}} id='button-small-outline-green-bg' onClick={() => {showDrawer(actualRecord); setMoreActionModalIsOpen(false)}} ><EyeOutlined /></Button>
                                        :
                                        <></>
                                    }
                                </Col>
                            </Row>
                            <Row style={{width: "100%"}}>
                                {
                                    (actualRecord !== null && actualRecord.role.type === "user") ? 
                                    <>
                                        <Button style={{width: "100%"}} id='button-small-outline-green-bg' onClick={() => {onSendUserFiscalKey(actualRecord.id); setMoreActionModalIsOpen(false) }} >{t("adminDashboardPage.users.table.sendFiscalKeyButton")}</Button>
                                    </>
                                    : 
                                    <></>
                                }
                            </Row>
                            <Row style={{width: "100%"}}>
                                {
                                    (actualRecord !== null && actualRecord.role.type === "user") ?
                                    <>
                                        <Button style={{width: "100%"}} id='button-small-outline-green-bg' onClick={() => {navigate(`/admin/users/payments/${actualRecord.id}`, {state: {email: actualRecord.email}})}}>{t("adminDashboardPage.users.table.paymentsButton")}</Button>
                                    </>
                                    :
                                    <></>
                                }
                            </Row>
                            <Row style={{width: "100%"}} hidden={actualRecord !== null && actualRecord.logo === null}>
                                {
                                    (actualRecord !== null && actualRecord.logo !== null) ?
                                    <>
                                        <Button style={{width: "100%"}} id='button-small-outline-green-bg' onClick={() => {showLogo(actualRecord.id); setMoreActionModalIsOpen(false)}}>{t("adminDashboardPage.users.table.getLogo")}</Button>
                                    </>
                                    :
                                    <></>
                                }
                            </Row>
                            <Row style={{width: "100%"}}>
                                {
                                    (actualRecord !== null && actualRecord.subscription === null) ?
                                    <>
                                        {(actualRecord.role.type !== "admin") ?
                                            <Button style={{width: "100%"}} id='button-small-outline-green-bg' onClick={() => {createSub(actualRecord.id); setMoreActionModalIsOpen(false)}} >{t("adminDashboardPage.users.table.createSubscription")}</Button>
                                            :
                                            <></>
                                        }
                                    </>
                                    :
                                    (actualRecord !== null && actualRecord.subscription.status === "manual") ?
                                    <>
                                        <Button style={{width: "100%"}} id='button-small-outline-green-bg' onClick={() => {updateAuthorization(actualRecord.subscription); setMoreActionModalIsOpen(false)}} >{ (actualRecord.subscription.authorized) ? t("adminDashboardPage.users.table.unauthorizeSubButton") : t("adminDashboardPage.users.table.authorizeSubButton")}</Button>
                                    </>
                                    :
                                    <>
                                    </>

                                }
                            </Row>
                            <Row style={{width: "100%"}}>
                                {
                                    (actualRecord !== null && actualRecord.subscription === null) ?
                                    <>
                                    </>
                                    :
                                    (actualRecord !== null && actualRecord.subscription.status === "manual" && actualRecord.subscription.authorized) ?
                                    <>
                                        {
                                            <Space.Compact>
                                                <Input placeholder={t("adminDashboardPage.users.table.drawer.addPaymentId")||""} style={{height: "38px"}} onChange={(e) => {setPaymentId(e.target.value); verificarNumeros(e.target.value)}}></Input>
                                                <Tooltip title={paymentId === "" ? t("adminDashboardPage.users.table.drawer.addPaymentIdTooltip") : ""}>
                                                    <Button className={paymentId === "" || esSoloNumeros === false ? "" : 'button-small-compact'} type='default' disabled={paymentId === "" || esSoloNumeros === false} style={{padding: "auto", width: "auto", height: "38px", borderColor: paymentId === "" ? "lightgrey" : "#03b9ac"}}  onClick={() => subRelationAdmin()}>{t("add")}</Button>
                                                </Tooltip>
                                            </Space.Compact>
                                        }
                                    </>
                                    :
                                    <>
                                    </>

                                }
                            </Row>
                        </Row>

                    }
                </Modal>
                <Drawer title={(singleUserData === null) ? "" : singleUserData.name} style={{zIndex: 1003}} keyboard={false} maskClosable={false} placement="right" bodyStyle={{display: "flex" , flexDirection: "column", gap: "40px"}} onClose={() => {closeDrawer()}} open={open} width={1000}>
                        <Row style={{display: "flex", gap: "90px"}}>
                            <Col>
                                <Typography style={RESUME_TITLES}>{t("adminDashboardPage.users.table.id")}</Typography><Typography style={RESUME_VALUES}>{(singleUserData === null) ? "" : singleUserData.id }</Typography>
                            </Col>
                            <Col>
                                <Typography style={RESUME_TITLES}>{t("adminDashboardPage.users.table.name")}</Typography><Typography style={RESUME_VALUES}>{(singleUserData === null) ? "" : singleUserData.name }</Typography>
                            </Col>
                            <Col>
                                <Typography style={RESUME_TITLES}>{t("adminDashboardPage.users.table.email")}</Typography><Typography style={RESUME_VALUES}>{(singleUserData === null) ? "" : singleUserData.email }</Typography>
                            </Col>
                            <Col>
                                <Typography style={RESUME_TITLES}>{t("adminDashboardPage.users.table.mpemail")}</Typography><Typography style={RESUME_VALUES}>{(singleUserData === null) ? "" : singleUserData.mpEmail }</Typography>
                            </Col>
                            <Col>
                                <Typography style={RESUME_TITLES}>{t("adminDashboardPage.users.table.phone")}</Typography><Typography style={RESUME_VALUES}>{(singleUserData === null) ? "" : singleUserData.phone }</Typography>
                            </Col>
                        </Row>
                        <Row style={{display: "flex", gap: "90px"}}>
                            <Col>
                                <Typography style={RESUME_TITLES}>{t("adminDashboardPage.users.table.template")}</Typography><Typography style={RESUME_VALUES}>{(singleUserData === null) ? "" : singleUserData.template }</Typography>
                            </Col>
                            <Col>
                                <Typography style={RESUME_TITLES}>{t("adminDashboardPage.users.table.lastLoginAt")}</Typography><Typography style={RESUME_VALUES}>{(singleUserData === null || singleUserData.lastLoginAt === null) ? "Nunca" : dayjs.unix(singleUserData.lastLoginAt).format("DD/MM/YYYY HH:mm:ss")  }</Typography>
                            </Col>
                            <Col>
                                <Typography style={RESUME_TITLES}>{t("adminDashboardPage.users.table.cuit")}</Typography><Typography style={RESUME_VALUES}>{(singleUserData === null) ? "" : singleUserData.cuit }</Typography>
                            </Col>
                            <Col>
                                <Typography style={RESUME_TITLES}>{t("adminDashboardPage.users.table.hasFiscalKey")}</Typography><Typography style={RESUME_VALUES}>{(singleUserData === null) ? "" : (singleUserData.hasFiscalKey) ? "Si" : "No" }</Typography>
                            </Col>
                            <Col>
                                <Button id='button-small-outline-green-bg' onClick={() => showLogo(singleUserData.id)}>{t("adminDashboardPage.users.table.getLogo")}</Button>
                            </Col>
                        </Row>
                        <Row hidden={ isSiradig(singleUserData) } style={{display: "flex", width: "100%", flexDirection: "column", gap: "30px", backgroundColor: "#4E7A99", borderRadius: "4px", padding: "20px"}}>
                            <Row style={{width: "100%", height: "auto"}}>
                                <Col span={24} style={{display: "flex", justifyContent: "center"}}>
                                    <Typography style={{color: "white", fontFamily: "Poppins", fontWeight: "700"}}>{t("adminDashboardPage.users.table.drawer.siradig.title")}</Typography>
                                </Col>
                            </Row>
                            <Row style={{display: "flex", gap: "10px", justifyContent: "space-between"}}>
                                <Col span={3} style={{display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
                                    <Typography style={RESUME_TITLES_WHITE}>{t("adminDashboardPage.users.table.drawer.siradig.countable_income")}</Typography><Typography style={RESUME_VALUES_WHITE}>{(singleUserData !== null && singleUserData.siradig !== null) ?  singleUserData.siradig.countable_income : "" }</Typography>
                                </Col>
                                <Col span={3} style={{display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
                                    <Typography style={RESUME_TITLES_WHITE}>{t("adminDashboardPage.users.table.drawer.siradig.dependency_relationship")}</Typography><Typography style={RESUME_VALUES_WHITE}>{(singleUserData !== null && singleUserData.siradig !== null) ?  (singleUserData.siradig.dependency_relationship) ? "Sí" : "No" : "" }</Typography>
                                </Col>
                                <Col span={3} style={{display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
                                    <Typography style={RESUME_TITLES_WHITE}>{t("adminDashboardPage.users.table.drawer.siradig.dependents")}</Typography><Typography style={RESUME_VALUES_WHITE}>{(singleUserData !== null && singleUserData.siradig !== null) ?  (singleUserData.siradig.dependents) ? "Sí" : "No" : "" }</Typography>
                                </Col>
                                <Col span={3} style={{display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
                                    <Typography style={RESUME_TITLES_WHITE}>{t("adminDashboardPage.users.table.drawer.siradig.rent_bills")}</Typography><Typography style={RESUME_VALUES_WHITE}>{(singleUserData !== null && singleUserData.siradig !== null) ?  (singleUserData.siradig.rent_bills) ? "Sí" : "No" : "" }</Typography>
                                </Col>
                                <Col span={3} style={{display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
                                    <Typography style={RESUME_TITLES_WHITE}>{t("adminDashboardPage.users.table.drawer.siradig.domestic_worker")}</Typography><Typography style={RESUME_VALUES_WHITE}>{(singleUserData !== null && singleUserData.siradig !== null) ?  (singleUserData.siradig.domestic_worker) ? "Sí" : "No" : "" }</Typography>
                                </Col>
                                <Col span={3} style={{display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
                                    <Typography style={RESUME_TITLES_WHITE}>{t("adminDashboardPage.users.table.drawer.siradig.health_insurance")}</Typography><Typography style={RESUME_VALUES_WHITE}>{(singleUserData !== null && singleUserData.siradig !== null) ?  (singleUserData.siradig.healt_insurance) ? "Sí" : "No" : "" }</Typography>
                                </Col>

                            </Row>
                        </Row>
                        <Row style={{display: "flex", gap: "90px"}}>
                            <Col span={24} style={{display: "flex", flexDirection: "column", gap: "10px"}}>
                                <Typography style={RESUME_TITLES}>{t("adminDashboardPage.users.table.notes")}</Typography>
                                {
                                    (singleUserData === null || singleUserData.note === null) ?
                                    <>{"no hay notas"}</>
                                    :
                                    <List  
                                    itemLayout="horizontal" 
                                    dataSource={(items !== null) ? items : "No hay notas"}
                                    renderItem={
                                        (item: any, index) => <List.Item actions={[
                                            editingIndex === index ? (
                                              <div style={{display: "flex", width: "300px", justifyContent: "space-evenly"}}>
                                                <Button id='button-small-outline-green-bg'
                                                  onClick={() => handleSaveClick(index)}
                                                >
                                                  {t("adminDashboardPage.users.table.drawer.saveButton")}
                                                </Button>
                                                <Button id='button-small-outline' onClick={handleCancelClick}>{t("adminDashboardPage.users.table.drawer.cancelButton")}</Button>
                                              </div>
                                            ) : (
                                              <div style={{display: "flex", width: "300px", justifyContent: "space-evenly"}}>  
                                                <Button id='button-small-outline' onClick={() => handleEditClick(index)}>{t("adminDashboardPage.users.table.drawer.editButton")}</Button>
                                                <Button id='button-small-outline-red' onClick={() => handleDeleteClick(index)}>{t("adminDashboardPage.users.table.drawer.deleteButton")}</Button>
                                              </div>
                                              )
                                          ]}>
                                            {editingIndex === index ? (
                                            <Input.TextArea
                                            allowClear
                                            value={editValue}
                                            onChange={(e) => setEditValue(e.target.value)}
                                            onPressEnter={() => handleSaveClick(index)}
                                            />
                                            ) : (
                                                item
                                            )}
                                        </List.Item>
                                    }
                                    >
                                    </List>
                                }

                                <Input.TextArea placeholder={t("adminDashboardPage.users.table.drawer.typeNewNotePlaceholder")||""} value={notes} onChange={(e) => setNotes(e.target.value)}></Input.TextArea>
                                <Button onClick={() => {axios.put(`${baseUrl}user/add-note/${singleUserData.id}`, {"note": notes}, config).then((res) => {setNotes(""); setSingleUserData(res.data); setItems(res.data.note.split("\n"))})}} style={{maxWidth: "180px"}} id='button-small-outline-green-bg'>{t("adminDashboardPage.users.table.saveNote")}</Button>
                            </Col>
                        </Row>
                        <Row style={{display: "flex", flexDirection: "column", gap: "20px"}}>
                            <Col span={8} style={{display: "flex", flexDirection: "column", gap: "10px"}}>
                                <Typography style={RESUME_TITLES}>{t("adminDashboardPage.users.table.clientStatus")}</Typography>
                                <Select defaultValue={(singleUserData !== null) ? singleUserData.client_status : ""} value={(singleUserData !== null) ? singleUserData.client_status : ""}  options={clientStatusOptions} onChange={(value) => axios.put(`${baseUrl}user/update-status/${singleUserData.id}`, {"client_status" : value} , config).then((res) => setSingleUserData(res.data))}/>
                            </Col>
                        </Row>
                </Drawer>
            </Col>
        </Row>
    </div>
)
}

export default Users