import { Col, Row, Typography } from 'antd';
import React from 'react'
import { useBreakpoint } from 'use-breakpoint';
import { DASHBOARD_TITLES } from '../../../components/common/utils/styles';
import { t } from 'i18next';
import InvoiceTemplate from '../../../components/UserSettings/InvoiceTemplate';

type Props = {}
const BREAKPOINTS = { mobile: 0, tablet: 768, desktop: 1280 }

const Customize = (props: Props) => {
    const { breakpoint, maxWidth, minWidth } = useBreakpoint(BREAKPOINTS);

  return (
    <>
        <Typography style={DASHBOARD_TITLES} >{t("dashboardPage.customize.title")}</Typography>
    {
        (breakpoint === "mobile" || breakpoint === "tablet") ?
        <div style={{display: "flex", flexDirection: "column", gap: "15px", paddingBottom: "100px", marginTop: "15px"}}>
            <div style={{display: "flex", flexDirection: "column"}}>
                <InvoiceTemplate />
            </div>
        </div>
        :
        <>
            <Row>
                <Col span={24}>
                    <div className='sectionDiv' style={{minHeight: "200px", height: "100%"}}>
                        <InvoiceTemplate />
                    </div>
                </Col>
            </Row>
            
        </>
    }
    </>
  )
}

export default Customize