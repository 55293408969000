import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useQueryClient } from "react-query";
import useGetData from "../../../hooks/useGetData";
import { Skeleton } from "antd";
import { useBreakpoint } from "use-breakpoint";

const BREAKPOINTS = { mobile: 0, tablet: 768, desktop: 1280 }

const Prices: React.FC<{ demoSelected?: boolean }> = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const user: any = queryClient.getQueryData("userData");
  const { breakpoint, maxWidth, minWidth } = useBreakpoint(BREAKPOINTS);


  const baseUrl = process.env.REACT_APP_BASE_URL;

  const redirectUrlOAuth = process.env.REACT_APP_REDIRECT_OAUTH_URL;
  const clientId = process.env.REACT_APP_CLIENT_ID;

  const userLogged = !!user;
  const userWithMP = !!user?.mpEmail;

  const onCreateSub = (planId: number, code?: string) => {
    //Se utilica el code que se recibe de los parametros para vincular la cuenta y crear la suscripcion
    const token = localStorage.getItem("token");
    const jsonToken = token ? JSON.parse(token) : "";
    const codeString = `?code=${code}`;
    localStorage.removeItem("planId");
    axios
      .post(
        `${baseUrl}subscription/create/${planId}${
          userWithMP ? "" : codeString
        }`,
        null,
        {
          headers: {
            Authorization: `Bearer ${jsonToken}`,
          },
        }
      )
      .then((data) => {
        const { init_point } = data.data;
        queryClient.invalidateQueries({ queryKey: ["userData"] });
        window.location.href = init_point;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // const onCreatePreference =(planId:number)=>{
  //   const token = localStorage.getItem("token");
  //   const jsonToken = token ? JSON.parse(token) : "";
  //     localStorage.removeItem("planId");
  //     axios
  //       .post(`${baseUrl}subscription/create-preference/${planId}`, null, {
  //         headers: {
  //           Authorization: `Bearer ${jsonToken}`,
  //         },
  //       })
  //       .then((data) => {
  //         const { init_point } = data.data;
  //         queryClient.invalidateQueries({ queryKey: ["userData"] });
  //         window.location.href = init_point;
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //       });
  // }

  const onVinculate = () => {
    //En caso de que el usuario no tenga un mpEmail se tiene que ejecutar esta funcion
    //const token = localStorage.getItem("token");
    /* if (token) { */
    const uri = `https://auth.mercadopago.com/authorization?client_id=${clientId}&response_type=code&platform_id=mp&state=${new Date().toISOString()+Math.random()}&redirect_uri=${redirectUrlOAuth}`;
    queryClient.invalidateQueries({ queryKey: ["userData"] });
    window.location.href = uri;
    /* } else {
      navigate("/signin?redirect=true");
    } */
  };

  const premiumPlanData = useGetData("plan/current-plan", "premiumPlanData");

  return (
    <>
    {(breakpoint === "mobile")
    ?
    <Container style={{display: "flex", flexDirection: "column", alignItems: "center", gap: "25px", justifyContent: "center", paddingBottom: "30px"}}>
      <Row style={{display: "flex", flexDirection: "column", justifyContent: "center"}}>
        <Col className="d-flex flex-column align-items-center">
          <h2
            className="z-5"
            style={{
              fontSize: "2rem",
              fontFamily: "Poppins",
              color: "#181818",
              textAlign: "center",
              paddingTop: "25px"
            }}
          >
            {t("landingPage.pricesSection.title")}
          </h2>
          <h3
            style={{
              fontSize: "14px",
              fontFamily: "Poppins",
              color: "#585757",
            }}
          >
            {t("landingPage.pricesSection.subtitle")}
          </h3>
        </Col>
      </Row>      
      <Row
        style={
              props.demoSelected === true
                ? {
                    border: "2px solid",
                    borderRadius: "4px",
                    borderColor: "#69B2A8",
                    boxShadow: "0 0 0 4px rgba(105, 178, 168, 0.3)",
                    width: "95vw",
                    alignItems: "center",
                    gap: "12px"
                  }
                : {
                    border: "1px solid",
                    borderRadius: "4px",
                    borderColor: "#69B2A8",
                    width: "95vw",
                    alignItems: "center",
                    gap: "12px"

                  }
            }
      >
        <Row style={{display: "flex", alignItems: "center"}}>
          <Col>
            <span
              className="d-flex justify-content-center"
              style={{
                maxWidth: "120px",
                border: "1px solid",
                borderRadius: "4px",
                borderColor: "#69B2A8",
                padding: "10px",
                color: "#69B2A8",
                fontFamily: "Poppins",
                }}
              >
                {t("landingPage.pricesSection.freeCard.gratis")}
              </span>

          </Col>
          <Col>
            <span
              style={{
                fontFamily: "Poppins",
                fontWeight: "bold",
                fontSize: "3rem",
                }}
            >
                {t("landingPage.pricesSection.freeCard.price")}
            </span>
          </Col>
        </Row>
        <Row>
          <Col>
            <span
              style={{
                fontFamily: "Poppins",
                fontWeight: "lighter",
                  }}
            >
              {t("landingPage.pricesSection.freeCard.description")}
            </span>          
          </Col>
        </Row>
        <Row>
        <Col
          span={24}
          className="d-flex flex-column gap-3 justify-content-center"
          style={{ }}
        >
          <div
            className="d-flex"
            style={{ fontFamily: "Poppins", color: "#69B2A8", fontSize: "0.85rem" }}
          >
            <CheckCircleOutlined
              color="#69B2A8"
              style={{ paddingRight: "15px" }}
            />
              {t("landingPage.pricesSection.freeCard.benefitList.1")}
          </div>
          <div
            className="d-flex"
            style={{ fontFamily: "Poppins", color: "#c5c5c5", fontSize: "0.85rem"  }}
          >
            <CloseCircleOutlined
              color="#69B2A8"
              style={{ paddingRight: "15px" }}
            />
              {t("landingPage.pricesSection.freeCard.benefitList.2")}
          </div>
          <div
            className="d-flex"
            style={{ fontFamily: "Poppins", color: "#c5c5c5", fontSize: "0.85rem"  }}
          >
            <CloseCircleOutlined
              color="#c5c5c5"
              style={{ paddingRight: "15px" }}
            />
              {t("landingPage.pricesSection.freeCard.benefitList.3")}
          </div>
        </Col>
        </Row>
        <Row>
          <Col style={{display: "flex", justifyContent: "center"}}>
            <Button
              disabled={props.demoSelected}
              onClick={() => {
                localStorage.removeItem("planId");
                  if (!userLogged) navigate("/signin");
                  else navigate("/dashboard");
                }}
              style={{
                maxWidth:  "225px",
                fontFamily: "Poppins",
                fontWeight: "normal",
                height: "38px",
                marginBottom: "20px",
                lineHeight: "auto",
                }}
              id="btn-secondary"
            >
              {props.demoSelected
                ? t("landingPage.pricesSection.freeCard.button_demo_banner")
                : t("landingPage.pricesSection.freeCard.button_start_now")}
            </Button>
          </Col>
        </Row>
      </Row>
      <Row
        className={props.demoSelected ? "" : "shadow"}

    
        style={
                 {
                    border: "none",
                    borderRadius: "4px",
                    borderColor: "#69B2A8",
                    width: "95vw",
                    alignItems: "center",
                    gap: "12px",
                    background: "linear-gradient(232.69deg, #69B2A8 28.37%, #4E7A99 95.18%)",
                  }
              }
      >
        <Row style={{display: "flex", alignItems: "center"}}>
          <Col>
            <span
              className="d-flex justify-content-center"
              style={{
                maxWidth: "100%",
                border: "1px solid",
                borderRadius: "4px",
                borderColor: "white",
                padding: "10px",
                color: "white",
                fontFamily: "Poppins",
                }}
              >
                {premiumPlanData.length === 0 ||
                  premiumPlanData === undefined ||
                  premiumPlanData.reason ===
                    undefined ? (
                    <>
                      <Skeleton.Button size="small" active />
                    </>
                  ) : (
                    premiumPlanData.reason.toUpperCase()
                  )}
              </span>

          </Col>
          <Col >
            <Row >
              <span
                style={{
                  fontFamily: "Poppins",
                  fontWeight: "bold",
                  fontSize: "3rem",
                  color: "white"
                  }}
              >
                  {premiumPlanData.length === 0 ||
                    premiumPlanData === undefined ||
                    premiumPlanData
                      .transition_amount === undefined ? (
                      <>
                        <Skeleton.Button active />
                      </>
                    ) : (
                      premiumPlanData
                        .transition_amount + "$"
                    )}
              </span>
            </Row>
            <Row>
              <span
                style={{
                  fontFamily: "Poppins",
                  fontWeight: "lighter",
                  color: "#fff",
                  fontSize: "15px",
                  marginTop: "-15px",
                  textAlign: "end"
                  }}
              >
                {t("landingPage.pricesSection.premiumCard.frequency")}
              </span>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col>
            <span
              style={{
                fontFamily: "Poppins",
                fontWeight: "lighter",
                color: "white"
                  }}
            >
              {premiumPlanData.length === 0 ||
                  premiumPlanData === undefined ||
                  premiumPlanData.description ===
                    undefined ? (
                    <>
                      <Skeleton paragraph={false} active />
                      <Skeleton paragraph={false} active />
                      <Skeleton paragraph={false} active />
                      <Skeleton paragraph={false} active />
                    </>
                  ) : (
                    premiumPlanData.description
                  )}
            </span>          
          </Col>
        </Row>
        <Row>
          <Col
            span={24}
            className="d-flex flex-column gap-3 justify-content-center"
            style={{ }}
          >
          {premiumPlanData.length === 0 ||
                premiumPlanData === undefined ||
                premiumPlanData.items ===
                  undefined ? (
                  <>
                    <Skeleton.Button active />
                    <Skeleton.Button active />
                    <Skeleton.Button active />
                    <Skeleton.Button active />
                    <Skeleton.Button active />
                  </>
                ) : (
                  premiumPlanData.items.map(
                    (data: any) => (
                      <div
                        className="d-flex"
                        style={{ fontFamily: "Poppins", color: "#fff", fontSize: "0.85rem" }}
                      >
                        <CheckCircleOutlined
                          color="#fff"
                          style={{ paddingRight: "15px" }}
                        />
                        {data}
                      </div>
                    )
                  )
                )}
          </Col>
        </Row>
        <Row>
          <Col style={{display: "flex", justifyContent: "center"}}>
            <Button
                  onClick={() => {
                   localStorage.setItem(
                     "planId",
                     premiumPlanData.id
                   );
                   if (userLogged && userWithMP) {
                     onCreateSub(premiumPlanData.id);
                   } /*  if (userLogged && !userWithMP ) */ else {
                     onVinculate();
                   } /* else {
                                   navigate("/signin?redirect=true");
                               } */
                   /* if (userLogged) {
                     onCreatePreference(
                       premiumPlanData.id
                     );
                   } else {
                     navigate("/signin?redirect=true");
                   } */
                  }}
                  style={{
                    maxWidth:  "225px",
                    fontFamily: "Poppins",
                    fontWeight: "normal",
                    height: "38px",
                    marginBottom: "20px",
                    lineHeight: "auto",
                  }}
                  id="btn-secondary-outline-white"
                >
                  {t("landingPage.pricesSection.premiumCard.button_start_now")}
                </Button>
          </Col>
        </Row>
      </Row>
    </Container>
    :
    <Container
      data-testid="prices"
      id="prices"
      className={props.demoSelected ? "pb-5 pt-5" : "mt-5 pb-5 pt-5"}
      style={{ position: "static" }}
    >
      <Row>
        <Col className="d-flex flex-column align-items-center pb-5">
          <h2
            className="z-5"
            style={{
              fontSize: "38px",
              fontFamily: "Poppins",
              color: "#181818",
              textAlign: "center",
            }}
          >
            {t("landingPage.pricesSection.title")}
          </h2>
          <h3
            style={{
              fontSize: "14px",
              fontFamily: "Poppins",
              color: "#585757",
            }}
          >
            {t("landingPage.pricesSection.subtitle")}
          </h3>
        </Col>
      </Row>
      <Row className="gap-2">
        <Col>
          <Container
            style={
              props.demoSelected === true
                ? {
                    border: "2px solid",
                    borderRadius: "4px",
                    borderColor: "#69B2A8",
                    boxShadow: "0 0 0 4px rgba(105, 178, 168, 0.3)",
                  }
                : {
                    border: "1px solid",
                    borderRadius: "4px",
                    borderColor: "#69B2A8",
                  }
            }
          >
            <Row className="">
              <Col md={7} className="d-flex flex-column gap-4 pb-4">
                <span
                  className="d-flex justify-content-center"
                  style={{
                    maxWidth: "120px",
                    border: "1px solid",
                    borderRadius: "4px",
                    borderColor: "#69B2A8",
                    padding: "10px",
                    color: "#69B2A8",
                    fontFamily: "Poppins",
                    marginLeft:  "20px",
                    marginTop: "20px",
                  }}
                >
                  {t("landingPage.pricesSection.freeCard.gratis")}
                </span>
                <span
                  style={{
                    marginLeft:  "20px",
                    fontFamily: "Poppins",
                    fontWeight: "bold",
                    fontSize: "48px",
                  }}
                >
                  {t("landingPage.pricesSection.freeCard.price")}
                </span>
                <span
                  style={{
                    minHeight: "192px",
                    fontFamily: "Poppins",
                    marginLeft:  "20px",
                    fontWeight: "lighter",
                  }}
                >
                  {t("landingPage.pricesSection.freeCard.description")}
                </span>
                <Button
                  disabled={props.demoSelected}
                  onClick={() => {
                    
                    localStorage.removeItem("planId");
                    if (!userLogged) navigate("/signin");
                    else navigate("/dashboard");
                  }}
                  style={{
                    maxWidth:  "225px",
                    marginLeft:  "20px",
                    fontFamily: "Poppins",
                    fontWeight: "normal",
                  }}
                  id="btn-secondary"
                >
                  {props.demoSelected
                    ? t("landingPage.pricesSection.freeCard.button_demo_banner")
                    : t("landingPage.pricesSection.freeCard.button_start_now")}
                </Button>
              </Col>
              <Col
                md={4}
                className="d-flex flex-column gap-3 pb-4 justify-content-center"
                style={{ paddingLeft: "8%" }}
              >
                <div
                  className="d-flex"
                  style={{ fontFamily: "Poppins", color: "#69B2A8" }}
                >
                  <CheckCircleOutlined
                    color="#69B2A8"
                    style={{ paddingRight: "15px" }}
                  />
                  {t("landingPage.pricesSection.freeCard.benefitList.1")}
                </div>
                <div
                  className="d-flex"
                  style={{ fontFamily: "Poppins", color: "#c5c5c5" }}
                >
                  <CloseCircleOutlined
                    color="#69B2A8"
                    style={{ paddingRight: "15px" }}
                  />
                  {t("landingPage.pricesSection.freeCard.benefitList.2")}
                </div>
                <div
                  className="d-flex"
                  style={{ fontFamily: "Poppins", color: "#c5c5c5" }}
                >
                  <CloseCircleOutlined
                    color="#c5c5c5"
                    style={{ paddingRight: "15px" }}
                  />
                  {t("landingPage.pricesSection.freeCard.benefitList.3")}
                </div>
              </Col>
            </Row>
          </Container>
        </Col>
        <Col>
          <Container
            className={props.demoSelected ? "" : "shadow"}
            style={{
              border: "none ",
              borderRadius: "4px",
              background:
                "linear-gradient(232.69deg, #69B2A8 28.37%, #4E7A99 95.18%)",
            }}
          >
            <Row>
              <Col md={7} className="d-flex flex-column gap-4 pb-4">
                <span
                  className="d-flex justify-content-center"
                  style={{
                    maxWidth: "150px",
                    border: "1px solid",
                    borderRadius: "4px",
                    borderColor: "#fff",
                    padding: "10px",
                    color: "#fff",
                    fontFamily: "Poppins",
                    marginLeft:  "20px",
                    marginTop: "20px",
                  }}
                >
                  {premiumPlanData.length === 0 ||
                  premiumPlanData === undefined ||
                  premiumPlanData.reason ===
                    undefined ? (
                    <>
                      <Skeleton.Button size="small" active />
                    </>
                  ) : (
                    premiumPlanData.reason.toUpperCase()
                  )}
                </span>
                <div
                  style={{
                    marginLeft:  "20px",
                    fontFamily: "Poppins",
                    fontWeight: "bold",
                    fontSize: "48px",
                    color: "#fff",
                  }}
                >
                  {premiumPlanData.length === 0 ||
                  premiumPlanData === undefined ||
                  premiumPlanData
                    .transition_amount === undefined ? (
                    <>
                      <Skeleton.Button active />
                    </>
                  ) : (
                    premiumPlanData
                      .transition_amount + "$"
                  )}{" "}
                  <span
                    style={{
                      fontFamily: "Poppins",
                      fontWeight: "lighter",
                      color: "#fff",
                      fontSize: "15px",
                    }}
                  >
                    {t("landingPage.pricesSection.premiumCard.frequency")}
                  </span>{" "}
                </div>
                <span
                  style={{
                    minHeight:  "120px",
                    fontFamily: "Poppins",
                    marginLeft:  "20px",
                    fontWeight: "lighter",
                    color: "#fff",
                  }}
                >
                  {premiumPlanData.length === 0 ||
                  premiumPlanData === undefined ||
                  premiumPlanData.description ===
                    undefined ? (
                    <>
                      <Skeleton paragraph={false} active />
                      <Skeleton paragraph={false} active />
                      <Skeleton paragraph={false} active />
                      <Skeleton paragraph={false} active />
                    </>
                  ) : (
                    premiumPlanData.description
                  )}
                </span>
                <Button
                  onClick={() => {
                   localStorage.setItem(
                     "planId",
                     premiumPlanData.id
                   );
                   if (userLogged && userWithMP) {
                     onCreateSub(premiumPlanData.id);
                   } /*  if (userLogged && !userWithMP ) */ else {
                     onVinculate();
                   } /* else {
                                   navigate("/signin?redirect=true");
                               } */
                  //  if (userLogged) {
                  //    onCreatePreference(
                  //      premiumPlanData.id
                  //    );
                  //  } else {
                  //    navigate("/signin?redirect=true");
                  //  }
                  }}
                  style={{
                    maxWidth:  "225px",
                    marginLeft:  "20px",
                    fontFamily: "Poppins",
                    fontWeight: "normal",

                  }}
                  id="btn-secondary-outline-white"
                >
                  {t("landingPage.pricesSection.premiumCard.button_start_now")}
                </Button>
              </Col>
              <Col
                md={4}
                className="d-flex flex-column gap-3 pb-4 justify-content-center"
                style={{ paddingLeft: "8%" }}
              >
                {premiumPlanData.length === 0 ||
                premiumPlanData === undefined ||
                premiumPlanData.items ===
                  undefined ? (
                  <>
                    <Skeleton.Button active />
                    <Skeleton.Button active />
                    <Skeleton.Button active />
                    <Skeleton.Button active />
                    <Skeleton.Button active />
                  </>
                ) : (
                  premiumPlanData.items.map(
                    (data: any) => (
                      <div
                        className="d-flex"
                        style={{ fontFamily: "Poppins", color: "#fff" }}
                      >
                        <CheckCircleOutlined
                          color="#fff"
                          style={{ paddingRight: "15px" }}
                        />
                        {data}
                      </div>
                    )
                  )
                )}
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
    </Container>}
    </>
  );
};

export default Prices;
