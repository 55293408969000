import React, { useState } from 'react'
import './BillingInformation.scss'
import {useTranslation} from "react-i18next";
import { Button, Col, Input, Modal, Row, Space, Tooltip, Typography, message } from 'antd';
import { DASHBOARD_SECTION_TITLES, RESUME_TITLES, RESUME_VALUES } from '../common/utils/styles';
import axios from 'axios';
import { useQuery, useQueryClient } from 'react-query';
import Prices from '../../pages/LandingPage/sections/Prices';
import Faq from '../../pages/LandingPage/sections/Faq';
import { useBreakpoint } from 'use-breakpoint';
import { RightOutlined, LoadingOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Popup } from 'antd-mobile';

type Props = {}
const BREAKPOINTS = { mobile: 0, tablet: 768, desktop: 1280 }

const BillingPlan: React.FC  = (props: Props) => {

    const { t } = useTranslation()
    const { breakpoint, maxWidth, minWidth } = useBreakpoint(BREAKPOINTS);
    const queryClient = useQueryClient()

    const baseUrl = process.env.REACT_APP_BASE_URL

    const token = JSON.parse(localStorage.getItem("token") || '{}')

    const config = {
        headers: {Authorization: `Bearer ${token}`}
    }   

    const fetchData = async () => {
        const res: any = await axios.get(`${baseUrl}subscription/me`, config)
        return res.data
      }

    const { data } = useQuery(`plan`, fetchData, {enabled: true, retry: 1, suspense: true, refetchOnMount: "always", refetchOnWindowFocus: "always", refetchOnReconnect: "always"})

    const userData: any = queryClient.getQueryData("userData")

    //Modal that show the Faqs
    const [openModalFaqs, setOpenModalFaqs] = useState(false);

    const showModalFaqs = () => {
        setOpenModalFaqs(true);
    };

    //Modal that show plans
    const [openModalPrices, setOpenModalPrices] = useState(false);

    const showModalPrices = () => {
        setOpenModalPrices(true);
    };
    
    const isDemo = () => {
        if (userData.subscription === null) return true
        else {
          if (
            userData.subscription.status === "authorized" ||
            userData.subscription.status === "oneshoot" || 
            userData.subscription.status === "manual"
          )
            return false;
          else return true;
        }
    }

    const loadingIcon = <LoadingOutlined style={{ fontSize: 50, color: "#03b9ac" }} spin />;

    const [paymentIdPopup, setPaymentIdPopup] = useState(false)

    const [paymentId, setPaymentId] = useState("")

    const [esSoloNumeros, setEsSoloNumeros] = useState(false);

    const verificarNumeros = (input: string) => {
      const expresionRegular = /\D/;
      const contieneSoloNumeros = !expresionRegular.test(input);
      setEsSoloNumeros(contieneSoloNumeros);
    };

    const subRelation = async() => {
        try {
            await axios.put(`${baseUrl}subscription/sub-relation/${paymentId}`,{}, config).then((res) => {
                message.success(t("adminDashboardPage.users.table.drawer.successMessagePayment")); 
                setTimeout(() => {queryClient.refetchQueries({queryKey: "plan"})}, 2000); }).finally(() => axios.get(`${baseUrl}user/me`,config).then((res) => {queryClient.setQueryData('userData', res.data); setPaymentIdPopup(false)}))
            
        } catch (error) {
            message.error(t("adminDashboardPage.users.table.errorMessage"))
            
        }
        
    }

    return (
        <>{
        (breakpoint === "mobile" || breakpoint === "tablet")
        ?
        (data === undefined) ?
        <div style={{display: 'flex', flexDirection: "row", alignContent: 'center', alignItems: "center"}}>{loadingIcon}</div>
        :
        <div style={{display: "flex"}}>
            <Row style={{height: "135px",  justifyContent: "center", paddingLeft: "25px", width: "98vw", backgroundColor: "#F2F0F0", borderRadius: "4px", display: "flex", flexDirection: "row", alignItems: "center"}}>
                <Col span={22}>
                    <Row>
                        <Typography style={{fontWeight: "800", fontFamily: "Poppins"}}>{t("billing.billingPlan.title")}</Typography>
                    </Row>
                    <Row>
                        <Typography style={{fontWeight: "500", fontFamily: "Poppins"}}>{t("billing.billingPlan.currentPlan")}</Typography><Typography style={{fontWeight: "300", fontFamily: "Poppins", paddingLeft: "5px"}}>{(isDemo() === true || data === undefined) ? t("billing.billingPlan.free") : data.plan.reason }</Typography>
                    </Row>
                    <Row>
                        <Typography style={{fontWeight: "500", fontFamily: "Poppins"}}>{t("billing.billingPlan.planCost")}</Typography><Typography style={{fontWeight: "300", fontFamily: "Poppins", paddingLeft: "5px"}}>{(isDemo() === true || data === undefined) ? t("billing.billingPlan.free") : Intl.NumberFormat( "es-AR", {style: "currency", currency: "ARS"}).format(data.plan.transition_amount)}</Typography>
                    </Row>
                    <Row style={{justifyContent: "center"}}>
                        {
                            userData !== undefined && userData.subscription !== null && userData.subscription.status === "manual" ?
                            <Button type='text' size='small' style={{color: "#03b9ac"}} onClick={() => setPaymentIdPopup(true)}>{t("billing.billingPlan.addPaymentId")}</Button>
                            :
                            <></>
                        }
                        
                    </Row>
                    {
                        (isDemo()) ? 
                        <>
                            <Row><Typography onClick={() => showModalPrices()} style={{fontWeight: "500", fontFamily: "Poppins", color: "#029389"}}>{t("billing.billingPlan.editPlanButton")}</Typography><Typography onClick={() => showModalFaqs()} style={{ paddingLeft: "20px",fontWeight: "500", fontFamily: "Poppins", color: "#029389"}}>{"FAQ"}</Typography></Row> 
                        </>
                        : 
                        <></>
                    }
                </Col>
                <Col span={2}>
                    {(isDemo()) 
                    ? 
                    <RightOutlined />
                    :
                    <></>
                    }
                </Col>
            </Row>
            <Popup showCloseButton visible={openModalPrices} onClose={() => setOpenModalPrices(false)} onMaskClick={() => setOpenModalPrices(false)} bodyStyle={{ height: '95vh', overflow: "auto", display: "flex", flexDirection: "column" }} mask={true} closeOnSwipe >
                <Prices demoSelected={isDemo()} />
            </Popup>
            <Popup showCloseButton visible={openModalFaqs} onClose={() => setOpenModalFaqs(false)} onMaskClick={() => setOpenModalFaqs(false)} bodyStyle={{ height: '95vh', overflow: "auto", display: "flex", flexDirection: "column" }} mask={true} closeOnSwipe>
                <Faq></Faq>
            </Popup>
            <Popup showCloseButton visible={paymentIdPopup} onClose={() => setPaymentIdPopup(false)} onMaskClick={() => setPaymentIdPopup(false)} bodyStyle={{ height: '30vh', overflow: "auto", display: "flex", flexDirection: "column" }} mask={true} closeOnSwipe>
                <Space.Compact style={{marginTop: "50px", paddingInline: "10px"}}>
                    <Input placeholder={t("adminDashboardPage.users.table.drawer.addPaymentId")||""} style={{height: "38px", width: "300px"}} onChange={(e) => {setPaymentId(e.target.value); verificarNumeros(e.target.value)}}></Input>
                        <Tooltip title={paymentId === "" ? t("adminDashboardPage.users.table.drawer.addPaymentIdTooltip") : ""}>
                            <Button className={paymentId === "" || esSoloNumeros === false ? "" : 'button-small-compact'} type='default' disabled={paymentId === "" || esSoloNumeros === false} style={{padding: "auto", width: "auto", height: "38px", borderColor: paymentId === "" ? "lightgrey" : "#03b9ac"}}  onClick={() => subRelation()}>{t("add")}</Button>
                        </Tooltip>
                </Space.Compact>
                <Typography style={{marginTop: "30px", paddingInline: "10px", color: "#777777"}}>{t("billing.billingPlan.paymentIdTooltip")}</Typography>


            </Popup>

        </div>
        :
        <div className='sectionDiv' style={{height: "315px"}}>
            <Row style={{marginTop: "20px", marginBottom: "10px"}}>
                <Col span={24} style={{textAlign: "center"}}>
                    <Typography style={DASHBOARD_SECTION_TITLES}>{t("billing.billingPlan.title")}</Typography>
                </Col>
            </Row>
            <Row style={{marginTop: "10px"}}>
                <Col span={12}>
                    <Typography style={RESUME_TITLES}>{t("billing.billingPlan.currentPlan")}</Typography>
                </Col>
                <Col span={12}>
                    <Typography style={RESUME_TITLES}>{t("billing.billingPlan.planCost")}</Typography>
                </Col>
            </Row>
            <Row style={{ marginBottom: "20px"}}>
                <Col span={12}>
                    <Typography style={RESUME_VALUES}>{(isDemo() === true || data === undefined) ? t("billing.billingPlan.free") : data.plan.reason }</Typography>
                </Col>
                <Col span={12}>
                    <Typography style={RESUME_VALUES}>{(isDemo() === true || data === undefined) ? t("billing.billingPlan.free") : Intl.NumberFormat( "es-AR", {style: "currency", currency: "ARS"}).format(data.plan.transition_amount)}</Typography>
                </Col>
            </Row>
            {(isDemo()) ? 
            <>
                <Row style={{marginTop: "20px", marginBottom: "20px"}}>
                    <Col span={24}>
                        <Typography style={RESUME_VALUES}>{t("billing.billingPlan.faqMessage")}<span style={{cursor: "pointer", color: "#03b9ac" }} onClick={showModalFaqs}>{t("billing.billingPlan.faqLink")}</span></Typography>
                    </Col>
                </Row>
                <Row style={{marginTop: "20px", marginBottom: "20px", position: "absolute", left: (breakpoint !== "desktop") ? "0px" : "30px", bottom: (breakpoint !== "desktop") ? "0px" : "20px"}}>
                    <Button onClick={showModalPrices} id='btn-secondary'>{t("billing.billingPlan.editPlanButton")}</Button>
                </Row>
                <Modal open={openModalPrices} onCancel={() => setOpenModalPrices(false)} width={ "75vw"} maskClosable={true} closable footer={null}>
                    <Prices demoSelected={isDemo()} />
                </Modal>
                <Modal open={openModalFaqs} onCancel={() => setOpenModalFaqs(false)} width={"75vw"} maskClosable={true} closable footer={null}>
                    <Faq></Faq>
                </Modal>
            </> 
            :
            userData.subscription.status === "manual" ?
            <>
                <Space.Compact style={{marginTop: "20px", marginBottom: "20px", position: "absolute", left: (breakpoint !== "desktop") ? "0px" : "30px", bottom: (breakpoint !== "desktop") ? "0px" : "20px"}}>
                    <Input placeholder={t("adminDashboardPage.users.table.drawer.addPaymentId")||""} style={{height: "58px", width: "300px"}} onChange={(e) => {setPaymentId(e.target.value); verificarNumeros(e.target.value)}}></Input>
                        <Tooltip title={paymentId === "" ? t("adminDashboardPage.users.table.drawer.addPaymentIdTooltip") : ""}>
                            <Button className={paymentId === "" || esSoloNumeros === false ? "" : 'button-small-compact'} type='default' disabled={paymentId === "" || esSoloNumeros === false} style={{padding: "auto", width: "auto", height: "58px", borderColor: paymentId === "" ? "lightgrey" : "#03b9ac"}}  onClick={() => subRelation()}>{t("add")}</Button>
                        </Tooltip>
                        <Tooltip title={t("billing.billingPlan.paymentIdTooltip")}>
                            <QuestionCircleOutlined style={{marginLeft: "20px", fontSize: "20px"}}/>
                        </Tooltip>
                </Space.Compact>

            </>
            :
            <></>
            }
            
        </div>
        }
        </>
    )
}

export default BillingPlan