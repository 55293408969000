import { ConfigProvider, Layout, Typography } from 'antd'
import { Content } from 'antd/es/layout/layout'
import { t } from 'i18next'
import React from 'react'
import { useBreakpoint } from 'use-breakpoint'
import NavBar from './LandingPage/sections/NavBar'


type Props = {}

const BREAKPOINTS = { mobile: 0, tablet: 768, desktop: 1280 }

const TermsAndConditionsPage = (props: Props) => {

    const { breakpoint } = useBreakpoint(BREAKPOINTS, 'desktop');

    const content = 
    <>
    <p><em>Vigencia desde:&nbsp;</em><em>04</em><em>/04/2023</em></p>
    <p><strong>CONDICIONES GENERALES DE CONTRATACI&Oacute;N</strong></p>
    <p>Los presentes &ldquo;<em>Condiciones Generales de Contrataci&oacute;n</em>&rdquo; (en adelante, &ldquo;<em>CGC</em>&rdquo;), constituyen un contrato entre una persona f&iacute;sica o jur&iacute;dica (en adelante, el &ldquo;<em>Usuario</em>&rdquo; o, en plural, los &ldquo;<em>Usuarios</em>&rdquo;) que desee acceder y/o usar el servicio brindado por &ldquo;SANSTAG S.A.S., CUIT: 30-71606891-5 con domicilio en Jos&eacute; Hern&aacute;ndez 4813 Barrio Parque Liceo I Secci&oacute;n de la Ciudad de C&oacute;rdoba, en la web <a href="http://www.monotributo.digital/">www.monotributo.digital</a> o aquella que en el futuro la reemplace (en adelante la &ldquo;<em>Empresa</em>&rdquo;), en cuyo caso el Usuario estar&aacute; sujeto a las siguientes condiciones generales de contrataci&oacute;n.&nbsp;</p>
    <p>En estas Condiciones Generales se describen todos los derechos, y obligaciones de las partes al utilizar el servicio de <a href="http://www.monotributo.digital/">www.monotributo.digital</a>.</p>
    <p>Es obligaci&oacute;n del Usuario leer y aceptar las condiciones que se establecen en adelante, como as&iacute; tambi&eacute;n las relacionadas a las Pol&iacute;ticas de Privacidad - las cuales forman parte de las presentes - previo a proceder la utilizaci&oacute;n de los servicios proporcionados por la Empresa. En caso de no estar de acuerdo con alguna/s de las cl&aacute;usulas insertas, deber&aacute; abstenerse de la utilizaci&oacute;n de los servicios que brinde o pudiere brindar en un futuro la Empresa. La utilizaci&oacute;n del servicio implica la aceptaci&oacute;n por parte del Usuario de las presentes CGC.-</p>
    <p>A todo efecto, se entiende por:</p>
    <p><strong>Usuario:&nbsp;</strong>Persona f&iacute;sica o jur&iacute;dica que desea utilizar y/o acceder a la contrataci&oacute;n del Servicio.&nbsp;</p>
    <p><strong>Empresa:</strong> SANSTAG S.A.S. - CUIT 30-71606891-5 - con domicilio en Jos&eacute; Hern&aacute;ndez 4813 Barrio Parque Liceo I Secci&oacute;n de la Ciudad de C&oacute;rdoba.</p>
    <p><strong>Sitio</strong>: <a href="http://www.monotributo.digital/">www.monotributo.digital</a> o el que en el futuro lo reemplace y todos los subdominios en donde se encuentren alojados los Servicios a proveer por la Empresa.</p>
    <p><strong>Servicio:</strong> Son los diversos servicios&nbsp;&nbsp;prestados por la Empresa y que se encuentran publicados e informados al Usuario. Los mismos se encuentran descritos en la cl&aacute;usula 1) de las presentes CGC.</p>
    <p><strong>Pol&iacute;tica:</strong> As&iacute; se abrevia la presente &ldquo;<em>Pol&iacute;tica de Privacidad</em>&rdquo; del Sitio.</p>
    <p><strong>CGC:&nbsp;</strong>Son las &ldquo;<em>Condiciones Generales de Contrataci&oacute;n</em>&rdquo;, las cuales rigen toda la relaci&oacute;n contractual establecida entre el Usuario y la Empresa.</p>
    <p><strong>A.A.I.P.:</strong> Agencia de Acceso a la Informaci&oacute;n P&uacute;blica - Organismo de contralor.</p>
    <p><strong>D.N.P.D.P.:&nbsp;</strong>Direcci&oacute;n Nacional de Protecci&oacute;n de Datos Personales - Organismo de contralor.</p>
    <p><strong>Asimismo, se recuerda que es requisito excluyente para la contrataci&oacute;n del Servicio que Ud. cuente con &ldquo;<em>Clave Fiscal Nivel 2</em>&rdquo; de la A.F.I.P., de lo contrario resulta imposible la prestaci&oacute;n del mismo.</strong></p>
    <p><strong>1) SERVICIOS OFRECIDOS EN EL SITIO - OBJETO.</strong></p>
    <p>El presente es un contrato de adhesi&oacute;n por el cual Ud. -el Usuario- contrata a la Empresa a los fines de que la misma le proporcione alguno de los siguientes servicios:&nbsp;</p>
    <p><strong>i)</strong> Arbitre los medios necesarios para lograr la inscripci&oacute;n ante la Administraci&oacute;n Federal de Ingresos P&uacute;blicos (AFIP) en el impuesto &ldquo;<em>Monotributo</em>&rdquo; y seg&uacute;n la&nbsp;&nbsp;categor&iacute;a que resulte de los datos suministrados por Ud. y confirmados al momento de perfeccionar el presente contrato (Ver cl&aacute;usulas 2), 4), 5) y 6). En relaci&oacute;n al impuesto de Monotributo, la Empresa deja expresa constancia y el Usuario entiende y consiente, que la inscripci&oacute;n s&oacute;lo se refiere a la inscripci&oacute;n en el Impuesto de AFIP y no en los eventuales reg&iacute;menes provinciales (Ej: Ingresos Brutos) y/o municipales como as&iacute; tampoco se encuentra incluido en el Servicio prestado la inscripci&oacute;n/dada de alta en la obra social elegida, ni tampoco incluye la presentaci&oacute;n de declaraci&oacute;n jurada &ldquo;300/97&rdquo; ante la ANSES, como tampoco el tr&aacute;mite para &ldquo;<em>Asignaciones Familiares</em>&rdquo; y/o cualquier otro que no sea el mero impuesto de monotributo, tr&aacute;mites todos que deber&aacute;n correr por cuenta del Usuario.</p>
    <p><strong>ii)</strong> La Empresa arbitre todos los medios necesarios para lograr la &ldquo;<em>Baja por cese de actividad</em>&rdquo; (en adelante, &ldquo;<em>Baja del Impuesto</em>&rdquo;) del Impuesto de Monotributo (con exactamente los mismos alcances y limitaciones establecidas en la cl&aacute;usula que antecede con respecto a los reg&iacute;menes provinciales, municipales, de obra social, etc.) para el per&iacute;odo, mes y a&ntilde;o que el Usuario le indique. Asimismo, espec&iacute;ficamente, el Usuario exonera de toda responsabilidad a la Empresa en el caso de solicitud de baja del impuesto realizada por el Usuario, cuando la misma sea producto de culpa o error del mismo. Es decir, si por alg&uacute;n error o dolo, el Usuario indic&oacute; la baja del impuesto y la Empresa la lleva a cabo, el Usuario -jam&aacute;s y bajo ninguna circunstancia- podr&aacute; reclamar por los da&ntilde;os y perjuicios sufridos a la Empresa, quien diligentemente cumpli&oacute; con su principal prestaci&oacute;n a cargo. La culpa propia del Usuario no podr&aacute; ser alegada como fundamento de responsabilidad de la Empresa. A&uacute;n m&aacute;s, el Usuario entiende y consiente que la baja del impuesto, como fuera aqu&iacute; descripta, no importa asesoramiento alguno contable o profesional, ni actividad alguna&nbsp;&nbsp;por parte la Empresa posterior a la obtenci&oacute;n de la baja.</p>
    <p><strong>iii)&nbsp;</strong>La Empresa arbitre todos los medios necesarios para lograr la &ldquo;<em>recategorizaci&oacute;n</em>&rdquo; (en adelante, &ldquo;<em>Recategorizaci&oacute;n en el Impuesto</em>&rdquo;) en el Impuesto de Monotributo (con exactamente los mismos alcances y limitaciones establecidas en la cl&aacute;usula que antecede con respecto a los reg&iacute;menes provinciales, municipales, de obra social, etc.) para el per&iacute;odo, mes y a&ntilde;o que el Usuario le indique. El Servicio brindado por la Empresa se limita exclusivamente a analizar los datos brindados por AFIP respecto a Ingresos y Egresos, e informar al Usuario la eventual &ldquo;<em>nueva categor&iacute;a</em>&rdquo; que surgir&iacute;a de tal informaci&oacute;n, sin realizar un an&aacute;lisis o asesoramiento sobre la misma. En caso de que la &ldquo;<em>nueva categor&iacute;a</em>&rdquo; informada sea aceptada por el Usuario, la Empresa proceder&aacute; a realizar el tr&aacute;mite de recategorizaci&oacute;n objeto del servicio.&nbsp;&nbsp;A estos efectos, la Empresa ingresar&aacute; -mediante expreso mandato y autorizaci&oacute;n del Usuario- al Sistema Registral del Usuario (Padr&oacute;n &Uacute;nico del Contribuyente) con su clave fiscal y CUIT/L. Atento que la categor&iacute;a informada previamente por la Empresa es al mero efecto informativo, de simulaci&oacute;n y no implica asesoramiento alguno, ser&aacute; siempre el Usuario quien decide sobre sus inscripciones ante la AFIP, quien -se reitera- otorga mandato expreso a la Empresa para realizar el tr&aacute;mite de recategorizaci&oacute;n, indic&aacute;ndole la nueva categor&iacute;a donde pretende inscribirse, otorgando, en consecuencia, consentimiento informado para llevar adelante la operaci&oacute;n.</p>
    <p></p>
    <p></p>
    <p><strong>iv)&nbsp;</strong>Servicio de Gesti&oacute;n Facturaci&oacute;n Electr&oacute;nica.</p>
    <p>Este Servicio es ofrecido al Usuario mediante la puesta disposici&oacute;n al mismo de una secci&oacute;n personal, a la cual se accede a trav&eacute;s de la creaci&oacute;n de un perfil propio del Usuario en el Sitio de la Empresa. Dicho acceso se encuentra restringido al Usuario, quien deber&aacute; ingresar un usuario y contrase&ntilde;a y mecanismos de seguridad s&oacute;lo conocidos por su propia persona. A los efectos de lograr el alta, deber&aacute; seguir el procedimiento dispuesto en el Sitio.</p>
    <p>Registrado el Usuario en la plataforma, a trav&eacute;s de la misma, la Empresa ofrece el servicio consistente en facturaci&oacute;n electr&oacute;nica a trav&eacute;s del cual el Usuario imparte instrucciones a la Empresa para confeccionar y emitir comprobantes digitales en el sitio web y servicios de AFIP, facultando y autorizando expresamente a la Empresa a utilizar sus credenciales de acceso ante dicho organismo para actuar en su nombre (en adelante, el &ldquo;<em>Servicio de Facturaci&oacute;n&rdquo;)</em>. El Servicio de Facturaci&oacute;n permite, entre otras funcionalidades, la confecci&oacute;n de comprobantes electr&oacute;nicos, seleccionando entre aquellos tipos de comprobantes permitidos por la AFIP, y la instrucci&oacute;n a la Empresa para su correspondiente emisi&oacute;n en la fecha seleccionada de dichos comprobantes en el sitio web de AFIP.</p>
    <p>A tal efecto, el Usuario otorga a la Empresa un mandato expreso para que -utilizando sus datos de identificaci&oacute;n en los servicios y sitios web de AFIP- pueda llevar a cabo las instrucciones de confecci&oacute;n y emisi&oacute;n de comprobantes digitales, en los t&eacute;rminos, condiciones y plazos establecidos por el Usuario en el Sitio de la Empresa.</p>
    <p>Es decir, la principal obligaci&oacute;n a cargo de la Empresa ser&aacute; la de cumplir con la instrucci&oacute;n emanada del Usuario consistente en confeccionar y emitir los comprobantes digitales indicados por el Usuario en el Sitio de la Empresa, ingresando a tales fines en el sitio web y servicios de AFIP mediante el empleo de las credenciales de acceso del Usuario ante dichos sitios de AFIP.</p>
    <p>En todos los casos, el Usuario declara comprender que para poder utilizar los Servicios ofrecidos por la Empresa, debe contar previamente con un CUIT (Clave &Uacute;nica de Identificaci&oacute;n Tributaria) habilitado seg&uacute;n la normativa correspondiente. De no ser as&iacute;, y de conformidad a lo estipulado en la cl&aacute;usula catorce &ldquo;<em>Plazos para el cumplimiento del Servicio</em>&rdquo;, los plazos all&iacute; estipulados no comenzar&aacute;n a correr hasta tanto sea habilitada el CUIT, de conformidad a lo aqu&iacute; indicado. Asimismo, el Usuario presta su conformidad para que la Empresa trate sus datos personales, de manera anonimizada, a los fines de confecci&oacute;n de estad&iacute;sticas, elaboraci&oacute;n de perfiles, patrones de comportamiento, estad&iacute;sticas, en general y particular, incluyendo el tratamiento a gran escala y con inteligencia artificial, lo cual se agrega a las Pol&iacute;ticas de Privacidad.&nbsp;</p>
    <p><strong>2) FORMULARIO DE SOLICITUD DE TR&Aacute;MITE.</strong></p>
    <p>Todo Usuario que desee utilizar el servicio proporcionado por la Empresa, debe hacerlo mediante la utilizaci&oacute;n del sitio web <a href="http://www.monotibuto.digital/">www.monotibuto.digital</a> o el que en un futuro lo reemplace (en adelante, el &ldquo;<em>Sitio</em>&rdquo;). Asimismo, luego de ingresar, debe consignar todos y cada uno de los datos solicitados en el mismo, seg&uacute;n sea el tipo de servicio que el Usuario requiera, y que obviamente sea brindado por la Empresa. La Empresa no se responsabiliza por la veracidad de los datos introducidos por los Usuarios. Los Usuarios ser&aacute;n responsables y garantizan y responden, en cualquier caso, por la veracidad, exactitud, integridad, vigencia y autenticidad de los datos ingresados. Todos los datos proporcionados por los Usuarios revisten el car&aacute;cter de declaraci&oacute;n jurada siendo el mismo responsable por la veracidad, exactitud y vigencia de los mismos.-</p>
    <p>La Empresa no proceder&aacute; a auditar ni a realizar acci&oacute;n alguna a los fines de validar, verificar, etc., los datos personales proporcionados por Ud. con ocasi&oacute;n de la contrataci&oacute;n del Servicio. Ud. es el &uacute;nico responsable de ello y no podr&aacute; -jam&aacute;s y bajo ninguna circunstancia- suplantar la identidad de un tercero o utilizar el servicio en perjuicio de un tercero y/o para fines que est&aacute;n vedados legalmente.</p>
    <p>La Empresa se reserva el derecho de rechazar una solicitud de tr&aacute;mite o de cancelarla y/o suspenderla, temporal o definitivamente, en caso de detectar incongruencias o inconsistencias en la informaci&oacute;n provista por un Usuario. Tal decisi&oacute;n no dar&aacute; lugar a reclamo alguno de ning&uacute;n tipo por parte del Usuario.-</p>
    <p></p>
    <p></p>
    <p><strong>3) COMUNICACIONES.</strong></p>
    <p>El Usuario, por medio de la presente, autoriza de forma expresa la suplantaci&oacute;n del soporte f&iacute;sico/papel por el electr&oacute;nico para todas y cada una de las comunicaciones que La Empresa realice o deba realizar con motivo de la contrataci&oacute;n del Servicio. En consecuencia las comunicaciones ser&aacute;n dirigidas a la casilla de correo suministrada por el Usuario.</p>
    <p></p>
    <p><strong>4) FORMA Y MODALIDAD DE CONTRATACI&Oacute;N.</strong></p>
    <p>El presente es un contrato a distancia. A los fines de cumplimentar con el art. 1107 del C&oacute;digo Civil y Comercial (en adelante, &ldquo;<em>CCCN</em>&rdquo;), se informa mediante las presentes CGC, que a los fines de utilizar correctamente el Sitio y, en consecuencia, obtener correctamente el Servicio prestado por la Empresa, se pone a su disposici&oacute;n en el Sitio, una secci&oacute;n de &ldquo;<em>Preguntas y respuestas</em>&rdquo; que encontrar&aacute; en el siguiente enlace: <a href="https://monotributo.digital/preguntas-frecuentes">https://monotributo.digital/preguntas-frecuentes</a> o el que en un futuro lo reemplace.</p>
    <p>No obstante, al ser una contrataci&oacute;n electr&oacute;nica a distancia, se informa que la navegaci&oacute;n del sitio es gratuita para todo aquello que no sea contrataci&oacute;n. Es decir, hasta tanto no se confirme la contrataci&oacute;n de un Servicio, previa lectura de las condiciones de contrataci&oacute;n, la navegaci&oacute;n es gratuita y no se generar&aacute; cargo alguno.&nbsp;</p>
    <p>El pago se genera por servicio solicitado, una vez confirmada la solicitud del Servicio deseado. Se advierte que el principal riesgo de este tipo de contrataci&oacute;n es aquel inherente a la contrataci&oacute;n electr&oacute;nica y a distancia, no presentando el Sitio ninguna dificultad comprensiva y/o t&eacute;rminos extra&ntilde;os o en otra lengua. La plataforma presenta y despliega un dise&ntilde;o de interfaz simple, sencilla de usar, para evitar cualquier tipo de riesgo en el uso de la misma.</p>
    <p></p>
    <p></p>
    <p><strong>5) PERFECCI&Oacute;N DEL CONTRATO</strong>.</p>
    <p>Acorde a lo estipulado por los arts. 1105 y 1106 del CCCN, se reitera que el presente constituye un contrato de los denominados &ldquo;<em>a distancia</em>&rdquo;. El mismo no se considerar&aacute; perfeccionado, sino hasta haberse hecho efectivo el pago correspondiente, seg&uacute;n lo estipulado de las cl&aacute;usulas 4), 8) y 9) de las presentes CGC, habiendo previamente a ello, completado correctamente el Formulario de contrataci&oacute;n que se encuentra en el Sitio y previa LECTURA Y CONFORMIDAD a estas condiciones de contrataci&oacute;n, las cuales le son puestas a disposici&oacute;n suya de manera inmediatamente previa a la confirmaci&oacute;n de la solicitud de Servicio.</p>
    <p>Ambas partes entienden y consienten en que, antes de la perfecci&oacute;n del contrato conforme a lo aqu&iacute; estipulado, ninguna obligaci&oacute;n de prestar el Servicio pesa sobre la Empresa.</p>
    <p>Las partes -de com&uacute;n acuerdo y a los fines de probar la existencia de estas CGC- aceptan equiparar la firma aut&oacute;grafa del Usuario a la realizada mediante la confirmaci&oacute;n del tr&aacute;mite en el modo referenciado y/o a cualquier tipo de clave, c&oacute;digo o elemento de seguridad identificativo utilizado y conocido s&oacute;lo por el Usuario y/o puesto a disposici&oacute;n por la Empresa.</p>
    <p><strong>6) ACEPTACI&Oacute;N DEL CONTRATO.</strong></p>
    <p>Luego de haberse perfeccionado el contrato seg&uacute;n lo estipulado en la cl&aacute;usula que antecede, y dando cumplimiento a lo estipulado por el Art. 1108 del C&oacute;digo Civil y Comercial de la Rep&uacute;blica Argentina, La Empresa enviar&aacute; un correo electr&oacute;nico, a la direcci&oacute;n indicada por el Usuario al momento de completar el formulario de solicitud de tr&aacute;mite, de la Aceptaci&oacute;n de la contrataci&oacute;n del Servicio.</p>
    <p><strong>7) CAR&Aacute;CTER DEL SERVICIO - RESPONSABILIDAD.</strong></p>
    <p>Se pone de manifiesto expresamente, y el Usuario declara comprender, que los Servicios brindados por la Empresa no pretenden proporcionar servicio profesional alguno, ni asesoramiento de dicha &iacute;ndole en el &aacute;rea contable, ni en ninguna otra, (ni puede inducirse o concluirse ello). La Empresa renuncia expresamente a cualquier garant&iacute;a de que el uso del servicio garantice su cumplimiento de los criterios contables o las obligaciones reglamentarias o legales, o de que los resultados del mismo vayan a ser exactos o suficientes para sus fines. Si bien el sitio proporciona informaci&oacute;n referida al tr&aacute;mite y servicio solicitado, el Usuario no puede inferir que la Empresa est&aacute; otorgando alg&uacute;n tipo de asesoramiento profesional, contable, legal, fiscal, tributario, financiero y/o de cualquier otro rubro. Si la intenci&oacute;n del Usuario es obtener un asesoramiento de ese car&aacute;cter, deber&aacute; dirigirse a un profesional id&oacute;neo en la materia seg&uacute;n sea la consulta que el Usuario pretendiera evacuar. El contenido del Sitio, en tal sentido, no tiene otra finalidad que cumplimentar con el deber de informaci&oacute;n adecuada impuesto por el ordenamiento jur&iacute;dico vigente, as&iacute; como tambi&eacute;n brindar un mejor servicio a los Usuarios para la toma de sus decisiones.</p>
    <p>No existe garant&iacute;a de que el acceso vaya a ser continuo o ininterrumpido, y por lo tanto,&nbsp;&nbsp;la Empresa no ser&aacute; responsable por los fallos del servicio ni de otros problemas inherentes al uso de internet y comunicaciones electr&oacute;nicas, o de otros sistemas fuera del control razonable de la misma.&nbsp;</p>
    <p>Por otro lado, se hace saber al Usuario que los tr&aacute;mites objeto del Servicio ofrecido por la Empresa, son tr&aacute;mites que pueden realizarse por cuenta propia del Usuario y que, por lo tanto, el Servicio brindado tiene por finalidad simplificar y colaborar en la realizaci&oacute;n de los mismos, no estando el Usuario obligado a la utilizaci&oacute;n del servicio para ello.</p>
    <p><strong>8) PRECIO.</strong></p>
    <p>El precio que paga el Usuario por la contrataci&oacute;n del Servicio ser&aacute; publicado y mostrado al Cliente antes de proceder a la confirmaci&oacute;n de la solicitud del Servicio e Informe. En el mismo se detallar&aacute; y discriminar&aacute; el valor del Servicio, I.V.A. y/o impuestos que resulten aplicables.</p>
    <p>La Empresa se reserva el derecho a modificar el precio por el Servicio, el cual ser&aacute; publicado en la web de la misma y regir&aacute; a futuro, no retroactivamente, desde el momento de su publicaci&oacute;n y comunicaci&oacute;n al Usuario. En los casos de Servicios continuos o con abono, el nuevo precio ser&aacute; comunicado al Usuario en su secci&oacute;n personal o v&iacute;a correo electr&oacute;nico. En caso que el Usuario no comunique su baja o solicitud de no continuar con el Servicio, en un plazo de 48 horas de recibida la comunicaci&oacute;n del nuevo precio, la Empresa procer&aacute; autom&aacute;ticamente al cobro de la nueva tarifa.</p>
    <p><strong>9) FORMA DE PAGO.</strong></p>
    <p>La Empresa -actualmente- pone a disposici&oacute;n del Usuario y &eacute;ste acepta, los siguientes Medios de Pago, a saber:</p>
    <p><strong>i)</strong> Plataforma de &ldquo;<em>MOBBEX&rdquo;</em> (<a href="http://www.mobbex.com/">www.mobbex.com</a>)</p>
    <p><strong>ii)</strong> Plataforma de &ldquo;<em>MercadoPago</em>&rdquo; (<a href="https://www.mercadopago.com/">www.mercadopago.com</a>)</p>
    <p><strong>iii)</strong> Pago a trav&eacute;s de &ldquo;<em>Pago F&aacute;cil</em>&rdquo; y/o &ldquo;<em>Rapi Pago</em>&rdquo;</p>
    <p>El listado de Medios de Pago podr&aacute; sufrir alteraciones y los Medios de Pago disponibles ser&aacute;n puestos a disposici&oacute;n al Usuario al momento de realizar el pago.</p>
    <p>El Usuario ser&aacute; redirigido al sitio web de la plataforma de&ldquo;<em>MOBBEX</em>&quot; o &ldquo;<em>MercadoPago</em>&rdquo;, o plataforma que en el futuro se utilice, donde realizar&aacute; el pago de manera segura y r&aacute;pida. Ud. entiende y acepta que tanto &ldquo;<em>MOBBEX</em>&rdquo; como &ldquo;<em>MercadoPago</em>&rdquo; o las plataformas de Medios de Pago que en el futuro se utilicen, son terceros ajenos a la Empresa, quedando Ud. sujeto a la pol&iacute;tica de privacidad, condiciones de contrataci&oacute;n y legales de dicha empresa. Una vez finalizado el pago, ser&aacute; dirigido nuevamente a la web de la Empresa. De no mediar pago, no se proceder&aacute; a la prestaci&oacute;n del servicio contratado o se dejar&aacute; de prestar aquel Servicio que se ven&iacute;a prestando.</p>
    <p><strong>10) RECTIFICACI&Oacute;N DE ERRORES Y REEMBOLSO.</strong></p>
    <p>En el supuesto que se encuentren errores en los datos personales suministrados por el Usuario y que no permitan a La Empresa cumplir con el SERVICIO, se proseguir&aacute; de la siguiente manera:</p>
    <p>El Usuario SIEMPRE tiene derecho a rectificar sus datos en el plazo de un (1) d&iacute;a h&aacute;bil, mediante el env&iacute;o de un correo electr&oacute;nico a (Indicar el mail a donde tiene que enviar)</p>
    <p>En caso que La Empresa detecte un error que no le permita continuar con la prestaci&oacute;n a su cargo, la misma lo comunicar&aacute; al Usuario inmediatamente de ser detectado. Una vez comunicado, el Usuario tendr&aacute; otro plazo de un (1) d&iacute;a h&aacute;bil para enmendar dicho error.</p>
    <p>Con el uso de la tecnolog&iacute;a, es frecuente encontrarse con &ldquo;<em>typos</em>&rdquo;. Es decir, con errores tipogr&aacute;ficos involuntarios producto del uso de teclados u otros dispositivos. Ejemplo: en el campo de &ldquo;<em>email</em>&rdquo; escribir &ldquo;<em>ejemplo@@gmail.com</em>&rdquo;, donde claramente hay un <em>&ldquo;@&rdquo;</em> de m&aacute;s. En dicho supuesto, es facultad y jam&aacute;s una obligaci&oacute;n de La Empresa proceder a rectificar el typo que resulte palpable a los fines de cumplir con la prestaci&oacute;n a su cargo. No obstante, comunicar&aacute; dicho error al Usuario para que proceda a su enmienda.</p>
    <p><strong>La pol&iacute;tica de devoluciones es la siguiente:</strong></p>
    <p>En caso de que el Usuario NO HAYA enmendado su error en los datos personales en el plazo de un (1) d&iacute;a h&aacute;bil como se establece en el apartado &ldquo;B&rdquo; de esta cl&aacute;usula, La Empresa s&oacute;lo percibir&aacute; el cincuenta por ciento (%50) del precio y quedar&aacute; a disposici&oacute;n del Usuario requerir la devoluci&oacute;n del otro cincuenta por ciento (%50) restante.</p>
    <p>Las Devoluciones se regir&aacute;n de la siguiente manera:</p>
    <p><strong>i)&nbsp;</strong>Cuando el Cliente haya realizado el pago a trav&eacute;s de una tarjeta de cr&eacute;dito o d&eacute;bito o mediante una plataforma de Medio de Pago, se realizar&aacute; el reembolso a trav&eacute;s de la plataforma &ldquo;<em>MOBBEX</em>&rdquo; (<a href="http://www.mobbex.com/">www.mobbex.com</a>) o &ldquo;<em>MERCADOPAGO</em>&rdquo; (<a href="https://www.mercadopago.com/">www.mercadopago.com</a>) o la del Medio de Pago que a futuro se utilice, seg&uacute;n donde hubiese realizado el pago del servicio. Dicho reembolso se realizar&aacute; dentro de un plazo de 24 horas de solicitado y confirmaci&oacute;n por la Empresa de que el mismo procede.</p>
    <p><strong>ii)</strong> Para el supuesto que el Cliente haya abonado el servicio a trav&eacute;s de &ldquo;<em>Rapi Pago</em>&rdquo; y/o &ldquo;<em>Pago F&aacute;cil</em>&rdquo;, la Empresa proceder&aacute; al reembolso dentro de las 48 horas de solicitado el mismo y previa confirmaci&oacute;n de la Empresa de que el mismo procede. Para dicho reembolso, el Cliente deber&aacute; comunicar a la Empresa por correo electr&oacute;nico a &ldquo;<em>soporte@monotributo.digital&rdquo;</em> un CBU y n&uacute;mero de CUIT, sea personal o de un tercero, a donde la Empresa deber&aacute; realizar una transferencia bancaria electr&oacute;nica por el monto del reembolso.&nbsp;</p>
    <p>La Empresa no realiza devoluciones en dinero efectivo, ni otras transferencias bancarias, criptomonedas y/u otro medio/modo de pago que NO SEA ALGUNO DE LOS SUPUESTOS ANTERIORES. A tales fines, el Usuario entiende y acepta que, para el supuesto que procediese la devoluci&oacute;n, deber&aacute; tramitar la misma conforme a los mecanismos y modos aqu&iacute; establecidos.</p>
    <p><strong>12) MODIFICACIONES DE LAS CONDICIONES GENERALES DE CONTRATACI&Oacute;N DEL SERVICIO.</strong></p>
    <p>La Empresa podr&aacute;, en cualquier momento y unilateralmente, modificar las presentes condiciones generales de contrataci&oacute;n. Tales modificaciones ser&aacute;n comunicadas por La Empresa al correo electr&oacute;nico consignado por el Usuario en caso que la relaci&oacute;n contractual siga vigente al momento de la modificaci&oacute;n de las CGC; es decir, el per&iacute;odo comprendido entre la solicitud y pago del Servicio y hasta la entrega de la &ldquo;<em>Constancia de Inscripci&oacute;n</em>&rdquo; o &ldquo;<em>Constancia de Cese de Actividad</em>&rdquo; seg&uacute;n sea el Servicio contratado. Para los dem&aacute;s Servicios, actuales o futuros, que supongan una relaci&oacute;n contractual continua con el Usuario, las nuevas CGC regir&aacute;n una vez comunicada la misma sin que el Usuario objete las mismas o comunique su intenci&oacute;n de no continuar la relaci&oacute;n contractual con la Empresa dentro del plazo de 48 horas de recibida la comunicaci&oacute;n.</p>
    <p><strong>13) PRUEBA.</strong></p>
    <p>Las constancias emitidas por la Empresa ser&aacute;n consideradas prueba suficiente del cumplimiento de la solicitud de tr&aacute;mite ingresada por el Usuario y del cumplimiento de las obligaciones a cargo de la Empresa, sustituyen la necesidad de cualquier otro documento y son plenamente oponibles al Usuario y a todo tercero.&nbsp;</p>
    <p><strong>14) PLAZO DE CUMPLIMIENTO DEL SERVICIO.</strong></p>
    <p>La Empresa, cuando la informaci&oacute;n proporcionada sea correcta, veraz y cumpla en un todo con lo requerido en estas CGC, se compromete a finalizar la ejecuci&oacute;n del Servicio en los siguientes t&eacute;rminos:</p>
    <p><strong>i) Servicio de INSCRIPCI&Oacute;N:</strong> La Empresa har&aacute; entrega de la &ldquo;<em>Constancia de Inscripci&oacute;n</em>&rdquo; (o constancia que un futuro la reemplace) -principal obligaci&oacute;n a su cargo- al Usuario en el plazo de cuatro (4) d&iacute;as h&aacute;biles desde que fuera requerido y pagado el Servicio. No obstante, siendo que la entrega de la constancia depende, pura y exclusivamente de AFIP, el plazo quedar&aacute; prorrogado t&aacute;citamente si AFIP establece otros plazos mayores al aqu&iacute; establecido y/o no cumple en entregarla.</p>
    <p><strong>ii) Servicio de Baja de Impuesto:</strong> La Empresa har&aacute; entrega de la &ldquo;<em>Constancia de Cese de Actividad</em>&rdquo; (o constancia que un futuro la reemplace) para el per&iacute;odo, mes y a&ntilde;o indicado por el Usuario -principal obligaci&oacute;n a su cargo- al Usuario en el plazo de dos (2) d&iacute;as h&aacute;biles desde que fuera requerido y pagado el Servicio. No obstante, siendo que la entrega de la constancia depende, pura y exclusivamente de AFIP, el plazo quedar&aacute; prorrogado t&aacute;citamente si AFIP establece otros plazos mayores al aqu&iacute; establecido y/o no cumple en entregarla.</p>
    <p><strong>iii) Servicio de Recategorizaci&oacute;n en el Impuesto:</strong> La Empresa har&aacute; entrega de la &ldquo;Constancia de Recategorizaci&oacute;n&rdquo; (o constancia que un futuro la reemplace) al Usuario en el plazo de cuatro (4) d&iacute;as h&aacute;biles desde que fuera requerido, pagado el Servicio, y prestado la conformidad respecto a la categor&iacute;a que surja de la informaci&oacute;n de Ingresos y Egresos proporcionada por AFIP, seg&uacute;n lo manifestado. No obstante, siendo que la entrega de la constancia depende, pura y exclusivamente de AFIP, el plazo quedar&aacute; prorrogado t&aacute;citamente si AFIP establece otros plazos mayores al aqu&iacute; establecido y/o no cumple en entregarla.</p>
    <p>Asimismo, y en virtud de lo estipulado en la &uacute;ltima parte de la cl&aacute;usula primera, el Usuario reitera conocer y comprender que debe contar previamente con un CUIT (Clave &Uacute;nica de Identificaci&oacute;n Tributaria) habilitado seg&uacute;n la normativa correspondiente. De no ser as&iacute;, los plazos estipulados en la presente cl&aacute;usula no comenzar&aacute;n a correr hasta tanto sea habilitado el CUIT.</p>
    <p><strong>&nbsp;iv) Servicio de Gesti&oacute;n de Facturaci&oacute;n Electr&oacute;nica.</strong></p>
    <p>La Empresa llevar&aacute; a cabo la confecci&oacute;n y emisi&oacute;n de facturas en el sistema y servicio de AFIP, conforme las instrucciones impartidas por el Usuario, en los plazos y condiciones establecidos por el Usuario. Asimismo, se deja expresamente informado y acordado que para el supuesto de que la AFIP experimente demoras, fallas, incidentes de seguridad y/o impedimentos, etc. en sus sistemas y servicios, ya sea por causales imputables a la misma o por fuerza mayor, el Plazo de Cumplimiento del Servicio quedar&aacute; prorrogado autom&aacute;ticamente hasta la desaparici&oacute;n de&nbsp;&nbsp;dichos incidentes por parte de AFIP. En dichos supuestos, la Empresa deber&aacute; cumplir con las obligaciones a su cargo el d&iacute;a h&aacute;bil siguiente inmediato desde la desaparici&oacute;n del incidente de AFIP.</p>
    <p><strong>15) CESI&Oacute;N.</strong></p>
    <p>El Usuario no podr&aacute; ceder el presente contrato, ni delegar facultades y/u obligaciones sin el previo consentimiento de La Empresa. La Empresa, en cambio, podr&aacute; ceder &iacute;ntegramente su posici&oacute;n contractual sin el consentimiento del Usuario.</p>
    <p><strong>16) JURISDICCI&Oacute;N Y LEY APLICABLE.</strong></p>
    <p>Este acuerdo estar&aacute; regido en todos sus puntos por las leyes vigentes en la Argentina. Cualquier controversia derivada del presente acuerdo, su existencia, validez, interpretaci&oacute;n, alcance o cumplimiento, ser&aacute; sometida a los Tribunales Provinciales en lo Civil y Comercial de la Ciudad de C&oacute;rdoba.</p>
    <p><strong>17) REVOCACI&Oacute;N DEL CONTRATO.</strong></p>
    <p><strong>Conforme lo dispuesto por el art. 1116 - Inc. &ldquo;B&rdquo; del C&oacute;digo Civil y Comercial, NO ES APLICABLE al presente el DERECHO DE REVOCAR ya que el presente contrato tiene como fin proveer un SERVICIO que se materializa con la entrega de: i) la &ldquo;<em>Constancia de Inscripci&oacute;n</em>&rdquo; <em>(o aquella que en el futuro la reemplace)</em> emitida por AFIP o ii) &ldquo;<em>Constancia de Cese de Actividad&rdquo; (o aquella que en el futuro la reemplace)</em>, siendo cada una, en los t&eacute;rminos literales del C.C. y C., un &ldquo;<em>fichero inform&aacute;tico, suministrado por v&iacute;a electr&oacute;nica, susceptible de ser descargado o reproducido con car&aacute;cter inmediato para su uso&hellip;</em>&rdquo;. Por ello, una vez finalizado el servicio y entregada la constancia mencionada, no se podr&aacute; revocar la contrataci&oacute;n.</strong></p>
    <p><strong>Para el Servicio de Gesti&oacute;n de Facturaci&oacute;n Electr&oacute;nica, el Usuario podr&aacute; revocar la contrataci&oacute;n durante el per&iacute;odo de vigencia de la relaci&oacute;n contractual, la cual tendr&aacute; efecto inmediato a partir de la finalizaci&oacute;n del per&iacute;odo que se encuentre transitando.</strong></p>
    <p><strong>A los fines del art. 1111, esta cl&aacute;usula se destaca y es la anterior inmediata a la firma (aceptaci&oacute;n) por parte del Usuario.</strong></p>
    <p>&nbsp;</p>
    </>

    return (

        <ConfigProvider
        theme={{
        token: {
            colorPrimary: '#029389',
            colorBgBase: 'white',
            colorBgContainer: 'white',
            fontSize: 20,
            fontFamily: "Dosis",
            colorText: "#252422",
        },
        }}
        >
        <Layout className="layout" style={{background: "white", display: 'flex', flexWrap: "wrap", alignContent: "center" }}>
            <NavBar/>
            <Content style={{display: "flex", flexDirection: "column", margin: '4rem', background: "white", maxWidth: "100vw", alignContent: "center"}}>
                <div className="site-layout-content container">
                    <Typography.Title style={{display:'flex', justifyContent: 'center', paddingTop: '4rem', color: '#000', fontFamily: "Dosis"}} level={2}>{t('termsAndConditionsPage.title')}</Typography.Title>
                    <div style={{whiteSpace: "pre-wrap", paddingTop: "100px"}}>
                        {content}
                    </div>
                </div>
            </Content>
        </Layout>
        </ConfigProvider>
      )
}

export default TermsAndConditionsPage