import React from "react";
import "./App.scss";
import { QueryClientProvider, QueryClient } from "react-query";
import es_ES from "antd/es/locale/es_ES";
import { ConfigProvider } from "antd";
import { ReactQueryDevtools } from "react-query/devtools";
import { persistQueryClient } from "react-query/persistQueryClient-experimental";
import { createWebStoragePersistor } from "react-query/createWebStoragePersistor-experimental";
import Router from "./routes/Router";
import LoadingPage from "./pages/LoadingPage";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 1.8e+6,
      suspense: true,
      staleTime: Infinity
    },
  },
});

const localStoragePersistor = createWebStoragePersistor({
  storage: window.localStorage,
});

persistQueryClient({
  queryClient,
  persistor: localStoragePersistor,
});

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false}></ReactQueryDevtools>
      <ConfigProvider locale={es_ES}>
        <React.Suspense fallback={<LoadingPage />}>
          <Router />
        </React.Suspense>
      </ConfigProvider>
    </QueryClientProvider>
  );
}

export default App;
