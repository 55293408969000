import React, { useEffect, useState } from 'react';
import {
  UsergroupAddOutlined,
  CopyOutlined,
  LogoutOutlined,
  SettingOutlined
} from '@ant-design/icons';
import { Layout, Menu, MenuProps } from 'antd';
import '../DashBoardPage/DashboardPage.scss'
import { useLocation, useNavigate } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { DASHBOARD_MENU_BACKGROUND_COLOR, DASHBOARD_MENU_HEIGHT, DASHBOARD_MENU_MARGIN, DASHBOARD_MENU_TEXT_COLOR, SIDER_BACKGROUND_COLOR } from '../../components/common/utils/styles';
import DashboardLayout from '../../components/common/DashboardLayout';
import {useTranslation} from "react-i18next";
import { useBreakpoint } from 'use-breakpoint';
import { Header } from 'antd/es/layout/layout';

const BREAKPOINTS = { mobile: 0, tablet: 768, desktop: 1280 }
const { Sider } = Layout;

const Dashboard: React.FC = () => {
  const [collapsed, setCollapsed] = useState(false);

  const { t } = useTranslation()
  const { breakpoint, maxWidth, minWidth } = useBreakpoint(BREAKPOINTS);
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("role");
    queryClient.clear();
    navigate('/');
  }

  //Control the highlighted menu item
  const location = useLocation();
  const [selected, setSelected] = useState<[string]>([""])

  useEffect(() => {
    if (location.pathname === '/admin/users') setSelected(["1"])
    else if (location.pathname === '/admin/vouchers') setSelected(["2"])
    else if (location.pathname === '/admin/landingpage-options') setSelected(["3"])
  }, [location.pathname])

  //Function that is triggered when click on menu items. It contains the routes inside the dasboard and the logout
  const onClick = (click: any) => {
    switch (click.key) {
        case '1': navigate('/admin/users')
            break;
          
        case '2': navigate('/admin/vouchers')
            break;

        case '3': navigate('/admin/landingpage-options')
            break;
        
        case '4': logout()
            break;

        default: navigate('/admin/users')
            break;
    }
  };

  const items: MenuProps['items'] = [
    {
      label: `${t('adminDashboardPage.menu.users')}`,
      key: 1,
      icon: <UsergroupAddOutlined />,
      style: {height: "58px", borderRadius: "4px",  display: "flex", width: (breakpoint === "mobile") ? "56px" : "auto", justifyContent: (breakpoint === "mobile") ? "center" : "inherit"},
    },

    {
      label: `${t('adminDashboardPage.menu.vouchers')}`,
      key: 2,
      icon: <CopyOutlined />,
      style: {height: "58px", borderRadius: "4px",  display: "flex", width: (breakpoint === "mobile") ? "56px" : "auto", justifyContent: (breakpoint === "mobile") ? "center" : "inherit"},
    },

    {
      label: `${t('adminDashboardPage.menu.landingPageOptions')}`,
      key: 3,
      icon: <SettingOutlined />,
      style: {height: "58px", borderRadius: "4px",  display: "flex", width: (breakpoint === "mobile") ? "56px" : "auto", justifyContent: (breakpoint === "mobile") ? "center" : "inherit"},
    },
    
    {
      label: `${t('adminDashboardPage.menu.logout')}`,
      key: 4,
      icon: <LogoutOutlined />,
      style: {height: "58px", borderRadius: "4px",  display: "flex", width: (breakpoint === "mobile") ? "56px" : "auto", justifyContent: (breakpoint === "mobile") ? "center" : "inherit"},
    },
  ]

  return (
    <Layout style={{ flexDirection: (breakpoint === "mobile" || breakpoint === "tablet") ? "column" : "row"}}>
      <Header hidden={(breakpoint === "desktop")} style={{display: "flex", gap: "15px", width: "100%", background: SIDER_BACKGROUND_COLOR, alignItems: 'center', flexDirection: "row"}}>
          <img style={{position: "absolute", top: "9px", left: "9px"}} src={`../../../logo-monotributo-white-mobile.png`} alt='logo' width={(breakpoint === "mobile") ? "16%" : "8%"}/>
          <Menu items={items} style={{background: SIDER_BACKGROUND_COLOR, color: DASHBOARD_MENU_TEXT_COLOR, width: "100%", justifyContent: "center"}} selectedKeys={selected} mode="horizontal" onClick={onClick} >
          </Menu>
      </Header>
        <Sider hidden={(breakpoint !== "desktop")} 
          style={{
          background: SIDER_BACKGROUND_COLOR,
          overflow: 'auto',
          height: '100vh',
          position: 'fixed',
          left: 0,
          top: 0,
          bottom: 0,
          }}
          collapsible
          collapsed={collapsed}
          onCollapse={(value) => {setCollapsed(value)
          }}>
          <div style={{ height: DASHBOARD_MENU_HEIGHT, padding: "15px", background: SIDER_BACKGROUND_COLOR , marginBottom: "25px"}} ><img src={`../../../logo-monotributo-white${(collapsed) ? "-mobile" : ""}.png`} alt='logo' width={"100%"}/></div>
          <Menu items={items} style={{background: SIDER_BACKGROUND_COLOR, color: DASHBOARD_MENU_TEXT_COLOR}} selectedKeys={selected} mode="inline" onClick={onClick} >
          </Menu>
        </Sider>
      <DashboardLayout collapsed={collapsed}></DashboardLayout>
    </Layout>
  );
};

export default Dashboard;