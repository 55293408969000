import { Button, Col, Row, Table, Tag, Typography, Upload } from 'antd'
import { UploadOutlined, BackwardOutlined } from '@ant-design/icons';
import Column from 'antd/es/table/Column'
import axios from 'axios'
import dayjs from 'dayjs'
import { t } from 'i18next'
import React from 'react'
import { useQuery, useQueryClient } from 'react-query'
import { DASHBOARD_SECTION_TITLES } from '../../../components/common/utils/styles'
import { useLocation, useNavigate, useParams } from 'react-router-dom';

const BillingHistoryById: React.FC = (props) => {

    const token = JSON.parse(localStorage.getItem("token") || '{}')
    const baseUrl = process.env.REACT_APP_BASE_URL  
    const config = {
      headers: {Authorization: `Bearer ${token}`}
    }

    const navigate = useNavigate()
    const queryClient = useQueryClient();
    const location = useLocation();
    const { id } = useParams()

    const fetchData = async () => {
        const res: any = await axios.get(`${baseUrl}payment/user/${id}`, config)
        return res.data
      }
      
    const { data, status } = useQuery(["get-payments-by-id", id], fetchData, {suspense: false, retry: 1, cacheTime: 300000})

    //Show the pdf in a new tab
    const showPdf = async (id) => {
    await axios.get(`${baseUrl}payment/get-file/${id}`, {
      headers: {Authorization: `Bearer ${token}`},
      responseType: 'blob'
      }).then(response => {
    //Create a Blob from the PDF Stream
      const file = new Blob(
        [response.data],
        {type: 'application/pdf'});
        //Build a URL from the file
        const fileURL = URL.createObjectURL(file);
        //Open the URL on new Window
        window.open(fileURL);
      })
      .catch(error => {
          console.log(error);
      });
    };
  

  return (
    <div className='sectionDiv' style={{width: "100%"}}>
            <Button id='button-small-outline-green-bg' onClick={() => navigate(-1)} style={{display: "flex", flexDirection: "row", maxWidth: "130px", alignItems: "center", justifyContent: "center", marginTop: "20px"}}><BackwardOutlined />{t("adminDashboardPage.users.table.backButton")}</Button>
            <Row style={{marginTop: "20px", marginBottom: "20px"}}>
                <Col span={24}>
                    <Typography style={DASHBOARD_SECTION_TITLES}>{t("billing.billingHistory.title") + " de " + location.state.email}</Typography>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Table style={{overflow: "hidden"}} dataSource={( data === undefined ) ? [] : data} size="large">
                        <Column title={t("billing.billingHistory.tableDate")} dataIndex="date_created" key="date_created" render={(_: any, record: any) => <>{dayjs.unix(record.date_created).format("DD-MM-YYYY")}</>} />
                        <Column title={t("billing.billingHistory.tableDescription")} dataIndex="description" key="description" />
                        <Column title={t("billing.billingHistory.tableAmount")} dataIndex="transaction_amount" key="transaction_amount" render={(_:any, record: any) => <>{"$ " + record.transaction_amount}</>} />
                        <Column width={"70px"} title={t("billing.billingHistory.tableStatus")} dataIndex="payment_status" key="payment_status" render={(_: any, record: any) =>
                            <Tag color={(record.payment_status === "success") ? "blue" : (record.payment_status === "failed") ? "red" : "grey"}>
                                {((record.payment_status === "success") ? "Completado" : (record.payment_status === "failed") ? "No completado" : "Cancelado")}
                            </Tag>} />
                        <Column  />
                        <Column width={"140px"} key={"upload"} render={(_: any, record: any) => (
                            (record.payment_status === "success") ?
                            <div style={{display: "flex", flexDirection: "row", gap: "5px"}}>
                                <Upload name={"file"} method="PATCH" accept='application/pdf' action={`${baseUrl}payment/upload-file/${record.id}`} headers={config.headers}><Button id='btn-secondary-outline' style={{height: "38px", lineHeight: "17px"}} icon={<UploadOutlined style={{fontSize: "17px"}} />}>{t("adminDashboardPage.vouchers.uploadButton")}</Button></Upload>
                                {(record.file_name !== undefined && record.file_name !== null) ? <Button id='button-small-outline-green-bg' onClick={() => showPdf(record.id)}>{t("adminDashboardPage.users.table.showPdfButton")}</Button> : <></>}
                            </div>
                            :
                            <></>
                        )}/>
                    </Table>
                </Col>
            </Row>
        </div>
  )
}

export default BillingHistoryById