import React from 'react'
import { Button, Form, Input, InputNumber, Select, Space, Typography } from 'antd';
import { Option } from 'antd/es/mentions';
import { useTranslation } from 'react-i18next';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { useBreakpoint } from 'use-breakpoint';

const BREAKPOINTS = { mobile: 0, tablet: 768, desktop: 1280 }

const Step3 = () => {

    const { t } = useTranslation()
    const { breakpoint, maxWidth, minWidth } = useBreakpoint(BREAKPOINTS);
    const form = Form.useFormInstance();

    //Variables de entorno, hay que cambiarlas con las reales

      const productosYServicios = ["Productos", "Servicios", "Productos y Servicios"]

      const uMedida = {
        "0": "otras unidades",
        "1": "kilogramos",
        "2": "metros",
        "3": "metros cuadrados",
        "4": "metros cúbicos",
        "5": "litros",
        "6": "1000 kWh",
        "7": "unidades",
        "8": "pares",
        "9": "docenas",
        "10": "quilates",
        "11": "millares",
        "14": "gramos",
        "15": "milimetros",
        "16": "mm cúbicos",
        "17": "kilómetros",
        "18": "hectolitros",
        "20": "centímetros",
        "25": "jgo. pqt. mazo naipes",
        "27": "cm cúbicos",
        "29": "toneladas",
        "30": "dam cúbicos",
        "31": "hm cúbicos",
        "32": "km cúbicos",
        "33": "microgramos",
        "34": "nanogramos",
        "35": "picogramos",
        "41": "miligramos",
        "47": "mililitros",
        "48": "curie",
        "49": "milicurie",
        "50": "microcurie",
        "51": "uiacthor",
        "52": "muiacthor",
        "53": "kg base",
        "54": "gruesa",
        "61": "kg bruto",
        "62": "uiactant",
        "63": "muiactant",
        "64": "uiactig",
        "65": "muiactig",
        "66": "kg activo",
        "67": "gramo activo",
        "68": "gramo base",
        "96": "packs",
      } 

  return (
    <>
        <Typography id='step19' style={{fontSize: "20px", fontWeight: "bold", fontFamily: "Dosis", color: "#606266", paddingBottom: "8px"}}>{t("vouchers.form.voucherLinesTitle")}</Typography>
        <Form.List name="voucher_request_lines"  initialValue={[{description: ""}]}>
            {(fields, {add, remove}) => (
            <>
                {fields.map(({key, name, ...restField}) => (
                <Space key={key} align="baseline" style={(breakpoint !== "desktop") ? {marginBottom: "50px", border: "1px solid", borderColor: "#f5f5f5", padding: "16px", borderRadius: "6px"} : {}}>
                    <Form.Item shouldUpdate={(prevValues, curValues) => prevValues.additional !== curValues.additional}>
                    {() => {
                    return(
                    <div style={(breakpoint !== "desktop") ? {display: "flex", flexDirection: "column", width: "80vw"} : {display: "flex", flexDirection: "row", gap: "30px"}} >
                    <Form.Item {...restField} label={t("vouchers.form.product_or_service_label")} rules={[{required: true}]} name={[name, "description"]} >
                        <Input style={{fontFamily: "Dosis", fontSize: "18px"}} id='step14'/>
                    </Form.Item>
                    <Form.Item {...restField}  label={t("vouchers.form.quantity")} rules={[{required: true}, {type: "number"}]} name={[name, "quantity"]} >
                        <InputNumber style={{fontFamily: "Dosis", fontSize: "18px"}} id='step15' min={0} />
                    </Form.Item>
                    <Form.Item {...restField} label={t("vouchers.form.measure")} rules={[{required: true}]} name={[name, "measure"]} >
                        <Select id='step16' style={{height: "58px"}}>
                            {Object.values(uMedida).map((value: string) => <Option key={value} value={value} >{value}</Option>)}
                        </Select>
                    </Form.Item>
                    <Form.Item {...restField} label={t("vouchers.form.unit_price")} rules={[{required: true}, {type: 'number', message: `${t("vouchers.form.typeNumberError")}`}]} name={[name, "unit_price"]} >
                        <InputNumber style={{fontFamily: "Dosis", fontSize: "18px"}} id='step17' min={0} />
                    </Form.Item>
                    <Form.Item shouldUpdate {...restField} label={t("vouchers.form.amount")} >
                    {() => {
                        let data = form.getFieldValue("voucher_request_lines")
                        if (data[key] === undefined)
                        return <Input style={{fontFamily: "Dosis", fontSize: "18px"}} value={"0"} ></Input>
                        else{
                        const quantity = (data[key].quantity)
                        const unitPrice = (data[key].unit_price)
                        const result = (quantity)*(unitPrice)
                        return <Input style={{fontFamily: "Dosis", fontSize: "18px"}} disabled value={((quantity === undefined || unitPrice === undefined ) ? "" : result)} ></Input>
                    }}}
                    </Form.Item>
                    <span onClick={() => remove(name)} style={{marginTop: (breakpoint !== "desktop") ? "0px" : "72px"}}><MinusCircleOutlined style={(breakpoint !== "desktop") ? {padding: "0 5px"} : {padding: "0 20px"}} />{(breakpoint !== "desktop") ? "Borrar linea" : ""}</span>
                    </div>
                    )}}
                    </Form.Item>
                </Space>
                ))}
                <Form.Item>
                    <Button id='step18' type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                        {t("add")}
                    </Button>
                </Form.Item>
            </>
            )}
        </Form.List>
  </>
  )
}

export default Step3