import { Col, Row, Typography } from 'antd'
import { t } from 'i18next'
import React from 'react'
import { useQueryClient } from 'react-query'
import { DASHBOARD_SECTION_TITLES, RESUME_TITLES, RESUME_VALUES } from '../common/utils/styles'
import { useBreakpoint } from 'use-breakpoint'
import { LoadingOutlined } from '@ant-design/icons';

type Props = {}
const BREAKPOINTS = { mobile: 0, tablet: 768, desktop: 1280 }

const MercadoPagoAccount = (props: Props) => {

    const queryClient = useQueryClient()
    const { breakpoint, maxWidth, minWidth } = useBreakpoint(BREAKPOINTS);
    const userData: any = queryClient.getQueryData("userData")
    const mercadoPagoAccount = userData.mpEmail
    const loadingIcon = <LoadingOutlined style={{ fontSize: 50, color: "#03b9ac" }} spin />;


  return (
    <>
    {
        (breakpoint === "mobile" || breakpoint === "tablet") ?
        (userData === undefined) ?
        <div style={{display: 'flex', flexDirection: "row", alignContent: 'center', alignItems: "center"}}>{loadingIcon}</div>
        :
        <div style={{display: "flex"}}>
            <Row style={{height: "135px",  justifyContent: "center",paddingLeft: "25px", width: "98vw", backgroundColor: "#F2F0F0", borderRadius: "4px", display: "flex", flexDirection: "row", alignItems: "center"}}>
                <Col span={22}>
                    <Row>
                        <Typography style={{fontWeight: "800", fontFamily: "Poppins"}}>{t("billing.mercadoPago.title")}</Typography>
                    </Row>
                    <Row style={{display: "flex", flexDirection: "column"}}>
                        <Typography style={{fontWeight: "500", fontFamily: "Poppins"}}>{t("billing.mercadoPago.email")}</Typography>
                        <Typography style={{fontWeight: "300", fontFamily: "Poppins"}}>{(userData === undefined || mercadoPagoAccount === null) ? t("billing.mercadoPago.noAccount") : mercadoPagoAccount }</Typography>
                    </Row>
                </Col>
                <Col span={2}>

                </Col>
            </Row>
        </div>
        :
        <div className='sectionDiv' style={{height: "315px"}}>
            <Row style={{marginTop: "20px", marginBottom: "10px"}}>
                <Col span={24}>
                    <Typography style={DASHBOARD_SECTION_TITLES}>{t("billing.mercadoPago.title")}</Typography>
                </Col>
            </Row>
            <Row style={{marginTop: "10px"}}>
                <Col span={24}>
                    <Typography style={RESUME_TITLES}>{t("billing.mercadoPago.email")}</Typography>
                </Col>
            </Row>
            <Row style={{ marginBottom: "20px"}}>
                <Col span={24}>
                    <Typography style={RESUME_VALUES}>{(userData === undefined || mercadoPagoAccount === null) ? t("billing.mercadoPago.noAccount") : mercadoPagoAccount }</Typography>
                </Col>
            </Row>
        </div>

    }
    </>
    )
}

export default MercadoPagoAccount