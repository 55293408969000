import React from 'react'
import { Button, Container, Nav, Navbar } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import useBreakpoint from 'use-breakpoint';
// import { HashLink } from 'react-router-hash-link';
import { NavButton } from '../../../components/common/NavButton';

const BREAKPOINTS = { mobile: 0, tablet: 768, desktop: 1200 }

const NavBar: React.FC = () => {

    const { breakpoint } = useBreakpoint(BREAKPOINTS, 'desktop');
    const { t } = useTranslation();
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const role = queryClient.getQueryData("role")

    const logout = () => {
        localStorage.removeItem("token");
        localStorage.removeItem("role");
        queryClient.clear();
        navigate('/');
      }

    const navButtonsUnloggedUsers = 
    <NavButton >
        <Button onClick={() => navigate("/login")} style={{width: "136px"}} id='btn-secondary-outline' >{t("landingPage.navBar.loginButton")}</Button>
        {/* <Button onClick={() => {navigate("/signin"); localStorage.removeItem("planId")}} style={{width: "136px"}} id='btn-secondary'>{t("landingPage.navBar.signinButton")}</Button> */}
    </NavButton>

    const navButtonsLoggedUsers = 
    <NavButton>
        <Button onClick={() => logout()} style={{minWidth: "136px"}} id='btn-secondary-outline' >{t("landingPage.navBar.logoutButton")}</Button>
        <Button id='btn-secondary' style={{minWidth: "136px"}} onClick={() => navigate((role === "user") ? "/dashboard/home" : "/admin/users")}>{t("landingPage.navBar.dashboardButton")}</Button>
    </NavButton>

  return (
    <Container data-testid="navbar" style={{fontFamily: "Poppins", fontSize: "1rem"}}>
        <Navbar className='mt-3 mb-2' collapseOnSelect expand="xl">
            <Container className='' style={{gap: "30px", maxWidth: "100vw"}}>
                <Navbar.Brand href={process.env.REACT_APP_DIGITAL_URL} className='pb-3'><img style={(breakpoint === "desktop") ? {maxWidth: "350px"} : {maxWidth: "200px"} } alt='logo' src="https://monotributo.digital/img/logo.svg"/></Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" style={{alignItems: "center"}} />
                <Navbar.Collapse id="responsive-navbar-nav" style={(breakpoint === "desktop") ? {display: "none"} : { zIndex: 20, backgroundColor:"#fff", position: "absolute", top: "100%", width: "100%", left: "0px"}}>
                    {/* <Nav navbarScroll className="me-auto d-flex align-items-center pt-4 pb-4" style={{gap: "30px", color:"#03b9ac"}}>
                        <HashLink to={'/#prices'} className='nav-link'>{t("landingPage.navBar.how_it_works")}</HashLink>
                        <HashLink to={'/#prices'} className='nav-link'>{t("landingPage.navBar.prices")}</HashLink>
                        <HashLink to={'/#features'} className='nav-link'>{t("landingPage.navBar.features")}</HashLink>
                        <HashLink to={'/#faq'} className='nav-link'>{t("landingPage.navBar.faq")}</HashLink>
                        <Nav.Link href='/contact'>{t("landingPage.footer.contact")}</Nav.Link>
                    </Nav > */}
                    {(role === "admin" || role === "user") ? navButtonsLoggedUsers : navButtonsUnloggedUsers}
                </Navbar.Collapse>
            </Container>
        </Navbar>
    </Container>

  )
}

export default NavBar