import React, {useEffect, useState} from 'react';
import { ConfigProvider, Layout, Button, Form, Input, message, Typography } from 'antd';
import '../LoginPage/LoginPage.scss'
import {useTranslation} from "react-i18next";
import useBreakpoint from 'use-breakpoint';
import { useNavigate, useParams } from 'react-router-dom';
import NavBar from '../LandingPage/sections/NavBar';
import { ResetPassword } from './ResetPasswordPage.types';
import useResetPassword from './query/useResetPassword';


const BREAKPOINTS = { mobile: 0, tablet: 768, desktop: 1280 }

const { Content, Footer } = Layout;

const ResetPasswordPage: React.FC = () => {

    const form = Form.useFormInstance();

    const navigate = useNavigate()

    const { breakpoint, maxWidth, minWidth } = useBreakpoint(BREAKPOINTS, 'desktop');

    const [, setFormResetPassword] = useState<ResetPassword>({password:""})

    const { t } = useTranslation();

    const baseUrl = process.env.REACT_APP_BASE_URL

    const { mutate: userResetPassword, isLoading, isSuccess, isError } = useResetPassword();
    const {resetPasswordToken} = useParams();
    
    //The function triggered when values changes
    const handleFormResetPassword = (changedValues: any, allValues: any) => {
        setFormResetPassword(allValues);
    }

    //The function triggered when click Enviar button
    const onFinish = (formResetPassword: ResetPassword) => {
        delete formResetPassword.confirmPassword
        userResetPassword(formResetPassword)
    };

    //Show an error message on the top of the window when there is an error 
    const errorMessage = () => {
        message.open({
        type: 'error',
        content: `${t('signinPage.signinForm.error')}`,
        });
    };
    
    return (
    <ConfigProvider
        theme={{
        token: {
            colorPrimary: '#029389',
            colorBgBase: 'white',
            colorBgContainer: 'white',
            fontSize: 20,
            fontFamily: "Dosis",
            colorText: "#252422",
        },
        }}
    >
        
    <Layout className="layout" style={{background: "white", display: 'flex', flexWrap: "wrap", alignContent: "center" }}>
        <NavBar></NavBar>
        <Content style={{display: "flex", flexDirection: "column", margin: '4rem', background: "white", maxWidth: "100vw", alignContent: "center"}}>
            <div className="site-layout-content container" style={(breakpoint === "desktop" || breakpoint === "tablet") ? { maxWidth: "50vw", display: "flex", flexDirection: "column", background: '#f7f6f6de', backgroundImage: "url('https://monotributo.digital/img/map.png')" , borderRadius: '4px' } : { width: "90vw", display: "flex", flexDirection: "column", background: '#f7f6f6de', backgroundImage: "url('https://monotributo.digital/img/map.png')" , borderRadius: '4px' } }>
                <Typography.Title style={{display:'flex', justifyContent: 'center', paddingTop: '4rem', color: '#000'}} level={2}>{t('resetPasswordPage.title')}</Typography.Title>
                <Form
                    name="reset-password"
                    layout='vertical'
                    onFinish={onFinish}
                    onFinishFailed={errorMessage}
                    onValuesChange={handleFormResetPassword}
                    autoComplete="off"
                    size='large'
                    style={{ padding: '1rem', display: 'block', maxWidth: '66.66666667%', fontSize: '1.5rem', alignItems: 'center', marginLeft: 'auto', marginRight: 'auto' }}
                >

                    <Form.Item
                        style={{ borderRadius: '4px' }}                       
                        labelAlign='left'
                        label={t('signinPage.signinForm.passwordLabel')}
                        name="password"
                        rules={[{ required: true, message: `${t('signinPage.signinForm.passwordError')}` },
                        () => ({
                            validator(_, value) {
                                if ( value.length < 8) {return Promise.reject(new Error(`${t('signinPage.signinForm.passwordLengthError')}`))}
                                else return Promise.resolve() 
                            }
                        })
                        ]}
                    >
                        <Input.Password data-testid="password" placeholder= {`${t('signinPage.signinForm.passwordPlaceholder')}`} />
                    </Form.Item>

                    <Form.Item
                        style={{ borderRadius: '4px' }}                       
                        labelAlign='left'
                        label={t('signinPage.signinForm.confirmPasswordLabel')}
                        name="confirmPassword"
                        dependencies={['password']}
                        rules={[{ required: true, message: `${t('signinPage.signinForm.confirmPasswordError')}` },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (!value || getFieldValue('password') === value) {
                                return Promise.resolve();
                              }
                              return Promise.reject(new Error(`${t('signinPage.signinForm.confirmPasswordError')}`));
                            },
                          }),]}
                    >
                        <Input.Password data-testid="confirm-password" placeholder= {`${t('signinPage.signinForm.confirmPasswordPlaceholder')}`} />
                    </Form.Item>

                    <Form.Item  style={{padding: '1rem',paddingBottom: '2rem', display:'flex', justifyContent: 'center'}}>
                        <Button className='btnlogin' type="primary" loading={isLoading}  htmlType="submit" style={{ height: 'auto', borderRadius: '0.25rem', marginTop: '25px', boxShadow: 'none'}}>
                            <span style={{ }} >{t('signinPage.signinForm.submitButton')}</span>
                        </Button>
                    </Form.Item>
                    <Form.Item hidden initialValue={resetPasswordToken} name={"resetPasswordToken"}></Form.Item>
                    
                </Form>
            </div>

        </Content>
        <Footer style={{ textAlign: 'center', background: "white" }}>Footer</Footer>
    </Layout>
    </ConfigProvider>
  );
};

export default ResetPasswordPage;