import React from 'react'
import { Col, Row, Typography } from 'antd';
import {useTranslation} from "react-i18next";
import BillingHistory from '../../../components/BillingInformation/BillingHistory';
import BillingPlan from '../../../components/BillingInformation/BillingPlan';
import BillingSettings from '../../../components/BillingInformation/BillingSettings';
import { DASHBOARD_TITLES } from '../../../components/common/utils/styles';
import MercadoPagoAccount from '../../../components/BillingInformation/MercadoPagoAccount';
import { useBreakpoint } from 'use-breakpoint';

const BREAKPOINTS = { mobile: 0, tablet: 768, desktop: 1280 }
type Props = {}

const Billing: React.FC = (props: Props) => {

  const { breakpoint, maxWidth, minWidth } = useBreakpoint(BREAKPOINTS);
  const { t } = useTranslation()

  return (
    <>
      <Typography style={DASHBOARD_TITLES} >{t("billing.title")}</Typography>
      {(breakpoint === "mobile" || breakpoint === "tablet")
      ?
      <div style={{display: "flex", flexDirection: "column", gap: "15px", paddingBottom: "100px", marginTop: "15px"}}>
        <div style={{display: "flex", flexDirection: "column"}}>
          <BillingSettings></BillingSettings>
        </div>
        <div style={{display: "flex", flexDirection: "column"}}>
          <BillingPlan></BillingPlan>
        </div>
        <div style={{display: "flex", flexDirection: "column"}}>
          <MercadoPagoAccount></MercadoPagoAccount>
        </div>
        <div style={{display: "flex", flexDirection: "column"}}>
          <BillingHistory></BillingHistory>
        </div>
      </div>
      :
      <>
        <Row gutter={20} align={"stretch"} style={(breakpoint !== "desktop") ? {display: "flex", flexDirection: "column"}: {}}>
          <Col span={ 8}>
            <BillingSettings></BillingSettings>
          </Col>
          <Col span={ 8}>
            <BillingPlan></BillingPlan>
          </Col>
          <Col span={ 8} >
            <MercadoPagoAccount></MercadoPagoAccount>
          </Col>
        </Row>
        <Row style={{marginTop: "10px"}}>
          <BillingHistory></BillingHistory>
        </Row>
      </>
    }

      
    </>
  )
}

export default Billing