import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import common_es from './locale/es/common.json'

export const defaultNS = 'common_es';

export const resources = {
  es: {
    common_es
  }
};

i18n
  .use(initReactI18next)
  .init({
    fallbackLng: 'es',
    debug: true,
    resources,
    defaultNS,
  });


export default i18n;