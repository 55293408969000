import React, { useEffect, useState } from 'react';
import { Button, Col, DatePicker, Divider, Drawer, Input, Row, Select, Table, Tag, Typography, Upload } from 'antd';
import {useTranslation} from "react-i18next";
import "./VoucherRequests.scss"
import axios from 'axios';
import { useQuery, useQueryClient } from 'react-query';
import VoucherRequestForm from '../../../components/VoucherRequest/VoucherRequestForm';
import { DASHBOARD_TITLES } from '../../../components/common/utils/styles';
import dayjs from 'dayjs';
import { CaretUpOutlined, CaretDownOutlined, LoadingOutlined, UploadOutlined } from '@ant-design/icons';
import { useSearchParams } from 'react-router-dom';
import { useBreakpoint } from 'use-breakpoint';
import { Value } from 'sass';

const { Column } = Table;
const BREAKPOINTS = { mobile: 0, tablet: 768, desktop: 1280 }


export default function VoucherRequests() {

  const { RangePicker } = DatePicker;

  const { breakpoint, maxWidth, minWidth } = useBreakpoint(BREAKPOINTS);
  const queryClient = useQueryClient()
  const [open, setOpen] = useState(false);
  const [voucherId, setVoucherId] = useState<number>()

  const loadingIcon = <LoadingOutlined style={{ fontSize: 20, color: "#03b9ac" }} spin />;

  const showDrawer = () => {
      setOpen(true)
  }

  const onClose = () => {
    setOpen(false);
  }

  const { t } = useTranslation();

  //Filters states
  const [dateRange, setDateRange] = useState<any>(["",""])
  const [page, setPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(10)
  const [query, setQuery] = useState("")
  const [order, setOrder] = useState("desc")
  const [orderBy, setOrderBy] = useState("id")
  const [statusFilter, setStatusFilter] = useState<string>("")

  const baseUrl = process.env.REACT_APP_BASE_URL

  const token = JSON.parse(localStorage.getItem("token") || '{}')

  const config = {
    headers: {Authorization: `Bearer ${token}`}
  }

  const [searchParams, setSearchParams] = useSearchParams();

  const [totalItems, setTotalItems] = useState(10)

  const [userTypeFilter, setUserTypeFilter] = useState<boolean|string>("")

  const [userId, setUserId] = useState(null)

  useEffect(() => {
    axios.get(`${baseUrl}voucher-request/get-all/?pageSize=1000000`, config).then(res => setTotalItems(res.data.length))
  }, [])

  useEffect(() => {
    if (searchParams.get("page") !== "" ) {setPage(Number(searchParams.get("page")) || 1)}
    else if (searchParams.get("createAtFrom") !== "") {setDateRange([(searchParams.get("createAtFrom")), ""])}
    else if (searchParams.get("createAtTo") !== "") {setDateRange([(searchParams.get("createAtFrom")), (searchParams.get("createAtTo"))])}
    else if (searchParams.get("pageSize") !== "") {setPageSize(Number(searchParams.get("pageSize")) || 10)}
    else if (searchParams.get("query") !== "") {setQuery(searchParams.get("query") || "")}
    else if (searchParams.get("order") !== "") {setOrder(searchParams.get("order") || "asc")}
    else if (searchParams.get("orderBy") !== "") {setOrderBy(searchParams.get("orderBy") || "id")}
    else if (searchParams.get("voucherStatus") !== "") {setStatusFilter(searchParams.get("voucherStatus") || "")}
    else {}
  }, [])

  const fetchData = async () => {
    const res: any = await axios.get(`${baseUrl}voucher-request/get-all/?page=${page}&pageSize=${pageSize}&query=${query}&createAtFrom=${dateRange[0]}&createAtTo=${dateRange[1]}&order=${order}&orderBy=${orderBy}&voucherStatus=${statusFilter}&authSub=${userTypeFilter}`, config)
    setTotalItems(res.total)
    return res.data
  }
  
  const { data, status } = useQuery(["get-all-vouchers", [dateRange, query, order, orderBy, page, pageSize, statusFilter, userTypeFilter]], fetchData, {retry: 1, suspense: false})

  //Filter invoices by dates
  const handleDateRange = (dates: any) => {
    if (dates[0] !== ""  && dates[1] !== "") setDateRange([dayjs(dates[0]).unix(), dayjs(dates[1]).unix()])
    else {}
  }

  //Filter by word
  const handleInputWordToFilter = (e) => {
    setQuery(e.target.value)
  }

  //Clear Filters
  const clearFilters = () => {
    setQuery("")
    setDateRange(["", ""])
    setUserTypeFilter("")
  }

  //Status Filter
  const handleStatusFilter = (value: any) => {
    if (value === "Demo") setStatusFilter("demo")
    else if (value === "Pendiente") setStatusFilter("pending")
    else if (value === "En Tramite") setStatusFilter("inProccess")
    else if (value === "Completadas") setStatusFilter("completed")
    else setStatusFilter("")
  }
  const selectStatusOptions = [{value: "Demo"}, {value: "Pendiente"}, {value: "En Tramite"}, {value: "Completadas"}, {value: "Todos"}]

  const selectStatus = <Select onChange={handleStatusFilter} showArrow defaultValue={["Todos"]} style={{ width: '100%' }} options={selectStatusOptions}/>


  const handleUserStatusFilter = (value: any) => {
    switch (value) {
      case "Todos": setUserTypeFilter("")
        break;
      case "Demo": setUserTypeFilter(false)
        break;
      case "Pago": setUserTypeFilter(true)
        break;
      default: setUserTypeFilter("")
        break;
    }
  }

  const selectUserStatusOptions = [{value: "Todos"}, {value: "Demo"}, {value: "Pago"}]


  const selectUserStatus = <Select placeholder={<div style={{lineHeight: "38px"}}>{t("adminDashboardPage.vouchers.filterByUserState")}</div>} style={{fontFamily: "Dosis", fontSize: "18px", height: "38px", lineHeight: "38px"}} onChange={handleUserStatusFilter} showArrow options={selectUserStatusOptions}/>


  //Show the pdf in a new tab
  const showPdf = async (id) => {
    await axios.get(`${baseUrl}voucher-request/get-file/${id}`, {
      headers: {Authorization: `Bearer ${token}`},
      responseType: 'blob'
      }).then(response => {
  //Create a Blob from the PDF Stream
      const file = new Blob(
        [response.data],
        {type: 'application/pdf'});
        //Build a URL from the file
        const fileURL = URL.createObjectURL(file);
        //Open the URL on new Window
        window.open(fileURL);
      })
      .catch(error => {
          console.log(error);
      });
    };

  return(
    <div style={{maxHeight: "100vh"}}>
      <Typography style={DASHBOARD_TITLES} >{t("vouchers.title")}</Typography>

        <Row style={{display: "flex", justifyContent: "normal", gap: "5px", width: "fit-content", paddingBottom: "15px"}}>
          <Col style={{display: "flex", gap: "20px"}}>
            <RangePicker value={(dateRange[0] !== "") ? [dayjs.unix(dateRange[0]), dayjs.unix(dateRange[1])] : [null, null]} format={"DD-MM-YYYY"} style={{height: "38px", borderRadius: "4px"}} onChange={(dates) => handleDateRange(dates)}></RangePicker>
          </Col>
          <Col style={{display: "flex", gap: "10px"}}>
            <Input value={query} style={{fontFamily: "Dosis", fontSize: "18px", height: "38px",}} placeholder={t("vouchers.table.filterInputPlaceholder") || ""} onChange={(e) => handleInputWordToFilter(e)}/>
          </Col>
          <Col>
            {selectUserStatus}
          </Col>
          <Col style={{display: "flex"}}>
            <Button id='btn-secondary-outline' style={{height: "38px", lineHeight: "17px"}} onClick={clearFilters}>{t("vouchers.table.buttonClearFilters")}</Button>
          </Col>
        </Row>
      <Table loading={{spinning: (status === "loading"), indicator: loadingIcon}} style={{overflow: "hidden"}} scroll={{ y: "68vh" }} dataSource={( data === undefined || status === "error" || data.length === 0 ) ? [] : data.items } size="large" pagination={{showSizeChanger: true, size: "small", showQuickJumper: false,  total: (data === undefined) ? totalItems : data.total, responsive: true, onChange(page, pageSize) {setPage(page); setPageSize(pageSize)} }}>
        <Column 
          width={"55px"} 
          colSpan={1} 
          title={<div style={{display: "flex", alignItems: "center"}}>{t("vouchers.table.id")}<div style={{display: "flex", flexDirection: "column", height: "20px"}}><Button onClick={() => {setOrderBy("id"); setOrder("asc"); }} style={{border: "none", background: "none", height: "10px" }} icon={<CaretUpOutlined id='asc-desc-icons' style={{height: "10px", margin: "0px", padding: "0px", position: "absolute", top: 0, left: 5}}/>}></Button><Button onClick={() => {setOrderBy("id"); setOrder("desc")}} style={{border: "none", background: "none", height: "15px"}} icon={<CaretDownOutlined id='asc-desc-icons' style={{height: "10px", margin: "0px", padding: "0px", position: "absolute", top: 0, left: 5}}/>}></Button></div></div>} 
          dataIndex="id" 
          key="id" 
        />
        <Column
          width={"170px"}
          title={<div style={{display: "flex", alignItems: "center"}}>{t("vouchers.table.point_of_sale")}<div style={{display: "flex", flexDirection: "column", height: "20px"}}><Button onClick={() => {setOrderBy("point_of_sale"); setOrder("asc"); }} style={{border: "none", background: "none", height: "10px" }} icon={<CaretUpOutlined id='asc-desc-icons' style={{height: "10px", margin: "0px", padding: "0px", position: "absolute", top: 0, left: 5}}/>}></Button><Button onClick={() => {setOrderBy("point_of_sale"); setOrder("desc")}} style={{border: "none", background: "none", height: "15px"}} icon={<CaretDownOutlined id='asc-desc-icons' style={{height: "10px", margin: "0px", padding: "0px", position: "absolute", top: 0, left: 5}}/>}></Button></div></div>}
          dataIndex="point_of_sale"
          key="point_of_sale"
        />
        <Column width={"190px"} title={t("vouchers.table.type")} dataIndex="type" key="type" />
        <Column width={"170px"} title={t("vouchers.table.doc_number")} dataIndex="doc_number" key="doc_number" />
        <Column width={"170px"} title={t("vouchers.table.cuit")} render={(_: any, record: any) => (record.user.cuit)}/>
        <Column width={"90px"} filterDropdown={selectStatus} title={t("vouchers.table.status")} dataIndex="status" key="status" render={(_: any, { status }: any) => (
                <Tag color={(status === "pending")? "blue" : (status === "inProccess") ? "orange" : (status === "completed") ? "green" : "#c5c5c5"}>
                  {(status === "pending") ? "Pendiente" : (status === "inProccess") ? "En Tramite" : (status === "completed") ? "Completada" : "Demo"}
                </Tag> )}
        />
        <Column width={"140px"} key={"modificar"} render={(_: any, record: any) => (
          <Button id='button-small-outline-green-bg' onClick={() => {setVoucherId(record.id); setUserId(record.user.id); showDrawer()}} >{t("adminDashboardPage.vouchers.editButton")}</Button>
        )} />
        <Column width={"140px"} key={"upload"} render={(_: any, record: any) => (
          (record.status === "completed") ?
          <div style={{display: "flex", flexDirection: "row", gap: "5px"}}>
            <Upload method="PATCH" name='file' accept='file' action={`${baseUrl}voucher-request/upload-file/${record.id}`} headers={config.headers}><Button id='btn-secondary-outline' style={{height: "38px", lineHeight: "17px"}} icon={<UploadOutlined style={{fontSize: "17px"}} />}>{t("adminDashboardPage.vouchers.uploadButton")}</Button></Upload>
            {(record.file_name !== undefined && record.file_name !== null) ? <Button id='button-small-outline-green-bg' onClick={() => showPdf(record.id)}>{t("adminDashboardPage.vouchers.showVoucherButton")}</Button> : <></>}
          </div>
          :
          <></>
        )
        }/>
      </Table>
      <Drawer destroyOnClose keyboard={false} maskClosable={false} title={t("vouchers.form.title")} placement="right" onClose={onClose} open={open} width={(breakpoint !== "desktop") ? "100vw" : 1000}>
            <VoucherRequestForm userId={userId} closeDrawer={onClose} voucherId={voucherId} />
      </Drawer>
    </div>
  )};