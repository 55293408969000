import axios from "axios";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { errorMessageLogin } from "../../../common/errors";
import { ResetPassword } from "../ResetPasswordPage.types";

const useResetPassword = () => {
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const baseUrl = process.env.REACT_APP_BASE_URL
    const { resetPasswordToken } = useParams();

    const config = {
        headers: {Authorization: `Bearer ${resetPasswordToken}`}
    }


    return useMutation(async (formResetPassword: ResetPassword) => {
        return await axios.patch(`${baseUrl}auth/reset-password`, formResetPassword, config)
    },
    {
        onSuccess: (res) => {
            const response = JSON.stringify(res.data.token)
            localStorage.setItem('token', response)
            queryClient.setQueryData('token', response)
            let token = JSON.parse(response || '{}')
            axios.get(`${baseUrl}user/me`, {headers: {Authorization: `Bearer ${token}`}}).then(res => {
                if (res.data.role?.type) {queryClient.setQueryData('role', res.data.role.type); localStorage.setItem("role", res.data.role.type); (res.data.role.type === "admin") ? navigate('/admin/users') : navigate('/dashboard/')}
                else {queryClient.setQueryData('role', 'user'); queryClient.setQueryData('userData', res.data); localStorage.setItem("role", "user"); navigate('/dashboard/')}
        })
        },
        onError: (err) => {console.log('Error:', err); errorMessageLogin()}
    });
};

export default useResetPassword;