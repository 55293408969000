import { Button, Carousel, Col, Image, Modal, Radio, Row, Typography, UploadProps, message } from 'antd';
import React, { useEffect, useState } from 'react'
import { useQueryClient } from 'react-query';
import { useBreakpoint } from 'use-breakpoint';
import { DASHBOARD_SECTION_TITLES } from '../common/utils/styles';
import { t } from 'i18next';
import axios from 'axios';
import {  CheckOutlined, CloudUploadOutlined, UploadOutlined, InboxOutlined } from '@ant-design/icons';
import Prices from '../../pages/LandingPage/sections/Prices';
import Dragger from 'antd/es/upload/Dragger';
import Upload, { RcFile } from 'antd/es/upload';
import { Popup, Swiper } from 'antd-mobile';
import { useNavigate } from 'react-router-dom';


const BREAKPOINTS = { mobile: 0, tablet: 768, desktop: 1280 }
type Props = {}

const InvoiceTemplate = (props: Props) => {

    const { breakpoint, maxWidth, minWidth } = useBreakpoint(BREAKPOINTS);
    const queryClient = useQueryClient()
    const navigate = useNavigate()

    const baseUrl = process.env.REACT_APP_BASE_URL
    const token = JSON.parse(localStorage.getItem("token") || '{}')
    const config = {
        headers: {Authorization: `Bearer ${token}`}
    }

    //Modal that show plans
    const [openModalPrices, setOpenModalPrices] = useState(false);
    const showModalPrices = () => {
        setOpenModalPrices(true);
        };
        
    const userData: any = queryClient.getQueryData("userData")

    const isDemo = () => {
        if (userData.subscription === null) return true
        else {
          if (
            userData.subscription.status === "authorized" ||
            userData.subscription.status === "oneshoot"
              )
                return false;
              else return true;
            }
        }   

    const [radio0, setRadio0] = useState<boolean>(false)
    const [radio1, setRadio1] = useState<boolean>(false)
    const [radio2, setRadio2] = useState<boolean>(false)
    const [radio3, setRadio3] = useState<boolean>(false)

    const [key, setKey] = useState<number>(0)

    const onSelectTemplate0 = (e: any , selected: number) => {
        setKey(selected)
        setRadio1(false)
        setRadio0(true)
        setRadio2(false)
        setRadio3(false)
        sendToApi(selected)
    }

    const onSelectTemplate1 = (e: any , selected: number) => {
        setKey(selected)
        setRadio0(false)
        setRadio1(true)
        setRadio2(false)
        setRadio3(false)
        sendToApi(selected)
    }

    const onSelectTemplate2 = (e: any, selected: number) => {
        setKey(selected)
        setRadio0(false)
        setRadio2(true)
        setRadio1(false)
        setRadio3(false)
        sendToApi(selected)
    }

    const onSelectTemplate3 = (e: any, selected: number) => {
        setKey(selected)
        setRadio0(false)
        setRadio3(true)
        setRadio2(false)
        setRadio1(false)
        sendToApi(selected)
    }

    const imgStyleOnMobileSelected = {width: "100%", border: "4px solid #03b9ac", borderRadius: "4px", borderColor: "#03b9ac"}
    const imgStyleOnMobileNotSelected = {width: "100%"}


    const sendToApi = async(selected: number) =>{
        await axios.put(`${baseUrl}user/update-template`, {template: selected}, config).then(res => {successMessage(); axios.get(`${baseUrl}user/me`, {headers: {Authorization: `Bearer ${token}`}}).then(res => {queryClient.setQueryData('userData', res.data)})}).catch(e => {console.log(e); errorMessage();  })
    }

    const [messageApi, contextHolder] = message.useMessage();

    const successMessage = () => {
        messageApi.open({
          type: 'success',
          icon: <CheckOutlined style={{color: "#03b9ac"}} />,
          content: t("dashboardPage.userSettings.invoiceTemplate.resultSuccessMessage")
        })
      }

    const errorMessage = () => {
        messageApi.open({
          type: 'error',
          content: t("dashboardPage.userSettings.invoiceTemplate.resultSuccessMessage")
        })
      }

      const [hasLogo, setHasLogo] = useState<boolean | null>(null)
      const [logoUrl, setLogoUrl] = useState<any>()

    useEffect(() => {
        const userData: any = queryClient.getQueryData("userData")
        if (isDemo()) {setRadio0(true); setKey(0)}
        else {
            if (userData.template === null || userData.template === undefined) {setRadio0(true); setKey(0)}
            else if (userData.template === 0 ) {setRadio0(true); setKey(0)}
            else if (userData.template === 1 ) {setRadio1(true); setKey(1)}
            else if (userData.template === 2 ) {setRadio2(true); setKey(2)}
            else if (userData.template === 3 ) {setRadio3(true); setKey(3)}
        }
    }, [])

    useEffect(() => {
        if (userData.logo !== null) {setHasLogo(true); axios.get(`${baseUrl}user/get-logo-url`, config).then(res => {setLogoUrl(res.data.logo)}).catch(e => console.log(e))}
    }, [])
    

    const uploadLogoProps: UploadProps = {
        name: 'file',
        multiple: false,
        maxCount: 1,
        accept: ".png, .jpg, .jpeg",
        action: `${baseUrl}user/upload-logo`,
        headers: config.headers,
        method: "PUT",
        onChange: (e) => {
            if (e.file.status === "done") axios.get(`${baseUrl}user/get-logo-url`, config).then(res => {setLogoUrl(res.data.logo)}).catch(e => console.log(e))
        },
      };
    
    
  return (
    <>
    {contextHolder}
    {(breakpoint === "mobile" || breakpoint === "tablet") ?
    <>
        <div style={{paddingBottom: "80px", backgroundColor: "#F2F0F0", borderRadius: "4px"}}>
        <Row>
            <Col span={24}>
                <Typography style={{fontWeight: "800", fontFamily: "Poppins", paddingLeft: "25px", paddingTop: "25px"}}>{t("dashboardPage.userSettings.invoiceTemplate.title")}</Typography>
            </Col>
        </Row>
        <Row>
            <Col span={24} >
                <Row style={{display: 'flex', flexDirection: "row", gap: "5px"}}>
                    <Typography style={{fontWeight: "500", fontFamily: "Poppins", paddingLeft: "25px"}}>{t("dashboardPage.userSettings.invoiceTemplate.templateSelectedLabel")}</Typography>
                    <Typography style={{fontWeight: "300", fontFamily: "Poppins"}}>{key+1}</Typography>
                </Row>
            </Col>
        </Row>
        <div key={key}>
            <Swiper
            defaultIndex={key}
            >
                <Swiper.Item onClick={( isDemo() ) ? () => setOpenModalPrices(true) : (e) => onSelectTemplate0(e, 0)}>
                    <Col span={24} style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                        <div style={{ padding: "20px"}} >
                            <img src='/template-factura-v0.png' style={(radio0) ? imgStyleOnMobileSelected : imgStyleOnMobileNotSelected}/>
                        </div>
                    </Col>
                </Swiper.Item>
                <Swiper.Item onClick={( isDemo() ) ? () => setOpenModalPrices(true) : (e) => onSelectTemplate1(e, 1)}>
                    <Col span={24} style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                        <div style={{ padding: "20px"}} >
                            <img src='/template-factura-v1.png' style={(radio1) ? imgStyleOnMobileSelected : imgStyleOnMobileNotSelected}/>
                        </div>
                    </Col>
                </Swiper.Item>
                <Swiper.Item onClick={( isDemo() ) ? () => setOpenModalPrices(true) : (e) => onSelectTemplate2(e, 2)}>
                    <Col span={24} style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                        <div style={{ padding: "20px"}} >
                            <img src='/template-factura-v2.png' style={(radio2) ? imgStyleOnMobileSelected : imgStyleOnMobileNotSelected}/>
                        </div>
                    </Col>
                </Swiper.Item>
                <Swiper.Item onClick={( isDemo() ) ? () => setOpenModalPrices(true) : (e) => onSelectTemplate3(e, 3)}>
                    <Col span={24} style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                        <div style={{ padding: "20px"}} >
                            <img src='/template-factura-v3.png' style={(radio3) ? imgStyleOnMobileSelected : imgStyleOnMobileNotSelected}/>
                        </div>
                    </Col>
                </Swiper.Item>
            </Swiper>
        </div>
        <Row>
            <Col span={24}>
                <Typography style={{fontWeight: "800", fontFamily: "Poppins", paddingLeft: "25px", paddingTop: "25px", paddingBottom: "25px"}}>{t("dashboardPage.userSettings.logo.title")}</Typography>
            </Col>
        </Row>
        <Row style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
            <Col span={21}>
                <Dragger {...uploadLogoProps} style={{display: "flex", minWidth: "100%"}}>

                    {
                        (hasLogo) ? 
                        <div style={{display: "flex", flexDirection: "column", alignItems: "center", minWidth: "73vw"}}>
                            <img src={logoUrl} style={{width: "60%"}}/>
                            <p style={{display: "flex", marginTop: "20px", fontFamily: "Poppins", width: "100%", justifyContent: "center"}} className="ant-upload-text">{t("dashboardPage.userSettings.invoiceTemplate.logoUploadOnMobile")}</p>
                        </div>
                        :
                        <div style={{display: "flex", flexDirection: "column", alignItems: "center", minWidth: "73vw"}}>
                            <p style={{color: '#03b9ac', fontFamily: "Poppins"}}>
                                <CloudUploadOutlined style={{fontSize: "55px"}} />
                            </p>
                            <p className="ant-upload-text" style={{fontFamily: "Poppins"}}>{t("dashboardPage.userSettings.invoiceTemplate.logoUploadOnMobile")}</p>
                            <p className="ant-upload-hint" style={{fontFamily: "Poppins"}}>
                                {t("dashboardPage.userSettings.invoiceTemplate.logoSupportedFiles")}
                            </p>
                        </div>
                    }   

                </Dragger>
            </Col>
        </Row>
        <Popup showCloseButton visible={openModalPrices} onClose={() => setOpenModalPrices(false)} onMaskClick={() => setOpenModalPrices(false)} bodyStyle={{ height: '95vh', overflow: "auto", display: "flex", flexDirection: "column" }} mask={true} closeOnSwipe >
            <Prices demoSelected={isDemo()} />
        </Popup>
        </div>
    </>
    :
    <div>
        <Row>
            <Col span={24}>
                <Typography style={DASHBOARD_SECTION_TITLES}>{t("dashboardPage.userSettings.invoiceTemplate.title")}</Typography>
            </Col>
        </Row>
        <Row style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
            <Col span={5} style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                <div>
                    <Image src='/template-factura-v0.png' style={{width: "100%", border: (radio0) ? "4px solid #03b9ac" : "none", borderRadius: "4px"}}/>
                </div>
                <Radio.Button style={{ borderWidth: "1px", marginTop: "10px", border: "1px solid", borderColor: "#03b9ac", color: "#03b9ac", borderRadius: "0.25rem"}} defaultChecked={radio0} checked={radio0} onChange={( isDemo() ) ? () => setOpenModalPrices(true) : (e) => onSelectTemplate0(e, 0)}>{t("dashboardPage.userSettings.invoiceTemplate.select")}</Radio.Button>
            </Col>
            <Col span={5} style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                <div>
                    <Image src='/template-factura-v1.png' style={{width: "100%",  border: (radio1) ? "4px solid #03b9ac" : "none", borderRadius: "4px"}}/>
                </div>
                <Radio.Button style={{marginTop: "10px", border: "1px solid", borderColor: "#03b9ac", color: "#03b9ac", borderRadius: "0.25rem"}} defaultChecked={radio1} checked={radio1} onChange={( isDemo() ) ? () => setOpenModalPrices(true) : (e) => onSelectTemplate1(e, 1)}>{t("dashboardPage.userSettings.invoiceTemplate.select")}</Radio.Button>
            </Col>
            <Col span={5} style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                <div>
                    <Image src='/template-factura-v2.png' style={{width: "100%",  border: (radio2) ? "4px solid #03b9ac" : "none", borderRadius: "4px" }}/>
                </div>
                <Radio.Button style={{marginTop: "10px", border: "1px solid", borderColor: "#03b9ac", color: "#03b9ac", borderRadius: "0.25rem"}} defaultChecked={radio2} checked={radio2} onChange={( isDemo() ) ? () => setOpenModalPrices(true) : (e) => onSelectTemplate2(e, 2)}>{t("dashboardPage.userSettings.invoiceTemplate.select")}</Radio.Button>
            </Col>
            <Col span={5} style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                <div>
                    <Image src='/template-factura-v3.png' style={{width: "100%", border: (radio3) ? "4px solid #03b9ac" : "none", borderRadius: "4px"}}/>
                </div>
                <Radio.Button style={{marginTop: "10px", border: "1px solid", borderColor: "#03b9ac", color: "#03b9ac", borderRadius: "0.25rem"}} defaultChecked={radio3} checked={radio3} onChange={( isDemo() ) ? () => setOpenModalPrices(true) : (e) => onSelectTemplate3(e, 3)}>{t("dashboardPage.userSettings.invoiceTemplate.select")}</Radio.Button>
            </Col>
        </Row>
        <Row style={{marginTop: "20px", marginBottom: "10px"}}>
            <Col span={24} onClick={(isDemo()) ? () => setOpenModalPrices(true) : () => {}}>
                    <Dragger disabled={isDemo()} {...uploadLogoProps} style={{display: "flex", width: "100%"}}>

                        {
                            (hasLogo) ? 
                            <div>
                                <img src={logoUrl} style={{width: "30%"}}/>
                                <p style={{marginTop: "20px", fontFamily: "Poppins"}} className="ant-upload-text">{t("dashboardPage.userSettings.invoiceTemplate.logoUploadTitle")}</p>
                            </div>
                            :
                            <>
                                <p style={{color: '#03b9ac', fontFamily: "Poppins"}}>
                                    <CloudUploadOutlined style={{fontSize: "55px"}} />
                                </p>
                                <p className="ant-upload-text" style={{fontFamily: "Poppins"}}>{t("dashboardPage.userSettings.invoiceTemplate.logoUploadTitle")}</p>
                                <p className="ant-upload-hint" style={{fontFamily: "Poppins"}}>
                                    {t("dashboardPage.userSettings.invoiceTemplate.logoSupportedFiles")}
                                </p>
                            </>
                        }

                    </Dragger>
            </Col>   
        </Row>
        <Modal open={openModalPrices} onCancel={() => setOpenModalPrices(false)} width={ "75vw"} maskClosable={true} closable footer={null}>
            <Prices demoSelected={isDemo()} />
        </Modal>
    </div>

    }
    </>
  )
}

export default InvoiceTemplate