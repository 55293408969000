import React from 'react'
import { Layout } from 'antd'
import { Content } from 'antd/es/layout/layout'
import { Outlet } from 'react-router-dom'
import { DASHBOARD_BACKGROUND_COLOR, PADDING_CONTENT } from './utils/styles'
import { useBreakpoint } from 'use-breakpoint'

const BREAKPOINTS = { mobile: 0, tablet: 768, desktop: 1280 }

export default function DashboardLayout({collapsed}: {collapsed: boolean}) {

  const { breakpoint, maxWidth, minWidth } = useBreakpoint(BREAKPOINTS);

  return (
    <Layout style={{ height: "100vh", maxHeight: "100vh"}} className="site-layout">
        <Content style={ (breakpoint === "mobile" || breakpoint === "tablet") ? {width: "100vw"} : collapsed ? { margin: '0 16px', marginLeft: "80px"} : { margin: '0 16px', marginLeft: "200px"} }>
          <div style={{ padding: PADDING_CONTENT, background: DASHBOARD_BACKGROUND_COLOR, minWidth: (breakpoint !== "desktop") ? "auto" : "700px", maxHeight: "100vh" }}>
            <Outlet/>
          </div>
        </Content>
      </Layout>
  )
}