import React from 'react'
import { Col, Form, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { RESUME_TITLES, RESUME_VALUES } from '../common/utils/styles';
import dayjs from 'dayjs';
import { useBreakpoint } from 'use-breakpoint';

const BREAKPOINTS = { mobile: 0, tablet: 768, desktop: 1280 }

const Step4 = () => {

    const { t } = useTranslation()
    const { breakpoint, maxWidth, minWidth } = useBreakpoint(BREAKPOINTS);
    const form = Form.useFormInstance();

    const associatedVoucherRender = (array: any) => {if (array === undefined) return <Row><Typography style={RESUME_VALUES}>{t("vouchers.form.associated_vouchers_empty")}</Typography></Row>
    else return array.map((values: any) => <Row style={{gap: "10px"}} key={values.key}>
      <Typography style={RESUME_TITLES}>{t("vouchers.form.type")+": "}</Typography> <Typography style={RESUME_VALUES}>{values.type}</Typography>
      <Typography style={RESUME_TITLES}>{t("vouchers.form.point_of_sale")+": "}</Typography> <Typography style={RESUME_VALUES}>{values.point_of_sale}</Typography>
      <Typography style={RESUME_TITLES}>{t("vouchers.form.voucher_number")+": "}</Typography> <Typography style={RESUME_VALUES}>{values.number}</Typography>
      <Typography style={RESUME_TITLES}>{t("vouchers.form.date")+": "}</Typography> <Typography style={RESUME_VALUES}>{(values.date === undefined) ? "" : dayjs(values.date).format("DD-MM-YYYY")}</Typography>
    </Row>)}

  return (
    <>
        <Form.Item shouldUpdate>
        {() => {
          const comprobantesAsociados = form.getFieldValue("associated_voucher")
          const voucherLines = form.getFieldValue("voucher_request_lines")
          return <div id='step20' style={{ display: "flex", flexDirection: "column", width: "100%", fontFamily: "Dosis"}}>
              <Row><Col span={24}><Typography style={{fontFamily: "Dosis", fontSize: "30px", fontWeight: "lighter", textAlign: "center", paddingBottom: "10px"}}>{t("vouchers.form.fifthStepTitle")}</Typography></Col></Row>
              <div className='resumeDiv' >   
                <Row><Col span={24}><Typography style={{fontFamily: "Dosis", fontSize: "20px", fontWeight: "lighter", paddingBottom: "5px", paddingTop: "5px", textAlign: "center"}}>{t("vouchers.form.secondStepTitle")}</Typography></Col></Row>
                <Row>
                  <Col span={(breakpoint !== "desktop") ? 12 : 6}><Typography style={RESUME_TITLES}>{t("vouchers.form.point_of_sale")+": "}</Typography>{(breakpoint !== "desktop") ? <Typography style={RESUME_VALUES}>{form.getFieldValue("point_of_sale")}</Typography> : <></>}</Col>
                  <Col span={(breakpoint !== "desktop") ? 12 : 6}><Typography style={RESUME_TITLES}>{t("vouchers.form.type")+": "}</Typography>{(breakpoint !== "desktop") ? <Typography style={RESUME_VALUES}>{form.getFieldValue("type")}</Typography> : <></>}</Col>
                  <Col span={(breakpoint !== "desktop") ? 12 : 6}><Typography style={RESUME_TITLES}>{t("vouchers.form.date")+": "}</Typography>{(breakpoint !== "desktop") ? <Typography style={RESUME_VALUES}>{(form.getFieldValue("date_not_formatted").format("DD-MM-YYYY"))}</Typography> : <></>}</Col>
                  <Col span={(breakpoint !== "desktop") ? 12 : 6}><Typography style={RESUME_TITLES}>{t("vouchers.form.concept_selector")+": "}</Typography>{(breakpoint !== "desktop") ? <Typography style={RESUME_VALUES}>{form.getFieldValue("concept")}</Typography> : <></>}</Col>
                </Row>
                <Row hidden={(breakpoint !== "desktop")}>
                  <Col span={6}><Typography style={RESUME_VALUES}>{form.getFieldValue("point_of_sale")}</Typography></Col>
                  <Col span={6}><Typography style={RESUME_VALUES}>{form.getFieldValue("type")}</Typography></Col>
                  <Col span={6}><Typography style={RESUME_VALUES}>{(form.getFieldValue("date_not_formatted").format("DD-MM-YYYY"))}</Typography></Col>
                  <Col span={6}><Typography style={RESUME_VALUES}>{form.getFieldValue("concept")}</Typography></Col>
                </Row>
              </div> 
              <div className='resumeDiv' >   
                <Row><Col span={24}><Typography style={{fontFamily: "Dosis", fontSize: "20px", fontWeight: "lighter", paddingBottom: "5px", paddingTop: "5px", textAlign: "center"}}>{`${t("vouchers.form.thirdStepTitle")}`}</Typography></Col></Row>
                <Row>
                  <Col span={(breakpoint !== "desktop") ? 24 : 6}><Typography style={RESUME_TITLES}>{t("vouchers.form.VAT_conditions")+": "}</Typography>{(breakpoint !== "desktop") ? <Typography style={RESUME_VALUES}>{form.getFieldValue("vat_condition")}</Typography> : <></>}</Col>
                  <Col span={(breakpoint !== "desktop") ? 24 : 6}><Typography style={RESUME_TITLES}>{t("vouchers.form.doc_type")+": "}</Typography>{(breakpoint !== "desktop") ? <Typography style={RESUME_VALUES}>{form.getFieldValue("doc_type")}</Typography> : <></>}</Col>
                  <Col span={(breakpoint !== "desktop") ? 24 : 6}><Typography style={RESUME_TITLES}>{t("vouchers.form.doc_number")+": "} </Typography>{(breakpoint !== "desktop") ? <Typography style={RESUME_VALUES}>{form.getFieldValue("doc_number")}</Typography> : <></>}</Col>
                  <Col span={(breakpoint !== "desktop") ? 24 : 6}><Typography style={RESUME_TITLES}>{t("vouchers.form.surname_name_or_business_name")+": "} </Typography>{(breakpoint !== "desktop") ? <Typography style={RESUME_VALUES}>{form.getFieldValue("business_name")}</Typography> : <></>}</Col>
                </Row>
                <Row hidden={(breakpoint !== "desktop")}>
                  <Col span={6}><Typography style={RESUME_VALUES}>{form.getFieldValue("vat_condition")}</Typography></Col>
                  <Col span={6}><Typography style={RESUME_VALUES}>{form.getFieldValue("doc_type")}</Typography></Col>
                  <Col span={6}><Typography style={RESUME_VALUES}>{form.getFieldValue("doc_number")}</Typography></Col>
                  <Col span={6}><Typography style={RESUME_VALUES}>{form.getFieldValue("business_name")}</Typography></Col>
                </Row>
                <Row>
                  <Col span={(breakpoint !== "desktop") ? 24 : 6}><Typography style={RESUME_TITLES}>{t("vouchers.form.sale_conditions")+": "}</Typography>{(breakpoint !== "desktop") ? <Typography style={RESUME_VALUES}>{form.getFieldValue("sale_conditions")}</Typography> : <></>}</Col>
                </Row>
                <Row>
                  <Col hidden={(breakpoint !== "desktop")} span={6}><Typography style={RESUME_VALUES}>{form.getFieldValue("sale_conditions")}</Typography></Col>
                  <Col span={24}><Typography style={{fontFamily: "Dosis", fontSize: "18px", fontWeight: "600", padding: "5px 0px"}}>{t("vouchers.form.associated_vouchers")+": "}</Typography></Col>
                </Row>
                <Row>
                  <Col>
                    {associatedVoucherRender(comprobantesAsociados)}
                  </Col>
                </Row>
              </div> 
              <div className='resumeDiv' style={{marginBottom: "0px"}} >
                  <Row>
                    <Col span={24}><Typography style={{fontFamily: "Dosis", fontSize: "20px", fontWeight: "lighter", paddingBottom: "5px", paddingTop: "5px", textAlign: "center"}}>{`${t("vouchers.form.fourthStepTitle")}`}</Typography></Col>
                  </Row>
                  <Row>
                    <Col span={24}><Typography style={{fontFamily: "Dosis", fontSize: "18px", fontWeight: "600", padding: "5px 0px"}}>{t("vouchers.form.voucherLinesTitle")+": "}</Typography></Col>
                  </Row>
                  <>
                    {voucherLines.map((values: any) => <Row style={{gap: "10px"}} key={values.key}>
                      <Typography style={RESUME_TITLES}>{t("vouchers.form.product_or_service_label")+": "}</Typography> <Typography style={RESUME_VALUES}>{values.description}</Typography>
                      <Typography style={RESUME_TITLES}>{t("vouchers.form.quantity")+": "}</Typography> <Typography style={RESUME_VALUES}>{values.quantity}</Typography>
                      <Typography style={RESUME_TITLES}>{t("vouchers.form.measure")+": "}</Typography> <Typography style={RESUME_VALUES}>{values.measure}</Typography>
                      <Typography style={RESUME_TITLES}>{t("vouchers.form.unit_price")+": "}</Typography> <Typography style={RESUME_VALUES}>{"$ " + values.unit_price}</Typography>
                      <Typography style={RESUME_TITLES}>{t("vouchers.form.amount")+": "}</Typography> <Typography style={RESUME_VALUES}>{(values.quantity === undefined || values.unit_price === undefined) ? ("") : ("$ " + ((values.quantity)*(values.unit_price)))}</Typography>
                    </Row>)}
                  </>
                </div>
          </div>
        }}
      </Form.Item>
  </>
  )
}

export default Step4