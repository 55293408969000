import React from 'react'
import { Route, Routes, useParams } from 'react-router-dom'
import LandingPageOptions from '../pages/AdminDashboardPage/sections/LandingPageOptions/LandingPageOptions'
import Users from '../pages/AdminDashboardPage/sections/Users'
import VoucherRequests from '../pages/AdminDashboardPage/sections/VoucherRequests'
import Dashboard from '../pages/DashBoardPage/DashboardPage'
import AdminDashboard from '../pages/AdminDashboardPage/adminDashboardPage';
import Billing from '../pages/DashBoardPage/sections/Billing'
import Home from '../pages/DashBoardPage/sections/Home'
import Profile from '../pages/DashBoardPage/sections/Profile/Profile'
import Vouchers from '../pages/DashBoardPage/sections/vouchers/Vouchers'
import LandingPage from '../pages/LandingPage/LandingPage'
import LoginPage from '../pages/LoginPage/LoginPage'
import ResetPasswordPage from '../pages/ResetPasswordPage/ResetPasswordPage'
import SigninPage from '../pages/SigninPage/SigninPage'
import { PrivateRoute } from './privateRoute'
import LoadingPage from '../pages/LoadingPage/LoadingPage'
import ContactPage from '../pages/ContactPage'
import BillingHistoryById from '../pages/AdminDashboardPage/sections/BillingHistoryById'
import TermsAndConditionsPage from '../pages/TermsAndConditionsPage'
import Customize from '../pages/DashBoardPage/sections/Customize'
import MonotributoSubscription from './MonotributoSubscription'
import MonotributoSubscriptionSuccess from '../pages/MonotributoSubscriptionSuccess'

function Router() {

    let { resetPasswordToken } = useParams();

  return (
        <Routes>
          <Route path='/' element={<LandingPage />}></Route>
          <Route path='/contact' element={<ContactPage />}></Route>
          <Route path='/reset-password/:resetPasswordToken' element={<ResetPasswordPage />}></Route>
          <Route path='/tyc' element={<TermsAndConditionsPage />}></Route>
          <Route path='/login' element={<LoginPage />}></Route>
          <Route path='/signin' element={<SigninPage />}></Route>
          <Route path={'/loading'} element={<LoadingPage />}></Route>
          <Route path={'/monotributo-subscription'} element={<MonotributoSubscription />}></Route>
          <Route path={'/monotributo-subscription-success'} element={<MonotributoSubscriptionSuccess />}></Route>


          <Route path='/dashboard/' element={<PrivateRoute componentUser={Dashboard}></PrivateRoute>}>
            <Route path={'/dashboard/user'} element={<Profile />}></Route>
            <Route path={'/dashboard/billing'} element={<Billing />}></Route>
            <Route path={'/dashboard/vouchers'} element={<Vouchers />}></Route>
            <Route path={'/dashboard/customize'} element={<Customize />}></Route>
          </Route>
          <Route path='/admin/' element={<PrivateRoute componentAdmin={AdminDashboard}></PrivateRoute>}>
            <Route path={'/admin/users'} element={<Users />}></Route>
              <Route path={'/admin/users/payments/:id'} element={<BillingHistoryById />} ></Route>
            <Route path={'/admin/vouchers'} element={<VoucherRequests />}></Route>
            <Route path={'/admin/landingpage-options'} element={<LandingPageOptions />}></Route>
          </Route>
        </Routes>
  )
}

export default Router