import { Form, Input, Typography, Layout, ConfigProvider, Button, message } from 'antd'
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { Content } from 'antd/es/layout/layout';
import axios from 'axios';
import React from 'react'
import { useTranslation } from 'react-i18next';
import { useBreakpoint } from 'use-breakpoint';
import { errorMessage } from '../common/errors';
import NavBar from './LandingPage/sections/NavBar';

const BREAKPOINTS = { mobile: 0, tablet: 768, desktop: 1280 }

type Props = {}

const ContactPage = (props: Props) => {

    const { breakpoint } = useBreakpoint(BREAKPOINTS, 'desktop');
    const baseUrl = process.env.REACT_APP_BASE_URL
    const contactForm = useFormInstance()
    const { t } = useTranslation();

    const onFinish = (values: any) => {
        axios.post(`${baseUrl}email/contact`, values).then(res => {message.open({type: "success", content: t("successMessageSent")})}).catch(e => {console.log(e); errorMessage(t("errorMessageNotSent"))})
      };

  return (

    <ConfigProvider
    theme={{
    token: {
        colorPrimary: '#029389',
        colorBgBase: 'white',
        colorBgContainer: 'white',
        fontSize: 20,
        fontFamily: "Dosis",
        colorText: "#252422",
    },
    }}
    >
    <Layout className="layout" style={{background: "white", display: 'flex', flexWrap: "wrap", alignContent: "center" }}>
        <NavBar/>
        <Content style={{display: "flex", flexDirection: "column", margin: '4rem', background: "white", maxWidth: "100vw", alignContent: "center"}}>
            <div className="site-layout-content container" style={(breakpoint === "desktop" || breakpoint === "tablet" ) ? { maxWidth: "60vw", display: "flex", flexDirection: "column", background: '#f7f6f6de', backgroundImage: "url('https://monotributo.digital/img/map.png')" , borderRadius: '4px' } : { width: "90vw", display: "flex", flexDirection: "column", background: '#f7f6f6de', backgroundImage: "url('https://monotributo.digital/img/map.png')" , borderRadius: '4px' } }>
                <Typography.Title style={{display:'flex', justifyContent: 'center', paddingTop: '4rem', color: '#000', fontFamily: "Dosis"}} level={2}>{t('contactPage.title')}</Typography.Title>
                <Form 
                form={contactForm}
                name="contact"
                layout='vertical'
                onFinish={onFinish}
                autoComplete="off"
                size='large'
                style={{ padding: '1rem', display: 'block', width: "70%", fontSize: '1.5rem', alignItems: 'center', marginLeft: 'auto', marginRight: 'auto' }}
                >
                    <Form.Item name={"name"} label={t('contactPage.form.name')} rules={[{ required: true, message: (t("contactPage.form.nameError")|| "")}]}>
                        <Input></Input>
                    </Form.Item>
                    <Form.Item name={"email"} label={t('contactPage.form.email')} rules={[{ required: true, message: (t("contactPage.form.emailError")|| "")}]}>
                        <Input></Input>
                    </Form.Item>
                    <Form.Item name={"phone"} label={t('contactPage.form.phone')} rules={[{ required: true, message: (t("contactPage.form.phoneError")|| "")}]}>
                        <Input></Input>
                    </Form.Item>
                    <Form.Item name={"message"} label={t('contactPage.form.message')} rules={[{ required: true, message: (t("contactPage.form.messageError")|| "")}]}>
                        <Input.TextArea></Input.TextArea>
                    </Form.Item>
                    <Form.Item style={{padding: '1rem',paddingBottom: '2rem', display:'flex', justifyContent: 'center'}}>
                        <Button className='btnlogin' type="primary" htmlType="submit" style={{ height: 'auto', borderRadius: '0.25rem', marginTop: '25px', boxShadow: 'none'}}>{t('contactPage.form.submitButton')}</Button>
                    </Form.Item>
                </Form>
            </div>
        </Content>
    </Layout>
    </ConfigProvider>
  )
}

export default ContactPage