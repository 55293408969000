import { LoadingOutlined } from '@ant-design/icons'
import { Spin } from 'antd'
import React, { useEffect, useState } from 'react'
import { useLocation, useSearchParams } from 'react-router-dom'

type Props = {}

const MonotributoSubscriptionSuccess = (props: Props) => {

    const [searchParams]= useSearchParams()
    const location = useLocation();

    const [emailAddress, setEmailAddress] = useState("")

    const antIcon = (
        <LoadingOutlined style={{ color: "#03b9ac", fontSize: 50 }} spin />
      );

      const containsDev = (url) => {
        return url.includes('dev');
      };

      useEffect(() => {
        const emailAddress = searchParams.get("email")
        const planType = searchParams.get("type")

        setEmailAddress(emailAddress !== null ? emailAddress : "" )

        const baseUrl = process.env.REACT_APP_BASE_URL;

        const isDev = containsDev(baseUrl);

        const uri = `http://${isDev ? "dev." : "" }monotributo.digital/success-subscription-${planType}/${emailAddress}`
        window.location.href = uri;

      }, [])

      
  return (

    <div style={{height: "100vh", display: "flex", justifyContent: "center", alignItems: "center"}}>
        <Spin indicator={antIcon} />
    </div>


  )
}

export default MonotributoSubscriptionSuccess