import React, { useEffect, useState } from 'react'
import './BillingInformation.scss'
import {useTranslation} from "react-i18next";
import { Button, Col, Form, Input, Row, Steps, Typography, message } from 'antd';
import { DASHBOARD_SECTION_TITLES, RESUME_TITLES, RESUME_VALUES } from '../common/utils/styles';
import { useStepsForm } from 'sunflower-antd';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import axios from 'axios';
import { errorMessage } from '../../common/errors';
import { CheckOutlined, RightOutlined, LoadingOutlined } from '@ant-design/icons';
import { useBreakpoint } from 'use-breakpoint';
import { Popup } from 'antd-mobile';
import { useForm } from 'antd/es/form/Form';

const BREAKPOINTS = { mobile: 0, tablet: 768, desktop: 1280 }
type Props = {}

const BillingSettings: React.FC  = (props: Props) => {

    const { breakpoint, maxWidth, minWidth } = useBreakpoint(BREAKPOINTS);
    const { t } = useTranslation()
    const queryClient = useQueryClient()
    const baseUrl = process.env.REACT_APP_BASE_URL
    const token = JSON.parse(localStorage.getItem("token") || '{}')
    const config = {
        headers: {Authorization: `Bearer ${token}`}
    }

    const fetchData = async () => {
        const res: any = await axios.get(`${baseUrl}subscription-info/me`, config)
        return res.data
      }

    const { data, status, isPlaceholderData } = useQuery(`billing-information`, fetchData, {enabled: true, retry: 1, suspense: false, placeholderData: t("billing.billingSettings.noAddress"), refetchOnMount: false})

    const [formMobile] = useForm()

    const {
        form,
        current, 
        gotoStep,
        stepsProps,
        formProps,
        submit,
      } = useStepsForm({
        submit(values) {
          const {address, city, postalCode} = values
          onFinishForm(values)
        },
        total: 5,
      })

      const [userData, setUserData] = useState<any>(undefined)

      useEffect(() => {
        const userData: any = queryClient.getQueryData("userData")
        setUserData(userData)
      }, [userData, queryClient, current])

      const sendFormMobile = async(values: any) => {
        if (data === undefined) return await axios.post(`${baseUrl}subscription-info/create`, values, config).then(res => {queryClient.invalidateQueries({queryKey: ['billing-information']}); setPopupSettingsVisible(false); message.open({type: "success", content: t("billing.billingSettings.successMessage")}) }).catch(e =>{ console.log(e); errorMessage(t("billing.errorMessage"))})
        else return await axios.patch(`${baseUrl}subscription-info/update/${data.id}`, values, config).then(res => {queryClient.invalidateQueries({queryKey: ['billing-information']}); setPopupSettingsVisible(false) ; message.open({type: "success", content: t("billing.billingSettings.successMessage")}) }).catch(e => {console.log(e); errorMessage(t("billing.errorMessage"))})
      }


    
   

      const onFinishForm = async( values: any) => {
        if (data === undefined){
            await axios.post(`${baseUrl}subscription-info/create`, values, config).then((res) => {gotoStep(current + 1); queryClient.invalidateQueries({queryKey: ['billing-information']}); setTimeout(() => {gotoStep(0)}, 2000)}).catch((e) => {gotoStep(0); errorMessage(t("billing.errorMessage")); setPopupSettingsVisible(false)})
        } 
        else { 
            await axios.patch(`${baseUrl}subscription-info/update/${data.id}`, values, config).then((res) => {gotoStep(current + 1); queryClient.invalidateQueries({queryKey: ['billing-information']}); setTimeout(() => {gotoStep(0)}, 2000)}).catch((e) => {gotoStep(0); errorMessage(t("billing.errorMessage")); setPopupSettingsVisible(false)})
        }

      }

      const formList = [
        <>
            <Row style={{marginTop: "20px", marginBottom: "10px"}}>
                <Col span={24}>
                    <Typography style={DASHBOARD_SECTION_TITLES}>{t("billing.billingSettings.title")}</Typography>
                </Col>
            </Row>
            {(data === undefined) ? <Typography style={RESUME_TITLES}>{t("billing.billingSettings.noAddress")}</Typography> : <></>}
            <Row style={{marginTop: "0px"}}>
                <Col span={(breakpoint !== "desktop") ? 24 : 12}>
                    <Typography style={RESUME_TITLES}>{(data === undefined) ? "" : t("billing.billingSettings.address")}</Typography>
                </Col>
                <Col>
                </Col>                
            </Row>
            <Row style={{ marginBottom: "0px"}} >
                <Col span={(breakpoint !== "desktop") ? 24 : 12}>
                    <Typography style={RESUME_VALUES}>{(data === undefined) ? "" : data.address}</Typography>
                </Col>
                <Col>
                </Col>
            </Row>
            <Row style={{marginTop: "0px"}}>
                <Col span={(breakpoint !== "desktop") ? 24 : 12}>
                    <Typography style={RESUME_TITLES}>{(data === undefined) ? "" : t("billing.billingSettings.postalCode")}</Typography>
                    {( breakpoint !== "desktop" ) ? <Typography style={RESUME_VALUES}>{(data === undefined) ? "" : data.postal_code}</Typography> : <></>}
                </Col>
                <Col>
                    <Typography style={RESUME_TITLES}>{(data === undefined) ? "" : t("billing.billingSettings.city")}</Typography>
                    {( breakpoint !== "desktop" ) ? <Typography style={RESUME_VALUES}>{(data === undefined) ? "" : data.city}</Typography> : <></>}
                </Col>                
            </Row>
            <Row hidden={(breakpoint !== "desktop")} style={{ marginBottom: "0px"}} >
                <Col span={(breakpoint !== "desktop") ? 24 : 12}>
                    <Typography style={RESUME_VALUES}>{(data === undefined) ? "" : data.postal_code}</Typography>
                </Col>
                <Col>
                    <Typography style={RESUME_VALUES}>{(data === undefined) ? "" : data.city}</Typography>
                </Col>
            </Row>
            <Row style={{marginTop: "20px", marginBottom: "20px", position: (breakpoint !== "desktop") ? "static" :  "absolute", left: "30px", bottom: "20px"}}>
                <Button id='btn-secondary' onClick={() => gotoStep(current +  1 )} style={{}}>{(data === undefined) ? t("billing.billingSettings.addAddressButton") : t("billing.billingSettings.editAddressButton")}</Button>
            </Row>
        </>,
        <>
            <Row style={ {marginTop: "20px", marginBottom: "20px"}}>
                <Col span={24}>
                    <Typography style={DASHBOARD_SECTION_TITLES}>{t("billing.billingSettings.title")}</Typography>
                </Col>
            </Row>
            <Row style={{display: "flex", justifyContent: "space-evenly"}}>
                <Col span={24}>
                    <Form.Item name="city" label={t('billing.billingSettings.city')} style={{marginBottom: "0px"}}>
                        <Input placeholder={t('billing.billingSettings.cityPlaceholder') || ""}/>
                    </Form.Item>
                </Col>
            </Row>
            <Row style={(breakpoint !== "desktop") ? {display: "flex", flexDirection: "column", gap: "15px", marginTop: "15px"} : {}}>
                <Button style={(breakpoint !== "desktop") ?  {} : {position: "absolute", bottom: "40px", left: "30px"}} onClick={() => gotoStep(current - 1 )} id='btn-secondary'>{t('billing.billingSettings.buttonPrevStep')}</Button>
                <Button style={(breakpoint !== "desktop") ?  {} : {position: "absolute", bottom: "40px", right: "30px"}} onClick={() => gotoStep(current + 1 )} id='btn-secondary'>{t('billing.billingSettings.buttonNextStep')}</Button>
            </Row>
        </>,
        <>
            <Row style={{marginTop: "20px", marginBottom: "20px"}}>
                <Col span={24}>
                    <Typography style={DASHBOARD_SECTION_TITLES}>{t("billing.billingSettings.title")}</Typography>
                </Col>
            </Row>
            <Row style={{display: "flex", justifyContent: "space-evenly"}}>
                <Col span={24}>
                    <Form.Item name="address" label={t('billing.billingSettings.address')} style={{marginBottom: "0px"}}>
                        <Input placeholder={t('billing.billingSettings.addressChangerPlaceholder') || ""}/>
                    </Form.Item>
                </Col>
            </Row>
            <Row style={(breakpoint !== "desktop") ? {display: "flex", flexDirection: "column", gap: "15px", marginTop: "15px"} : {}}>
                <Button style={(breakpoint !== "desktop") ?  {} : {position: "absolute", bottom: "40px", left: "30px"}} onClick={() => gotoStep(current - 1 )} id='btn-secondary'>{t('billing.billingSettings.buttonPrevStep')}</Button>
                <Button style={(breakpoint !== "desktop") ?  {} : {position: "absolute", bottom: "40px", right: "30px"}} onClick={() => gotoStep(current + 1 )} id='btn-secondary'>{t('billing.billingSettings.buttonNextStep')}</Button>
            </Row>
        </>,
        <>
            <Row style={{marginTop: "20px", marginBottom: "20px"}}>
                <Col span={24}>
                    <Typography style={DASHBOARD_SECTION_TITLES}>{t("billing.billingSettings.title")}</Typography>
                </Col>
            </Row>
            <Row style={{display: "flex", justifyContent: "space-evenly"}}>
                <Col span={24}>
                    <Form.Item name="province" label={t('billing.billingSettings.province')} style={{marginBottom: "0px"}}>
                        <Input placeholder={t('billing.billingSettings.provinceCodePlaceholder') || ""}/>
                    </Form.Item>
                </Col>
            </Row>
            <Row style={(breakpoint !== "desktop") ? {display: "flex", flexDirection: "column", gap: "15px", marginTop: "15px"} : {}}>
                <Button style={(breakpoint !== "desktop") ?  {} : {position: "absolute", bottom: "40px", left: "30px"}} onClick={() => gotoStep(current - 1 )} id='btn-secondary'>{t('billing.billingSettings.buttonPrevStep')}</Button>
                <Button style={(breakpoint !== "desktop") ?  {} : {position: "absolute", bottom: "40px", right: "30px"}} onClick={() => gotoStep(current + 1 )} id='btn-secondary'>{t('billing.billingSettings.buttonNextStep')}</Button>
            </Row>
        </>,
        <>
            <Row style={{marginTop: "20px", marginBottom: "20px"}}>
                <Col span={24}>
                    <Typography style={DASHBOARD_SECTION_TITLES}>{t("billing.billingSettings.title")}</Typography>
                </Col>
            </Row>
            <Row style={{display: "flex", justifyContent: "space-evenly"}}>
                <Col span={24}>
                    <Form.Item name="postal_code" label={t('billing.billingSettings.postalCode')} style={{marginBottom: "0px"}}>
                        <Input placeholder={t('billing.billingSettings.postalCodePlaceholder') || ""}/>
                    </Form.Item>
                </Col>
            </Row>
            <Row style={(breakpoint !== "desktop") ? {display: "flex", flexDirection: "column", gap: "15px", marginTop: "15px"} : {}}>
                <Button style={(breakpoint !== "desktop") ?  {} : {position: "absolute", bottom: "40px", left: "30px"}} onClick={() => gotoStep(current - 1 )} id='btn-secondary'>{t('billing.billingSettings.buttonPrevStep')}</Button>
                <Button onClick={() => {submit()}} style={(breakpoint !== "desktop") ?  {} : {position: "absolute", bottom: "40px", right: "30px"}} id='btn-secondary'>{t('billing.billingSettings.buttonNextStep')}</Button>
            </Row>
        </>,
        <>
            <Row style={{display: "flex", justifyContent: "space-evenly"}}>
                <span style={{gap: "20px"}}>
                <CheckOutlined style={{color: "green", fontSize: "1.5rem", paddingLeft: "15px"}}/>
                {t('billing.billingSettings.successMessage')}
                </span>
            </Row>
        </>
      ]

      //Popup mobile
      const [popupSettingsVisible, setPopupSettingsVisible] = useState(false)

      const loadingIcon = <LoadingOutlined style={{ fontSize: 50, color: "#03b9ac" }} spin />;

    return (
        <>
        {(breakpoint === "mobile" || breakpoint === "tablet")
        ?
        (data === undefined && status === "loading") ?
        <div style={{display: 'flex', flexDirection: "row", alignContent: 'center', alignItems: "center"}}>{loadingIcon}</div>
        :
        <div style={{display: "flex"}}>
            <Row onClick={() => setPopupSettingsVisible(true)} style={{height: "135px",  justifyContent: "center",paddingLeft: "25px", width: "98vw", backgroundColor: "#F2F0F0", borderRadius: "4px", display: "flex", flexDirection: "row", alignItems: "center"}}>
                <Col span={22}>
                    {(data === undefined && status === "error") 
                    ?
                    <Typography style={{fontWeight: "800", fontFamily: "Poppins"}}>{t("billing.billingSettings.noAddress")}</Typography>
                    :
                    <>
                        <Row>
                            <Typography style={{fontWeight: "800", fontFamily: "Poppins"}}>{t("billing.billingSettings.address")}</Typography>
                        </Row>
                        <Row>
                            <Typography style={{fontWeight: "500", fontFamily: "Poppins"}}>{t("billing.billingSettings.address")}</Typography><Typography style={{fontWeight: "300", fontFamily: "Poppins", paddingLeft: "5px"}}>{ (data === undefined) ? "" : data.address}</Typography>
                        </Row>
                        <Row>
                            <Typography style={{fontWeight: "500", fontFamily: "Poppins"}}>{t("billing.billingSettings.postalCode")}</Typography><Typography style={{fontWeight: "300", fontFamily: "Poppins", paddingLeft: "5px"}}>{(data === undefined) ? "" : data.postal_code}</Typography>
                        </Row>
                        <Row>
                            <Typography style={{fontWeight: "500", fontFamily: "Poppins"}}>{t("billing.billingSettings.city")}</Typography><Typography style={{fontWeight: "300", fontFamily: "Poppins", paddingLeft: "5px"}}>{ (data === undefined) ? "" : data.city}</Typography>
                        </Row>
                        <Row>
                            <Typography style={{fontWeight: "500", fontFamily: "Poppins"}}>{t("billing.billingSettings.province")}</Typography><Typography style={{fontWeight: "300", fontFamily: "Poppins", paddingLeft: "5px"}}>{(data === undefined) ? "" : data.province}</Typography>
                        </Row>
                    </>}
                </Col>
                <Col span={2}>
                    <RightOutlined />
                </Col>
            </Row>
            <Popup showCloseButton visible={popupSettingsVisible} onMaskClick={() => setPopupSettingsVisible(false)} onClose={() => setPopupSettingsVisible(false)} bodyStyle={{ height: 'auto' }} mask={true} closeOnSwipe>
                <div style={{gap: "20px", display: "flex", flexDirection: "column", paddingLeft: "25px", paddingRight: "25px", paddingTop: "45px", paddingBottom: "25px"}}>
                    <Typography style={{fontWeight: "800"}}>{t("billing.billingSettings.editAddressButton")}</Typography>
                    <Form layout='vertical' size='small' form={formMobile} onFinish={(values) => sendFormMobile(values)}>
                        <Form.Item style={{maxHeight: "auto"}} label={t('billing.billingSettings.address')} name={"address"} rules={[{required: true}, {message: `t('billing.billingSettings.address'`}]}>
                            <Input style={{height: "38px"}} placeholder={t('billing.billingSettings.addressChangerPlaceholder') || ""}></Input>
                        </Form.Item>
                        <Form.Item label={t('billing.billingSettings.city')} name={"city"} rules={[{required: true}, {message: `t('billing.billingSettings.city'`}]}>
                            <Input style={{height: "38px"}} placeholder={t('billing.billingSettings.cityPlaceholder') || ""}></Input>
                        </Form.Item>
                        <Form.Item name={"postal_code"} label={t('billing.billingSettings.postalCode')} rules={[{required: true}, {message: `t('billing.billingSettings.postalCode'`}]}>
                            <Input style={{height: "38px"}} placeholder={t('billing.billingSettings.postalCodePlaceholder') || ""}></Input>
                        </Form.Item>
                        <Form.Item label={t('billing.billingSettings.province')} name={"province"} rules={[{required: true}, {message: `t('billing.billingSettings.province'`}]}>
                            <Input style={{height: "38px"}} placeholder={t('billing.billingSettings.provinceCodePlaceholder') || ""}></Input>
                        </Form.Item>
                        <Form.Item>
                            <Row style={{display: "flex", flexDirection: "row", gap: "10px", width: "100%", justifyContent: "space-between"}}>
                                <Col span={11} style={{display: "flex"}}>
                                </Col>
                                <Col span={11} style={{display: "flex"}}>
                                    <Button htmlType='submit' id='btn-secondary' style={{height: "38px", display: "flex", width: "100%", alignItems: "center", justifyContent: "center"}} >{(data === undefined) ? t("billing.billingSettings.addAddressButton") : t("billing.billingSettings.editAddressButton")}</Button>
                                </Col>
                            </Row>
                        </Form.Item>
                    </Form>
                </div>
            </Popup>
        </div>
        :
        <div className='sectionDiv' style={{height:"315px"}}>
            <Steps>
            </Steps>
            <div>
                <Form name='billingAddress' form={form} {...formProps}>
                    {formList[current]}
                </Form>
            </div>
        </div>
    
    
    }
        </>
    )
}

export default BillingSettings