import { Col, Row, Typography } from 'antd'
import { t } from 'i18next'
import React from 'react'
import { DASHBOARD_TITLES } from '../../../../components/common/utils/styles'
import FaqsAdmin from './components/FaqsAdmin'

const LandingPageOptions = () => {
  return (
    <>
      <Row style={{marginTop: "20px", marginBottom: "20px"}}>
        <Col span={24}>
          <Typography style={DASHBOARD_TITLES}>{t("adminDashboardPage.landingPageOptions.title")}</Typography>
        </Col>
      </Row>
      <FaqsAdmin />
    </>
    )
}

export default LandingPageOptions