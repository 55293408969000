import React, {useEffect, useState} from 'react';
import { ConfigProvider, Layout, Button, Form, Input, Modal, message, Typography } from 'antd';
import axios from 'axios';
import useLogin from './query/useLogin';
import { FormLogin } from './LoginPage.types';
import './LoginPage.scss'
import {useTranslation} from "react-i18next";
import useBreakpoint from 'use-breakpoint';
import { useLocation, useNavigate } from 'react-router-dom';
import NavBar from '../LandingPage/sections/NavBar';
import { useQueryClient } from 'react-query';

const BREAKPOINTS = { mobile: 0, tablet: 768, desktop: 1280 }

const { Content } = Layout;

const LoginPage: React.FC = () => {

    const [form] = Form.useForm();

    const { breakpoint } = useBreakpoint(BREAKPOINTS, 'desktop');

    const [, setLoginData] = useState<FormLogin>({username:"",password:""})

    const { mutate: userLogin, isLoading } = useLogin();

    const { t } = useTranslation();

    const navigate = useNavigate()

    const baseUrl = process.env.REACT_APP_BASE_URL
    
    //The function triggered when values changes
    const handleLoginData = (changedValues: any, allValues: any) => {
        setLoginData(allValues);
    }

    //The function triggered when click Enviar button
    const onFinish = (formLogin: FormLogin) => {
        userLogin(formLogin)
    };

    //Show an error message on the top of the window when there is an error 
    const errorMessage = () => {
        message.open({
        type: 'error',
        content: `${t('loginPage.loginErrorMessage')}`,
        });
    };

    //Is triggered when the user click on Enviar button inside the modal window recovery password
    const onFinishRecoveryPassword = (value: string) => {
        axios.patch(`${baseUrl}auth/request-reset-password/`, value)
        setOpen(false);
    }
    
    //Modal to send the email to the server and reset Password
    const [open, setOpen] = useState(false);

    const showModal = () => {
        setOpen(true);
    };

    const handleOk = () => {
        setOpen(false);
    };

    const handleCancel = () => {
        setOpen(false);
    };

    const location = useLocation()
    const queryClient = useQueryClient()

    useEffect(() => {
      if (queryClient.getQueryData("token")) navigate("/") 
    }, [])
    

    return (
    <ConfigProvider
        theme={{
        token: {
            colorPrimary: '#029389',
            colorBgBase: 'white',
            colorBgContainer: 'white',
            fontSize: 20,
            fontFamily: "Dosis",
            colorText: "#252422",
        },
        }}
    >
        
    <Layout className="layout" style={{background: "white", display: 'flex', flexWrap: "wrap", alignContent: "center" }}>
        <NavBar></NavBar>
        <Content style={{display: "flex", flexDirection: "column", margin: '4rem', background: "white", maxWidth: "100vw", alignContent: "center"}}>
            <div className="site-layout-content container" style={(breakpoint === "desktop" || breakpoint === "tablet" ) ? { maxWidth: "60vw", display: "flex", flexDirection: "column", background: '#f7f6f6de', backgroundImage: "url('https://monotributo.digital/img/map.png')" , borderRadius: '4px' } : { width: "90vw", display: "flex", flexDirection: "column", background: '#f7f6f6de', backgroundImage: "url('https://monotributo.digital/img/map.png')" , borderRadius: '4px' } }>
                <Typography.Title style={{display:'flex', justifyContent: 'center', paddingTop: '4rem', color: '#000', fontFamily: "Dosis", fontSize: (breakpoint === "mobile") ? "2rem" : "3rem"}} level={2}>{t('loginPage.loginTitle')}</Typography.Title>
                <Form
                    form={form}
                    name="login"
                    layout='vertical'
                    onFinish={onFinish}
                    onFinishFailed={errorMessage}
                    onValuesChange={handleLoginData}
                    autoComplete="off"
                    size='large'
                    style={{ padding: '1rem', display: 'block', width: (breakpoint === "mobile") ? "100%" : "70%", fontSize: '1.5rem', alignItems: 'center', marginLeft: 'auto', marginRight: 'auto' }}
                >
                    <Form.Item
                        labelAlign='left'
                        label={t('loginPage.loginForm.emailLabel')}
                        name="email"
                        rules={[{ required: true, message: `${t('loginPage.loginForm.emailError')}`}]}                        
                    >
                        <Input data-testid="Email" placeholder= {`${t('loginPage.loginForm.emailPlaceholder')}`} />
                    </Form.Item>
                    <Form.Item
                        style={{ borderRadius: '4px' }}                       
                        labelAlign='left'
                        label={t('loginPage.loginForm.passwordLabel')}
                        name="password"
                        rules={[{ required: true, message: `${t('loginPage.loginForm.passwordError')}` }]}
                    >
                        <Input.Password data-testid="Password" placeholder= {`${t('loginPage.loginForm.passwordPlaceholder')}`} />
                    </Form.Item>
                    <Form.Item shouldUpdate style={{padding: '1rem',paddingBottom: '2rem', display:'flex', justifyContent: 'center'}}>
                        {({ getFieldsValue, getFieldsError }) => {
                            const { email, password } = getFieldsValue();
                            const error = getFieldsError();
                            const formIsComplete = !!email && !!password && (error[0].errors.length === 0) && (error[1].errors.length === 0);
                            return (
                                <Button disabled={!formIsComplete} data-testid='btnlogin' className='btnlogin' type="primary" loading={isLoading}  htmlType="submit" style={{ height: 'auto', borderRadius: '0.25rem', marginTop: '25px', boxShadow: 'none'}}>
                                    {t('loginPage.loginForm.submitButton')}
                                </Button>
                            );
                        }}
                    </Form.Item>
                    <Form.Item style={{padding: '1rem',paddingBottom: '2rem', display:'flex', justifyContent: 'center'}}>
                        <Button type='text' onClick={showModal} style={{ fontSize: '20px' }} >
                            {t('loginPage.loginForm.resetPasswordButton')}
                        </Button>
                        <Modal
                        title="Reset Password"
                        open={open}
                        onOk={handleOk}
                        onCancel={handleCancel}
                        footer={null}
                        >
                            <Form
                            layout='horizontal'
                            name='resetPassword'
                            onFinish={onFinishRecoveryPassword}
                            style={{display: 'flex', flexDirection: "column"  }}
                            size='large'
                            >
                                <Form.Item
                                    style={{padding: '1rem', paddingTop: '2rem', display:'flex', justifyContent: 'center' }}
                                    name="email"
                                    labelAlign='left'
                                    rules={[{ type: 'email', required: true, message: 'Please input your email!'}]}
                                >
                                <Input placeholder= {`${t('loginPage.loginForm.resetPasswordFieldLabel')}`} />
                                </Form.Item>
                                <Form.Item  style={{ display:'flex', justifyContent: 'center'}}>
                                    <Button className='btnlogin' type="primary" htmlType="submit" style={{ height: 'auto', borderRadius: '0.25rem', marginTop: '25px', boxShadow: 'none', fontFamily: "Dosis"}}  >
                                        <span>{t('loginPage.loginForm.resetPasswordSubmitButton')}</span>
                                    </Button>
                                </Form.Item>
                            </Form>
                        </Modal>
                    </Form.Item>
                </Form>
            </div>
            <Button onClick={() => {window.location.href = `${process.env.REACT_APP_DIGITAL_URL}/pricing`;}} style={{ fontSize: '20px', marginTop: "20px", fontWeight: "bold", color: "#029389" }} type='link' >
                {t('loginPage.dontHaveAnAccount')}
            </Button>
        </Content>
    </Layout>
    </ConfigProvider>
  );
};

export default LoginPage;