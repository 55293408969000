import React, { useEffect, useState } from 'react'
import { Button, Form, Modal, Result, Steps } from 'antd';
import { useTranslation } from "react-i18next";
import { useStepsForm } from 'sunflower-antd';
import {  CheckOutlined } from '@ant-design/icons';
import "./VoucherRequestForm.scss"
import "dayjs/locale/es.js"
import { useMutation, useQueryClient } from 'react-query';
import axios from 'axios';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import dayjs from 'dayjs';
import { useBreakpoint } from 'use-breakpoint';

const BREAKPOINTS = { mobile: 0, tablet: 768, desktop: 1280 }
const { Step } = Steps;

const VoucherRequestForm: React.FC<{voucherId?: number, userCanUpdate?: boolean, closeDrawer: any, resetOnClose?: any, userId?: number|null}> = (props) => {

  const { t } = useTranslation()
  const { breakpoint, maxWidth, minWidth } = useBreakpoint(BREAKPOINTS);
  const queryClient = useQueryClient()

  //Modal to handle server errors
  const error = () => {
    Modal.error({
      title: 'Error',
      content: t("vouchers.form.error"),
      okType: "danger",
      okButtonProps: {size: "small", ghost: true}
    });
  };

  const baseUrl = process.env.REACT_APP_BASE_URL

  const token = JSON.parse(localStorage.getItem("token") || '{}')

  const config = {
    headers: {Authorization: `Bearer ${token}`}
  }

  const {
    form,
    current, 
    gotoStep,
    stepsProps,
    formProps,
    submit,
  } = useStepsForm({
    submit(values) {
      const { status, point_of_sale, type, date_not_formatted, concept, vat_condition, doc_type, doc_number, business_name, recidence, sale_conditions, associated_voucher } = values;
      let voucher_request_lines = form.getFieldValue("voucher_request_lines")
      voucher_request_lines.forEach((line: any) => {
        line.amount = ((line.quantity)*(line.unit_price));
        line.unit_price = Number(line.unit_price)
      })
      let date = dayjs(form.getFieldValue("date_not_formatted")).unix()

      let associated_vouchers_data = form.getFieldValue("associated_voucher")
      let associated_vouchers = (associated_vouchers_data === undefined || associated_vouchers_data.length === 0) ? null : associated_vouchers_data.map((line: any) => { return {"type": line.type, "point_of_sale": Number(line.point_of_sale), "number": line.number, "date": line.date = dayjs(line.date).unix()}} )
      
      let point_of_sale_id = () => {
        if (point_of_sale === "demo" || point_of_sale === "" || point_of_sale === undefined) return null
        else {
          let pointsOfSale: any = queryClient.getQueryData("pointOfSale")
          let result = pointsOfSale.filter((item: any) => item.name === point_of_sale)
          console.log(pointsOfSale)
          return result[0].afip_id === undefined ? null : result[0].afip_id
          
        }
      }
      
      onFinishForm((associated_vouchers === null) ? {status, point_of_sale, point_of_sale_id: point_of_sale_id(), type, date, concept, vat_condition, doc_type, doc_number, business_name, recidence, sale_conditions, voucher_request_lines} : {status, point_of_sale, point_of_sale_id: point_of_sale_id(), type, date, concept, vat_condition, doc_type, doc_number, business_name, recidence, sale_conditions, associated_vouchers, voucher_request_lines})
    },
    total: 5,
    isBackValidate: false,
  });

  const useSendVoucherForm = () => {
    return useMutation(async (values: any) => {
      if (queryClient.getQueryData("role") === "admin") return axios.patch(`${baseUrl}voucher-request/update/${props.voucherId}`, values, config)
      else if (props.userCanUpdate) {
        delete values.status
        return axios.patch(`${baseUrl}voucher-request/update/${props.voucherId}`, values, config)
      }
      else
      delete values.status
      return await axios.post(`${baseUrl}voucher-request/create`, values, config)},
    {
      onSuccess: (res) => {
        gotoStep(current + 1)
        queryClient.invalidateQueries({queryKey: ["vouchers"]})
        queryClient.invalidateQueries({queryKey: ["get-all-vouchers"]})
        setTimeout(() => {props.closeDrawer(false); gotoStep(0)}, 2000)
      },
      onError: (e) => {error(); props.closeDrawer(false); gotoStep(0)}
    }
  )}

  const {mutate: onFinishForm} = useSendVoucherForm()

  //InitialValues: para renderizar los valores cuando se quiere modificar una factura

  const [date, setDate] = useState<string>()
  const [saleConditionValue, setSaleConditionValue] = useState<string>()
  const [cuitUser, setCuitUser] = useState("")
  const [userName, setUserName] = useState("")
  const [template, setTemplate] = useState<any>(null)
  const [logo, setLogo] = useState<any>(null)
  const [userId, setUserId] = useState<number | null>(null)

  useEffect(() => {
    if (queryClient.getQueryData("role") === "admin" || props.userCanUpdate ){axios.get(`${baseUrl}voucher-request/${(props.userCanUpdate) ? "by-user/" : ""}${props.voucherId}`, config).then(res => {form.resetFields(); gotoStep(0); form.setFieldsValue({"status": res.data.status, "point_of_sale": res.data.point_of_sale, "type": res.data.type, "vat_condition": res.data.vat_condition, "sale_conditions": res.data.sale_conditions, "concept": res.data.concept, "doc_type": res.data.doc_type, "doc_number": res.data.doc_number,"business_name": res.data.business_name, "recidence": res.data.recidence, "voucher_request_lines": res.data.voucher_request_lines, "associated_vouchers": res.data.associated_vouchers }); setDate(res.data.date); setSaleConditionValue(res.data.sale_conditions); setCuitUser(res.data.user.cuit); setUserName(res.data.user.name); setLogo(res.data.user.logo); setTemplate(res.data.user.template); setUserId(res.data.user.id) })}
    else if (queryClient.getQueryData("isFirstLogin") === true && queryClient.getQueryData("skipTour") !== true) {form.setFieldsValue({"status": "demo", "point_of_sale": "demo", "type": "Factura C", "vat_condition": "IVA Responsable Inscripto", "sale_conditions": "Contado", "concept": "Productos", "doc_type": "CUIT", "doc_number": "00000000","business_name": "demo", "voucher_request_lines": [{"description": "Demo", "quantity": 1, "measure": "Otras Unidades", "unit_price": 1000}], "associated_vouchers": [] })}
    else {form.resetFields(); setDate(undefined); queryClient.invalidateQueries({queryKey: ["pointOfSale"]}); queryClient.removeQueries(["razon-social"], gotoStep(0))}
  }, [props.voucherId, props.userCanUpdate, date, props.resetOnClose])

  const top: any = document.querySelector(".ant-drawer-body")

  const showLogo = async (id) => {
    await axios.get(`${baseUrl}user/get-logo-admin/${id}`, {
        method: "GET",
        headers: {Authorization: `Bearer ${token}`}
      }).then(res =>{
        const url = res
        window.open(url.data.logo, "_parent")
      }).catch(err => {
        console.log(err);
      });
  };

  //Formulario en un array correspondientes a los varios pasos
  const formList = [
    <>  
      <Step1 userId={props.userId} date={date} />
      <Form.Item >
            <Button id='btn-secondary' onClick={() => {gotoStep(current + 1); top.scrollTo({top: 0, behavior: 'smooth'})}}>
              <div id='step6click'>{t("nextStep")}</div>
            </Button>
      </Form.Item>
      {(queryClient.getQueryData("role") === "admin") 
      ? 
      <div style={{fontWeight: "500", display: "flex", gap: "8px", alignItems: "center"}}>{t("vouchers.form.userNameLabel")}
        <span style={{fontWeight: "300"}}>{userName}</span>{" " + t("vouchers.form.userCuitLabel")}<span style={{fontWeight: "300"}}>{cuitUser}</span>
        <span style={{fontWeight: "500"}}>{" " + t("vouchers.form.templateSelected") + " "}</span><span style={{fontWeight: "300"}}>{(template === null) ? "Plantilla por defecto" : template }</span>
        <Button style={{color: "#03b9ac"}} disabled={(logo === null)} onClick={() => showLogo(userId)} type='link' >{(logo === null) ? t("vouchers.form.logoNotFound") : t("vouchers.form.getLogoButton")}</Button>
      </div>
      : 
      <></>}
    </>
   ,
    <>
      <Step2 userId={props.userId} saleConditionValue={saleConditionValue} />
      <Form.Item>
        <Button id='btn-secondary' onClick={() => {gotoStep(current - 1); top.scrollTo({top: 0, behavior: 'smooth'})}}>
          <div id='prev-step'>{t("prevStep")}</div>
        </Button>
        <Button id='btn-secondary' onClick={() => {gotoStep(current + 1); top.scrollTo({top: 0, behavior: 'smooth'})}}>
          <div id='step12click'>{t("nextStep")}</div>
        </Button>
      </Form.Item>
      {(queryClient.getQueryData("role") === "admin") 
      ? 
      <div style={{fontWeight: "500", display: "flex", gap: "8px", alignItems: "center"}}>{t("vouchers.form.userNameLabel")}
        <span style={{fontWeight: "300"}}>{userName}</span>{" " + t("vouchers.form.userCuitLabel")}<span style={{fontWeight: "300"}}>{cuitUser}</span>
        <span style={{fontWeight: "500"}}>{" " + t("vouchers.form.templateSelected") + " "}</span><span style={{fontWeight: "300"}}>{(template === null) ? "Plantilla por defecto" : template }</span>
        <Button style={{color: "#03b9ac"}} disabled={(logo === null)} onClick={() => showLogo(userId)} type='link' >{(logo === null) ? t("vouchers.form.logoNotFound") : t("vouchers.form.getLogoButton")}</Button>
      </div>
      : 
      <></>}
    </>,
    <>
      <Step3 />
      <Form.Item>
        <Button id='btn-secondary' onClick={() => {gotoStep(current - 1); top.scrollTo({top: 0, behavior: 'smooth'})}}>
          <div id='prev-step'>{t("prevStep")}</div>
        </Button>
        <Button id='btn-secondary' onClick={() => {gotoStep(current + 1); top.scrollTo({top: 0, behavior: 'smooth'})}}>
          <div id='step18click'>{t("nextStep")}</div>
        </Button>
      </Form.Item>
      {(queryClient.getQueryData("role") === "admin") 
      ? 
      <div style={{fontWeight: "500", display: "flex", gap: "8px", alignItems: "center"}}>{t("vouchers.form.userNameLabel")}
        <span style={{fontWeight: "300"}}>{userName}</span>{" " + t("vouchers.form.userCuitLabel")}<span style={{fontWeight: "300"}}>{cuitUser}</span>
        <span style={{fontWeight: "500"}}>{" " + t("vouchers.form.templateSelected") + " "}</span><span style={{fontWeight: "300"}}>{(template === null) ? "Plantilla por defecto" : template }</span>
        <Button style={{color: "#03b9ac"}} disabled={(logo === null)} onClick={() => showLogo(userId)} type='link' >{(logo === null) ? t("vouchers.form.logoNotFound") : t("vouchers.form.getLogoButton")}</Button>
      </div>
      : 
      <></>}
    </>,
    <>
      <Step4 />
      <div style={{display: "flex", justifyContent: "space-between", paddingBottom: "20px"}}>
        <Button id='btn-secondary' onClick={() => {gotoStep(current - 1); top.scrollTo({top: 0, behavior: 'smooth'})}}>
          <div id='prev-step'>{t("prevStep")}</div>
        </Button>
        <Button id='btn-secondary' onClick={() => {
          submit()
        }}
        >
          <div id='step20click'>{t("send")}</div>
        </Button>
      </div>
      {(queryClient.getQueryData("role") === "admin") 
      ? 
      <div style={{fontWeight: "500", display: "flex", gap: "8px", alignItems: "center"}}>{t("vouchers.form.userNameLabel")}
        <span style={{fontWeight: "300"}}>{userName}</span>{" " + t("vouchers.form.userCuitLabel")}<span style={{fontWeight: "300"}}>{cuitUser}</span>
        <span style={{fontWeight: "500"}}>{" " + t("vouchers.form.templateSelected") + " "}</span><span style={{fontWeight: "300"}}>{(template === null) ? "Plantilla por defecto" : template }</span>
        <Button style={{color: "#03b9ac"}} disabled={(logo === null)} onClick={() => showLogo(userId)} type='link' >{(logo === null) ? t("vouchers.form.logoNotFound") : t("vouchers.form.getLogoButton")}</Button>
      </div>
      : 
      <></>}
    </>,
    <>
        <Result status={"success"} title={t("vouchers.form.successTitle")} icon={<CheckOutlined />}></Result>
    </>
  ]
  
  return (
    <>
      <Steps style={{fontSize: "0px"}} {...stepsProps} labelPlacement={"vertical"} size={"small"}>
        <Step title={`${t("vouchers.form.secondStepTitle")}`}></Step>
        <Step title={`${t("vouchers.form.thirdStepTitle")}`}></Step>
        <Step title={`${t("vouchers.form.fourthStepTitle")}`}></Step>
        <Step disabled title={`${t("vouchers.form.fifthStepTitle")}`}></Step>
      </Steps>
      <div>
        <Form style={{paddingTop: "10px"}} layout='vertical' {...formProps} name={"voucherRequest"} form={form}>
          {formList[current]}
        </Form>
      </div>
    </>
  )
}

export default VoucherRequestForm;