import axios from "axios";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { errorMessage } from "../../../common/errors";
import { FormSignin } from "../SigninPage.types";

const useSignin = (redirectToMp?: boolean) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const baseUrl = process.env.REACT_APP_BASE_URL;

  return useMutation(
    async (formSignin: FormSignin) => {
      return await axios.post(`${baseUrl}auth/register`, formSignin);
    },
    {
      onSuccess: (res) => {
        const response = JSON.stringify(res.data.token);
        localStorage.setItem("token", response);
        queryClient.setQueryData("token", response);
        let token = JSON.parse(response || "{}");
        axios
          .get(`${baseUrl}user/me`, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((res) => {
            if (res.data.role?.type) {
              queryClient.setQueryData("role", res.data.role.type);
              queryClient.setQueryData("userData", res.data);
              localStorage.setItem("role", res.data.role.type);
              res.data.role.type === "admin"
                ? navigate("/admin/users")
                : !redirectToMp
                ? navigate("/dashboard/")
                : navigate('/loading');
            } else {
              queryClient.setQueryData("role", "user");
              queryClient.setQueryData("userData", res.data);
              localStorage.setItem("role", "user");
              !redirectToMp
                ? navigate("/dashboard/")
                : navigate('/loading');
            }
          });
      },
      onError: (err:any) => {
        console.log("Error:", err);
        errorMessage(err.response?.data?.message ?? "Error");
      },
    }
  );
};

export default useSignin;
