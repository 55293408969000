import { LoadingOutlined } from '@ant-design/icons';
import { Row, Spin } from 'antd'
import Column from 'antd/es/table/Column';
import axios from 'axios';
import React, { useEffect } from 'react'
import { useQueryClient } from 'react-query';
import { useSearchParams } from 'react-router-dom';

type Props = {}

const MonotributoSubscription = (props: Props) => {

    const queryClient = useQueryClient();
    const user: any = queryClient.getQueryData("userData");
    const userLogged = !!user;
    const userWithMP = !!user?.mpEmail;
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const redirectUrlOAuth = process.env.REACT_APP_REDIRECT_OAUTH_URL;
    const clientId = process.env.REACT_APP_CLIENT_ID;
    const [searchParams]= useSearchParams()

    const antIcon = (
        <LoadingOutlined style={{ color: "#03b9ac", fontSize: 50 }} spin />
      );

      const onVinculate = () => {
        //En caso de que el usuario no tenga un mpEmail se tiene que ejecutar esta funcion
        //const token = localStorage.getItem("token");
        /* if (token) { */
        const uri = `https://auth.mercadopago.com/authorization?client_id=${clientId}&response_type=code&platform_id=mp&state=${new Date().toISOString()+Math.random()}&redirect_uri=${redirectUrlOAuth}`;
        queryClient.invalidateQueries({ queryKey: ["userData"] });
        window.location.href = uri;
        /* } else {
          navigate("/signin?redirect=true");
        } */
      };

      const onCreateSub = (planId: number, code?: string) => {
        //Se utilica el code que se recibe de los parametros para vincular la cuenta y crear la suscripcion
        const token = localStorage.getItem("token");
        const jsonToken = token ? JSON.parse(token) : "";
        const codeString = `?code=${code}`;
        localStorage.removeItem("planId");
        axios
          .post(
            `${baseUrl}subscription/create/${planId}${
              userWithMP ? "" : codeString
            }`,
            null,
            {
              headers: {
                Authorization: `Bearer ${jsonToken}`,
              },
            }
          )
          .then((data) => {
            const { init_point } = data.data;
            queryClient.invalidateQueries({ queryKey: ["userData"] });
            window.location.href = init_point;
          })
          .catch((error) => {
            console.log(error);
          });
      };

    useEffect(() => {
      setTimeout(() => {

        const planId = searchParams.get("planId")

        localStorage.setItem("planId", planId == null ? "" : planId);
          if (userLogged && userWithMP) {
            onCreateSub(planId == null ? 0 : Number(planId));
          } /*  if (userLogged && !userWithMP ) */ else {
            onVinculate();
          } /* else {
                          navigate("/signin?redirect=true");
                      } */
          /* if (userLogged) {
            onCreatePreference(
              premiumPlanData.id
            );
          } else {
            navigate("/signin?redirect=true");
          } */
        
      }, 5000);

    }, [])
    

  return (
    <div style={{height: "100vh", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
      <div>
        <p style={{fontWeight: "bold", color: "#03b9ac"}}>
          {"Estamos redirigiendolo a Mercado Pago. "}
        </p>
      </div>
      <div>
        <p>{"Estamos redirigiéndote a Mercado Pago para seleccionar tu pais. Luego, daremos inicio a su trámite o plan elegido."}</p>
      </div>
      <div>
        <Spin indicator={antIcon} />
      </div>
    </div>
  )
}

export default MonotributoSubscription