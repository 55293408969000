import { Spin } from 'antd'
import React from 'react'
import { LoadingOutlined } from '@ant-design/icons';

function LoadingPage() {

    const antIcon = <LoadingOutlined style={{color: "#03b9ac", fontSize: 50 }} spin />;

  return (
    <div style={{
      display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        alignItems: "center",
        color: "#03b9ac",
        width: "100vw",
        height: "100vh",
      }}>
        <Spin indicator={antIcon} />
    </div>
  )
}

export default LoadingPage