import React from 'react';
import { Nav } from 'react-bootstrap';

export const NavButton = ({children}: { children: React.JSX.Element | React.JSX.Element []}) => {
  return (
    <Nav
      className="me d-flex align-items-center pt-4 pb-4 ms-auto"
      style={{ gap: "20px", paddingLeft: "10px" }}
    >
      {children}
    </Nav>
  );
};