import React, { useEffect } from "react";
import NavBar from "./sections/NavBar";
import Header from "./sections/Header";
import HowItWorks from "./sections/HowItWorks";
import Prices from "./sections/Prices";
import Features from "./sections/Features";
import Testimonial from "./sections/Testimonial";
import SubscribeBanner from "./sections/SubscribeBanner";
import Faq from "./sections/Faq";
import Footer from "./sections/Footer";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useQueryClient } from "react-query";
import LoadingPage from "../LoadingPage";

const LandingPage: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const user: any = queryClient.getQueryData("userData");

  useEffect(() => {
    if (
      process.env.REACT_APP_REDIRECT_MP &&
      window.location.href !== `${process.env.REACT_APP_REDIRECT_MP}/` &&
      location.hash === ""
    ) {
      window.location.href = `${process.env.REACT_APP_REDIRECT_MP}/dashboard`;
    }
    if (searchParams.get("old_id") === null) {
    } else document.cookie = `old_id=${searchParams.get("old_id")}`;
    !!user ? navigate("/dashboard/vouchers") : navigate("/login");
  }, []);

  return (
    <>
      <LoadingPage />
      {/* <NavBar />
      <Header />
      <HowItWorks />
      <Prices />
      <Features />
      <Testimonial />
      <SubscribeBanner />
      <Faq />
      <Footer /> */}
    </>
  );
};

export default LandingPage;
