import { Button, Col, Form, Input, Result, Row, Steps, Typography, message } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useStepsForm } from 'sunflower-antd';
import {  CheckOutlined, LoadingOutlined, RightOutlined } from '@ant-design/icons';
import { useMutation, useQueryClient } from 'react-query';
import { errorMessage } from '../../common/errors';
import { DASHBOARD_SECTION_TITLES, RESUME_TITLES, RESUME_VALUES } from '../common/utils/styles';
import { useBreakpoint } from 'use-breakpoint';
import { Popup } from 'antd-mobile';


const BREAKPOINTS = { mobile: 0, tablet: 768, desktop: 1280 }
const { Step } = Steps;

const Phone: React.FC = () => {

  const queryClient = useQueryClient()
  const { breakpoint, maxWidth, minWidth } = useBreakpoint(BREAKPOINTS);
  const { t } = useTranslation()
  const baseUrl = process.env.REACT_APP_BASE_URL
  const token = JSON.parse(localStorage.getItem("token") || '{}')
  const config = {
    headers: {Authorization: `Bearer ${token}`}
  }

  const {
    form,
    current, 
    gotoStep,
    stepsProps,
    formProps,
    submit,
  } = useStepsForm({
    submit(values) {
      const {phone} = values
      onFinishForm(values)
    },
    total: 3,
  })

  const [userData, setUserData] = useState<any>(undefined)

  useEffect(() => {
    const userData: any = queryClient.getQueryData("userData")
    setUserData(userData)
  }, [userData, queryClient, current])
  
  const havePhone = () => {
    if (userData === undefined || userData.phone === undefined || userData.phone === "" || userData.phone === null) return false
    else return true
  }

  const useSendForm = () => {
    return useMutation(async (values: any) => {
        return await axios.put(`${baseUrl}user/update`, values, config)
    },
    {
      onSuccess: (res) => {if (breakpoint === "mobile" || breakpoint === "tablet") {successMessageOnMobile(); axios.get(`${baseUrl}user/me`, {headers: {Authorization: `Bearer ${token}`}}).then(res => {queryClient.setQueryData('userData', res.data); setUserData(res.data)}); setTimeout(() => {setPopupCuitVisible(false)}, 1500)} else {gotoStep(current + 1); queryClient.invalidateQueries({queryKey: ['userData']}); axios.get(`${baseUrl}user/me`, {headers: {Authorization: `Bearer ${token}`}}).then(res => {queryClient.setQueryData('userData', res.data)}); setTimeout(() => {gotoStep(0)}, 2000)}},
      onError: (e) => {gotoStep(0); errorMessage(t("dashboardPage.userSettings.phone.errorMessage"))}
    })
  }

  const [messageApi, contextHolder] = message.useMessage();

  const successMessageOnMobile = () => {
    messageApi.open({
      type: 'success',
      icon: <CheckOutlined style={{color: "#03b9ac"}} />,
      content: t("dashboardPage.userSettings.phone.resultSuccessMessage")
    })
  }

  const {mutate: onFinishForm} = useSendForm()

  const formList = [
    <>
        <Row style={{}}>
            <Col span={12}><Typography style={RESUME_TITLES}>{(havePhone()) ? t("dashboardPage.userSettings.phone.form.yourPhoneIs") : t("dashboardPage.userSettings.phone.form.phoneNotFoundTitle")}</Typography> <Typography style={RESUME_VALUES}>{(havePhone()) ? userData.phone : t("dashboardPage.userSettings.phone.form.phoneNotFoundDescription")}</Typography> </Col>
            <Col span={12}><Button onClick={() => {gotoStep(current +  1 )}} id='btn-secondary'>{(havePhone()) ? t("dashboardPage.userSettings.phone.form.modifyButton") : t("dashboardPage.userSettings.phone.form.addButton")}</Button></Col>
        </Row>
    </>,
    <>
        <Row style={{display: "flex", justifyContent: "space-evenly"}}>
            <Col span={11}>
                <Form.Item name="phone"  style={{marginBottom: "0px"}}>
                    <Input style={{width: "100%"}} placeholder={t('dashboardPage.userSettings.phone.form.inputPhonePlaceholder')||""}/>
                </Form.Item>
            </Col>
            <Col span={11}><Button onClick={() => {submit() }} id='btn-secondary'>{t("dashboardPage.userSettings.phone.form.submitButton")}</Button></Col>
        </Row>
    </>,
    <>
        <Row style={{display: "flex", justifyContent: "space-evenly"}}>
            <span style={{gap: "20px"}}>
            <CheckOutlined style={{color: "green", fontSize: "1.5rem", paddingLeft: "15px"}}/>
                {t("dashboardPage.userSettings.phone.resultSuccessMessage")}
            </span>
        </Row>
    </>
  ]

  const loadingIcon = <LoadingOutlined style={{ fontSize: 50, color: "#03b9ac" }} spin />;
  
  //Popup mobile
  const [popupCuitVisible, setPopupCuitVisible] = useState(false)

  return (
    <>
      {(breakpoint === "mobile" || breakpoint === "tablet") ?
      (userData === undefined) ?
      <div style={{display: 'flex', flexDirection: "row", alignContent: 'center', alignItems: "center"}}>{loadingIcon}</div>
      :
      <div style={{display: "flex"}}>
        {contextHolder}
        <Row onClick={() => setPopupCuitVisible(true)} style={{height: "135px",  justifyContent: "center",paddingLeft: "25px", width: "98vw", backgroundColor: "#F2F0F0", borderRadius: "4px", display: "flex", flexDirection: "row", alignItems: "center"}}>
          <Col span={22}>
          {((!havePhone()) 
          ?
          <Typography style={{fontWeight: "800", fontFamily: "Poppins"}}>{t("dashboardPage.userSettings.phone.form.phoneNotFoundTitle")}</Typography>
          :
          <>
            <Row>
              <Typography style={{fontWeight: "800", fontFamily: "Poppins"}}>{t("dashboardPage.userSettings.phone.title")}</Typography>
            </Row>
            <Row>
              <Typography style={{fontWeight: "500", fontFamily: "Poppins"}}>{t("dashboardPage.userSettings.phone.form.yourPhoneIs")}</Typography><Typography style={{fontWeight: "300", fontFamily: "Poppins", paddingLeft: "5px"}}>{userData.phone}</Typography>
            </Row>
          </>
          )}
          </Col>
          <Col span={2}>
            <RightOutlined />
          </Col>
        </Row>
        <Popup showCloseButton visible={popupCuitVisible} onMaskClick={() => {setPopupCuitVisible(false); form.resetFields()}} onClose={() => setPopupCuitVisible(false)} bodyStyle={{ height: 'auto' }} mask={true} closeOnSwipe>
            <Row style={{display: "flex", flexDirection: "column",  justifyContent: "space-evenly", paddingLeft: "25px", paddingRight: "25px", paddingTop: "45px", paddingBottom: "25px", gap: "20px"}}>
                <Typography style={{fontFamily: "Poppins", fontSize: "15px", fontWeight: "800"}}>{t('dashboardPage.userSettings.phone.form.phoneLabel')}</Typography>
                <Col span={24}>
                  <Form form={form} onFinish={useSendForm}>
                    <Form.Item name="phone" style={{marginBottom: "0px"}}>
                        <Input style={{height: "38px"}} placeholder={t('dashboardPage.userSettings.phone.form.inputPhonePlaceholder')||""}/>
                    </Form.Item>
                  </Form>
                </Col>
                <Col span={12} offset={12}>
                  <Button style={{height: "38px", display: "flex", width: "100%", alignItems: "center", justifyContent: "center"}} onClick={() => {submit() }} id='btn-secondary'>{t("dashboardPage.userSettings.phone.form.submitButton")}</Button>
                </Col>
            </Row>
        </Popup>
      </div>
      :
      <>
        <Typography style={DASHBOARD_SECTION_TITLES} >{t("dashboardPage.userSettings.phone.title")}</Typography>
        <Steps>
        </Steps>
          <div>
            <Form name='cuit' form={form} {...formProps}>
              {formList[current]}
            </Form>
          </div>
      </>
      }
    </>
  )
}

export default Phone