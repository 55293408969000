import { Col, Row, Typography } from 'antd';
import React from 'react'
import { useTranslation } from 'react-i18next';
import FiscalKey from '../../../../components/UserSettings/FiscalKey';
import Cuit from '../../../../components/UserSettings/Cuit';
import { DASHBOARD_TITLES } from '../../../../components/common/utils/styles';
import PointOfSale from '../../../../components/UserSettings/PointOfSale';
import { useBreakpoint } from 'use-breakpoint';
import Phone from '../../../../components/UserSettings/Phone';
import InvoiceTemplate from '../../../../components/UserSettings/InvoiceTemplate';

const BREAKPOINTS = { mobile: 0, tablet: 768, desktop: 1280 }

const Profile: React.FC = () => {

  const { breakpoint, maxWidth, minWidth } = useBreakpoint(BREAKPOINTS);
  const { t } = useTranslation()

  return (
    <>{(breakpoint === "mobile" || breakpoint === "tablet") ?
      <>
        <Typography style={DASHBOARD_TITLES} >{t("dashboardPage.userSettings.title")}</Typography>
        <div style={{display: "flex", flexDirection: "column", gap: "15px", minHeight: "100%", marginTop: "15px", paddingBottom: "70px"}}>
          <div style={{display: "flex", flexDirection: "column"}}>
            <Cuit></Cuit>
          </div>
          <div style={{display: "flex", flexDirection: "column"}}>
            <FiscalKey></FiscalKey>
          </div>
          <div style={{display: "flex", flexDirection: "column"}}>
            <Phone></Phone>
          </div>
          <div style={{display: "flex", flexDirection: "column"}}>
            <PointOfSale></PointOfSale>
          </div>
        </div>
      </>
      :
      <>
        <Typography style={DASHBOARD_TITLES} >{t("dashboardPage.userSettings.title")}</Typography>
        <Row style={{display: "flex", justifyContent: "space-between"}}>
          <Col span={8}>
            <div className='sectionDiv' style={{minHeight: "200px", marginRight: "5px"}}>
              <Cuit></Cuit>
            </div>
          </Col>
          <Col span={8}>
            <div className='sectionDiv' style={{minHeight: "200px", marginRight: "5px", marginLeft: "5px"}}>
              <FiscalKey />
            </div>
          </Col>
          <Col span={8}>
            <div className='sectionDiv' style={{minHeight: "200px", marginLeft: "5px"}}>
              <Phone />
            </div>
          </Col>
        </Row>
        <Row style={{display: "flex", justifyContent: "flex-start", paddingBottom: "50px"}}>
          <Col span={11}>
            <div className='sectionDiv' style={{minHeight: "200px", height: "100%"}}>
              <PointOfSale />
            </div>
          </Col>
        </Row>
      </>
      }
    </>
  )
}

export default Profile