import axios from 'axios'
import { useQuery, useQueryClient } from 'react-query'

const useGetData = (endpoint: string, queryKey: string, refetchInterval?: any) => {
    const baseUrl = process.env.REACT_APP_BASE_URL
    const token = JSON.parse(localStorage.getItem("token") || '{}')
    const config = {
        headers: {Authorization: `Bearer ${token}`}
    }

    const fetchData = async () => {
        const res: any = await axios.get(`${baseUrl}${endpoint}`, config)
        return res.data
      }

    const { data } = useQuery(`${queryKey}`, fetchData, {cacheTime: 1500000, refetchOnReconnect: "always", refetchInterval: refetchInterval, staleTime: 500})
    
    return data
}

export default useGetData