import React, { useContext, useEffect, useRef, useState } from 'react';
import { Col, InputNumber, InputRef, Modal, Popover, Row, Typography, message } from 'antd';
import { Button, Form, Input, Popconfirm, Table } from 'antd';
import type { FormInstance } from 'antd/es/form';
import axios from 'axios';
import { t } from 'i18next';
import { DASHBOARD_SECTION_TITLES } from '../common/utils/styles';
import { LoadingOutlined, RightOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { useBreakpoint } from 'use-breakpoint';
import { Popup, SwipeAction, SwipeActionRef } from 'antd-mobile';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';

const BREAKPOINTS = { mobile: 0, tablet: 768, desktop: 1280 }
const EditableContext = React.createContext<FormInstance<any> | null>(null);

interface Item {
    id: number;
    name: string;
  }

interface EditableRowProps {
  index: number;
}

const EditableRow: React.FC = ({ ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  children: React.ReactNode;
  dataIndex: keyof Item;
  record: Item;
  handleSave: (record: Item) => void;
}

const EditableCell: React.FC<EditableCellProps> = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef<InputRef>(null);
  const form = useContext(EditableContext)!;

  useEffect(() => {
    if (editing) {
      inputRef.current!.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({ [dataIndex]: record[dataIndex] });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{ margin: 0 }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `El campo es obligatorio.`,
          },
        ]}
      >
        <Input allowClear onFocus={e => e.target.select()} style={{userSelect: "all"}} ref={inputRef} onPressEnter={save} onBlur={save} />
      </Form.Item>
    ) : (
      <div className="editable-cell-value-wrap" style={{ paddingRight: 24 }} onClick={toggleEdit}>
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

type EditableTableProps = Parameters<typeof Table>[0];

interface DataType {
    id?: number;
    name: string | undefined;
    afip_id?: number | string;
}

type ColumnTypes = Exclude<EditableTableProps['columns'], undefined>;

const PointOfSale: React.FC = () => {

  const { breakpoint, maxWidth, minWidth } = useBreakpoint(BREAKPOINTS);
  const form = useFormInstance()

  const token = JSON.parse(localStorage.getItem("token") || '{}')
  const config = {headers: {Authorization: `Bearer ${token}`}}
  const baseUrl = process.env.REACT_APP_BASE_URL

  const [dataSource, setDataSource] = useState<DataType[]>([]);
  const [count, setCount] = useState(dataSource.length);
  const [isLoading, setIsLoading] = useState(false)

  const deletePointOfSale = async (id: number) => {
    await axios.delete(`${baseUrl}point-of-sale/delete/${id}`, config)
    setCount(count-1)
    }

  const defaultColumns: (ColumnTypes[number] & { editable?: boolean; dataIndex?: string })[] = [

    {
      title: t("dashboardPage.userSettings.point_of_sale.table.title"),
      dataIndex: 'name',
      editable: true,
    },
    {
      title: t("dashboardPage.userSettings.point_of_sale.table.afipId"),
      editable: false,
      render: (_ :any, item: any) =>  <InputNumber min={0} onBlur={(e: any) => handleSave(item, e.target.value)} style={{ width: "60px"}} defaultValue={ (item.afip_id === undefined || item.afip_id === null) ? null :  item.afip_id}></InputNumber>
    },
    {
      title: '',
      dataIndex: 'operation',
      width: '5%',
      render: (_, record: any) =>
        dataSource.length >= 1 ? (
          <Popconfirm title={t("dashboardPage.userSettings.point_of_sale.table.sureToDelete")} okButtonProps={{type: "text"}} onConfirm={() => {deletePointOfSale(record.id)}}>
                <Button id='button-small-outline-green-bg' style={{borderRadius: "4px"}} >{t("dashboardPage.userSettings.point_of_sale.table.deleteButton")}</Button>
          </Popconfirm>
        ) : null,
    },
  ];

  const handleAdd = async (name?: any) => {
    setCurrentItem("")
    const newData: DataType = {
        name: `${t("dashboardPage.userSettings.point_of_sale.table.placeholder")}`,
        afip_id: undefined,
    };
    await axios.post(`${baseUrl}point-of-sale/create`, name, config).then(res => setIsModalOpen(false))
    .catch(e => message.error("error"))

    setCount(count + 1);
  };

  const handleSave = async (row: DataType, afip_id?: any) => {
    setIsLoading(true)
    const id = row.id
    await axios.patch(`${baseUrl}point-of-sale/update/${id}`, {name: row.name, afip_id: (afip_id === "") ? null : afip_id}, config).then(res => setCount(count + 1))
  };

  const handleSaveOnMobile = async (id: string, values: any ) => {
    setIsLoading(true)
    await axios.patch(`${baseUrl}point-of-sale/update/${id}`, {name: values.name, afip_id: (values.afip_id === "") ? null : values.afip_id}, config).then(res => {setIsLoading(false); setCount(count + 1)})
    .catch(e => message.error("error"))
  
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: DataType) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

    //Popup mobile
    const [popupPointOfSaleVisible, setPopupPointOfSaleVisible] = useState(false)
    const loadingIcon = <LoadingOutlined style={{ fontSize: 50, color: "#03b9ac" }} spin />;

    //Swipe Action
    const ref = useRef<SwipeActionRef>(null)

    //Modal to add a new point of sale on mobile
    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
      setIsModalOpen(true);
    };
  
    const handleOk = () => {
      setIsModalOpen(false);
    };
  
    const handleCancel = () => {
      setIsModalOpen(false);
    };

    const [currentItem, setCurrentItem] = useState<any>("")
    const [inputValue, setInputValue] = useState("")
    const [isEditing, setIsEditing] = useState(false)
    const [id, setId] = useState("")

    useEffect(() => {
      axios.get(`${baseUrl}point-of-sale/me`, config).then( res => {setDataSource(res.data.sort((a: any, b: any) => a.id - b.id)); setCount(res.data.length); setIsLoading(false); setCurrentItem("")
      })
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [count, isModalOpen])


  return (
    <>
    {
      (breakpoint === "mobile" || breakpoint === "tablet")
      ?
      <div style={{display: "flex"}}>
        <Row onClick={() => setPopupPointOfSaleVisible(true)} style={{height: "135px",  justifyContent: "center",paddingLeft: "25px", width: "98vw", backgroundColor: "#F2F0F0", borderRadius: "4px", display: "flex", flexDirection: "row", alignItems: "center"}}>
          <Col span={22}>
            <Row>
              <Typography style={{fontWeight: "800", fontFamily: "Poppins"}}>{t("dashboardPage.userSettings.point_of_sale.title")}</Typography>
            </Row>
            <Row>
              <Typography style={{fontWeight: "500", fontFamily: "Poppins"}}>{t("dashboardPage.userSettings.point_of_sale.subtitle")}</Typography><Typography style={{fontWeight: "300", fontFamily: "Poppins", paddingLeft: "5px"}}>{ (dataSource === undefined) ? "0" : dataSource.length}</Typography>
            </Row>
          </Col>
          <Col span={2}>
            <RightOutlined />
          </Col>
        </Row>
        <Popup showCloseButton visible={popupPointOfSaleVisible} onMaskClick={() => setPopupPointOfSaleVisible(false)} onClose={() => setPopupPointOfSaleVisible(false)} bodyStyle={{ height: '90vh', overflow: "auto" }} mask={true} closeOnSwipe>
          <div style={{ marginTop: "15px", paddingBottom: "15px"}}>
            {
            (dataSource === undefined || isLoading)?
        
            <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>{loadingIcon}</div>
            :
            (dataSource.length === 0 && !isLoading) ?
            <div style={{display: "flex", flexDirection: "column", gap: "15px", alignItems: "center"}}>
              <div style={{textAlign: "center", paddingBottom: "30px"}}>{"No hay Puntos de Venta"}</div>
              <div onClick={(dataSource.length < 5) ? () => {showModal(); setIsEditing(false); setInputValue("")} : () => {}} style={{height: "100px", width: "95vw", backgroundColor: "white", border: "1px dashed", alignItems: "center", padding: "40px", textAlign: "center", borderColor: (dataSource.length < 5) ? "#03b9ac" : "lightgrey", color: (dataSource.length < 5) ? "#03b9ac" : "lightgrey", borderRadius: "4px", display: "flex", justifyContent: "space-evenly", fontFamily: "Poppins"}}>
                {(dataSource.length < 5) ? t("dashboardPage.userSettings.point_of_sale.addButtonTooltip") : t("dashboardPage.userSettings.point_of_sale.addButtonTooltipMaxPointOfSale") }
              </div>
              <Modal destroyOnClose bodyStyle={{display: "flex", flexDirection: "column", paddingTop: "25px"}} title={t("dashboardPage.userSettings.point_of_sale.add") + " " + t("dashboardPage.userSettings.point_of_sale.table.title")} open={isModalOpen} closable onCancel={handleOk} cancelButtonProps={{ hidden: true }} okButtonProps={{ hidden: true }} style={{width: "95vw", display: "flex", flexDirection: "column", }}>
                <Form form={form} onFinish={(values) => (isEditing) ? handleSaveOnMobile(id, values) : handleAdd(values)}>
                  <Form.Item name={"name"} required>
                    <Input defaultValue={inputValue} value={inputValue} style={{height: "38px"}} placeholder={t("dashboardPage.userSettings.point_of_sale.table.placeholderOnMobile")||""}></Input>
                  </Form.Item>
                  <Row style={{display: "flex", flexDirection: "row", gap: "10px", width: "100%", justifyContent: "space-between"}}>
                    <Col span={11}>
                      <Button onClick={() => handleCancel()} style={{height: "38px", display: "flex", width: "100%", alignItems: "center", justifyContent: "center"}}  id='btn-secondary-outline'>{"Cancelar"}</Button>
                    </Col>
                    <Col span={11}>
                      <Button onClick={() => handleCancel()} htmlType='submit' style={{height: "38px", display: "flex", width: "100%", alignItems: "center", justifyContent: "center"}}  id='btn-secondary'>{t("dashboardPage.userSettings.cuit.form.submitButton")}</Button>
                    </Col>
                  </Row>
                </Form>
              </Modal>
            </div>
            :
            <div style={{display: "flex", flexDirection: "column", gap: "15px", alignItems: "center"}}>
            {
            dataSource.map((item: any) => (
            <SwipeAction
            ref={ref}
            closeOnAction={true}
            closeOnTouchOutside={true}
            rightActions={
            [
              {
                key: 'delete',
                text: <DeleteOutlined style={{fontSize: "2rem"}}/>,
                color: "#FF4869",
                onClick: () => deletePointOfSale(item.id),
              },
              {
                key: 'edit',
                text: <EditOutlined style={{fontSize: "2rem"}}/>,
                color: "#5F8BFF",
                onClick: () => {setCurrentItem(item) ; setInputValue(item.name); showModal(); setIsEditing(true); setId(item.id)}
              }
            ]
            }
            >
              <Row style={{height: "100px", width: "95vw", backgroundColor: "#F2F0F0", borderRadius: "4px", display: "flex", justifyContent: "space-evenly", fontFamily: "Poppins"}}>
                <Col span={10} style={{fontFamily: "Poppins", display: "flex", flexDirection: "column", justifyContent: "space-evenly", paddingTop: "5px", paddingBottom: "5px"}}>
                  <Row>
                    <Typography style={{fontSize: "0.85rem", fontWeight: "600", fontFamily: "Poppins"}}>{t("dashboardPage.userSettings.point_of_sale.table.title") + ":"}</Typography><Typography style={{fontSize: "0.85rem", fontWeight: "300", paddingLeft: "6px", fontFamily: "Poppins"}}>{item.name}</Typography>
                  </Row>
                </Col>
                <Col span={10} style={{fontFamily: "Poppins", display: "flex", flexDirection: "column", justifyContent: "space-evenly", paddingTop: "5px", paddingBottom: "5px"}}>
                  <Row>
                    <Typography style={{fontSize: "0.85rem", fontWeight: "600", fontFamily: "Poppins"}}>{t("dashboardPage.userSettings.point_of_sale.table.afipId") + ":"}</Typography><Typography style={{fontSize: "0.85rem", fontWeight: "300", paddingLeft: "6px", fontFamily: "Poppins"}}>{item.afip_id}</Typography>
                  </Row>
                </Col>
              </Row>
            </SwipeAction>
            )
            )}
              <div onClick={(dataSource.length < 5) ? () => {showModal(); setIsEditing(false); setInputValue("")} : () => {}} style={{height: "100px", width: "95vw", backgroundColor: "white", border: "1px dashed", alignItems: "center", padding: "40px", textAlign: "center", borderColor: (dataSource.length < 5) ? "#03b9ac" : "lightgrey", color: (dataSource.length < 5) ? "#03b9ac" : "lightgrey", borderRadius: "4px", display: "flex", justifyContent: "space-evenly", fontFamily: "Poppins"}}>
                {(dataSource.length < 5) ? t("dashboardPage.userSettings.point_of_sale.addButtonTooltip") : t("dashboardPage.userSettings.point_of_sale.addButtonTooltipMaxPointOfSale") }
              </div>
              <Modal destroyOnClose bodyStyle={{display: "flex", flexDirection: "column", paddingTop: "25px"}} title={t("dashboardPage.userSettings.point_of_sale.add") + " " + t("dashboardPage.userSettings.point_of_sale.table.title")} open={isModalOpen} closable onCancel={handleOk} cancelButtonProps={{ hidden: true }} okButtonProps={{ hidden: true  }} style={{width: "95vw", display: "flex", flexDirection: "column", }}>
                <Form form={form} onFinish={(values) => (isEditing) ? handleSaveOnMobile(id, values) : handleAdd(values)}>
                  <Form.Item name={"name"}  rules={[{ required: true, message: 'Nombre punto de venta es obligatorio' }]}>
                    <Input defaultValue={inputValue} value={inputValue} style={{height: "38px"}} placeholder={t("dashboardPage.userSettings.point_of_sale.table.placeholderOnMobile")||""}></Input>
                  </Form.Item>
                  <Form.Item name={"afip_id"} rules={[{ required: true, message: 'Afip ID es obligatorio' }]}>
                    <Input defaultValue={currentItem.afip_id } value={currentItem.afip_id} style={{height: "38px"}} placeholder={t("dashboardPage.userSettings.point_of_sale.table.afipIdPlaceholder")||""}></Input>
                  </Form.Item>
                  <Row style={{display: "flex", flexDirection: "row", gap: "10px", width: "100%", justifyContent: "space-between"}}>
                    <Col span={11}>
                      <Button onClick={() => handleCancel()} style={{height: "38px", display: "flex", width: "100%", alignItems: "center", justifyContent: "center"}}  id='btn-secondary-outline'>{"Cancelar"}</Button>
                    </Col>
                    <Col span={11}>
                      <Button htmlType='submit' style={{height: "38px", display: "flex", width: "100%", alignItems: "center", justifyContent: "center"}}  id='btn-secondary'>{t("dashboardPage.userSettings.cuit.form.submitButton")}</Button>
                    </Col>
                  </Row>
                </Form>
              </Modal>
            </div>
          }
          </div>
        </Popup>
      </div>
      :
      <div >
        <Row>
          <Col span={24}>
            <Typography style={DASHBOARD_SECTION_TITLES}>{t("dashboardPage.userSettings.point_of_sale.title")}</Typography>
          </Col>
        </Row>
        <Popover content={(count >= 5) ? t("dashboardPage.userSettings.point_of_sale.addButtonTooltipMaxPointOfSale") : t("dashboardPage.userSettings.point_of_sale.addButtonTooltip")}>
          <Button disabled={(count >= 5)} id='btn-secondary' onClick={() => {showModal(); setIsEditing(false); setInputValue("")}} style={{ marginBottom: 16 }}>
            {t("dashboardPage.userSettings.point_of_sale.add")}
          </Button>
        </Popover>
        <Table
          loading={(isLoading) ? {indicator: <LoadingOutlined style={{color: "#03b9ac", fontSize: "50px"}}/>, spinning: true} : false}
          //rowClassName={() => 'editable-row'}
          bordered
          dataSource={dataSource}
          columns={columns as ColumnTypes}
          size="small"
          
        />
              <Modal destroyOnClose bodyStyle={{display: "flex", flexDirection: "column", paddingTop: "25px"}} title={t("dashboardPage.userSettings.point_of_sale.add") + " " + t("dashboardPage.userSettings.point_of_sale.table.title")} open={isModalOpen} closable onCancel={handleOk} cancelButtonProps={{ hidden: true }} okButtonProps={{ hidden: true  }} style={{width: "95vw", display: "flex", flexDirection: "column", }}>
                <Form form={form} onFinish={(values) => (isEditing) ? handleSaveOnMobile(id, values) : handleAdd(values)}>
                  <Form.Item name={"name"}  rules={[{ required: true, message: 'Nombre punto de venta es obligatorio' }]}>
                    <Input defaultValue={inputValue} value={inputValue} style={{height: "38px"}} placeholder={t("dashboardPage.userSettings.point_of_sale.table.placeholderOnMobile")||""}></Input>
                  </Form.Item>
                  <Form.Item name={"afip_id"} rules={[{ required: true, message: 'Afip ID es obligatorio' }]}>
                    <Input defaultValue={currentItem.afip_id } value={currentItem.afip_id} style={{height: "38px"}} placeholder={t("dashboardPage.userSettings.point_of_sale.table.afipIdPlaceholder")||""}></Input>
                  </Form.Item>
                  <Row style={{display: "flex", flexDirection: "row", gap: "10px", width: "100%", justifyContent: "space-between"}}>
                    <Col span={11}>
                      <Button onClick={() => handleCancel()} style={{height: "38px", display: "flex", width: "100%", alignItems: "center", justifyContent: "center"}}  id='btn-secondary-outline'>{"Cancelar"}</Button>
                    </Col>
                    <Col span={11}>
                      <Button htmlType='submit' style={{height: "38px", display: "flex", width: "100%", alignItems: "center", justifyContent: "center"}}  id='btn-secondary'>{t("dashboardPage.userSettings.cuit.form.submitButton")}</Button>
                    </Col>
                  </Row>
                </Form>
              </Modal>
      </div>
    }
    </>
  );
};

export default PointOfSale;