import axios from "axios";
import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { useQueryClient } from "react-query";

const LoadingPage = () => {
  const queryClient = useQueryClient();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const antIcon = (
    <LoadingOutlined style={{ color: "#03b9ac", fontSize: 50 }} spin />
  );
  const onCreateSub = async (code?: string | null) => {
    //Se utilica el code que se recibe de los parametros para vincular la cuenta y crear la suscripcion
    const token = localStorage.getItem("token");
    const jsonToken = token ? JSON.parse(token) : "";

    if (!token) {
      //?code=${code}
      //navigate(`/signin`);

      navigate(`/signin?code=${code}`);
    } else {
      try {
        const planIdJson = localStorage.getItem("planId");
        if (!planIdJson) throw new Error();
        const planId = JSON.parse(planIdJson);
        const data = await axios.post(
          //`${baseUrl}subscription/create-preference/${planId}`,
          `${baseUrl}subscription/create/${planId}${
            code ? `?code=${code}` : ""
          }`,
          null,
          {
            headers: {
              Authorization: `Bearer ${jsonToken}`,
            },
          }
        );
        const { init_point } = data.data;
        localStorage.removeItem("planId");
        queryClient.invalidateQueries({ queryKey: ["userData"] });
        window.location.href = init_point;
      } catch (error) {
        navigate("/");
      }
    }
  };
  useEffect(() => {
    const code = searchParams.get("code");

    (async () => await onCreateSub(code))();
  }, []);
  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        alignItems: "center",
        color: "#03b9ac",
        width: "100vw",
        height: "100vh",
      }}
    >
      <Spin style={{}} indicator={antIcon} />
    </div>
  );
};

export default LoadingPage;
