import React, { useContext, useEffect, useRef, useState } from 'react';
import { Col, InputRef, Row, Typography } from 'antd';
import { Button, Form, Input, Popconfirm, Table } from 'antd';
import type { FormInstance } from 'antd/es/form';
import axios from 'axios';
import { t } from 'i18next';
import { DASHBOARD_SECTION_TITLES } from '../../../../../components/common/utils/styles';
import { LoadingOutlined } from '@ant-design/icons';


const EditableContext = React.createContext<FormInstance<any> | null>(null);

interface Item {
    id: number;
    order: number;
    title: string;
    description: string;
  }

interface EditableRowProps {
  index: number;
}

const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  children: React.ReactNode;
  dataIndex: keyof Item;
  record: Item;
  handleSave: (record: Item) => void;
}

const EditableCell: React.FC<EditableCellProps> = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef<InputRef>(null);
  const form = useContext(EditableContext)!;

  useEffect(() => {
    if (editing) {
      inputRef.current!.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({ [dataIndex]: record[dataIndex] });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{ margin: 0 }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `El campo ${title} es obligatorio.`,
          },
        ]}
      >
        <Input ref={inputRef} onPressEnter={save} onBlur={save} />
      </Form.Item>
    ) : (
      <div className="editable-cell-value-wrap" style={{ paddingRight: 24 }} onClick={toggleEdit}>
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

type EditableTableProps = Parameters<typeof Table>[0];

interface DataType {
    id?: number;
    order: number;
    title: string;
    description: string;
}

type ColumnTypes = Exclude<EditableTableProps['columns'], undefined>;

const FaqsAdmin: React.FC = () => {

    const token = JSON.parse(localStorage.getItem("token") || '{}')
    const config = {headers: {Authorization: `Bearer ${token}`}}
    const baseUrl = process.env.REACT_APP_BASE_URL

  const [dataSource, setDataSource] = useState<DataType[]>([]);
  const [count, setCount] = useState(dataSource.length);

  const deleteFaq = (id: number) => {
    return axios.delete(`${baseUrl}faq/delete/${id}`, config)
    }

  const handleDelete = (id: number) => {
    setIsLoading(true)
    const newData = dataSource.filter((item) => item.id !== id);
    setDataSource(newData);
    setCount(count-1)
  };

  const defaultColumns: (ColumnTypes[number] & { editable?: boolean; dataIndex: string })[] = [
    {
      title: 'Orden',
      dataIndex: 'order',
      editable: true
    },

    {
      title: t("adminDashboardPage.landingPageOptions.faqTable.title"),
      dataIndex: 'title',
      editable: true,
    },
    {
      title: t("adminDashboardPage.landingPageOptions.faqTable.description"),
      dataIndex: 'description',
      editable: true,
    },
    {
      title: '',
      dataIndex: 'operation',
      width: '5%',
      render: (_, record: any) =>
        dataSource.length >= 1 ? (
          <Popconfirm title={t("adminDashboardPage.landingPageOptions.faqTable.sureToDelete")} okButtonProps={{type: "text"}} onConfirm={() => {handleDelete(record.id); deleteFaq(record.id)}}>
                <Button id='btn-secondary' style={{borderRadius: "4px"}} >{t("adminDashboardPage.landingPageOptions.faqTable.deleteButton")}</Button>
          </Popconfirm>
        ) : null,
    },
  ];

  const [isLoading, setIsLoading] = useState(false)

  const handleAdd = () => {
    const newData: DataType = {
        order: (dataSource.length === 0) ? 1 : dataSource[dataSource.length-1].order+1,
        title: `Pregunta`,
        description: 'Respuesta',
    };
    setDataSource([...dataSource, newData]);
    setCount(count + 1);
    axios.post(`${baseUrl}faq/create`, newData, config)
  };

  const handleSave = (row: DataType) => {
    setIsLoading(true)
    const newData = [...dataSource];
    const id = row.id
    const index = newData.findIndex((item) => row.id === item.id);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setDataSource(newData);
    axios.put(`${baseUrl}faq/update/${id}`, {order: row.order, title: row.title, description: row.description}, config).then(() => setCount(count + 1))
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: DataType) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  useEffect(() => {
    axios.get(`${baseUrl}faq/get-all/`).then( res => {setDataSource(res.data); setCount(res.data.length); setIsLoading(false)})
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [count])

  return (
    <div className='resumeDiv' style={{padding: "20px", marginBottom: "20px"}}>
      <Row style={{marginTop: "20px", marginBottom: "20px"}}>
        <Col span={24}>
          <Typography style={DASHBOARD_SECTION_TITLES}>{t("adminDashboardPage.landingPageOptions.faqSectionTitle")}</Typography>
        </Col>
      </Row>
      <Button id='btn-secondary' onClick={handleAdd} style={{ marginBottom: 16 }}>
        {t("adminDashboardPage.landingPageOptions.addNewFaq")}
      </Button>
      <Table
        loading={(isLoading) ? {indicator: <LoadingOutlined style={{color: "#03b9ac", fontSize: "50px"}}/>, spinning: true} : false}
        components={components}
        rowClassName={() => 'editable-row'}
        bordered
        dataSource={dataSource}
        columns={columns as ColumnTypes}
        pagination={{pageSize: 20}}
        style={{overflow: "hidden"}} scroll={{x: true}}

      />
    </div>
  );
};

export default FaqsAdmin;