import React, { useEffect, useState } from "react";
import {
  ConfigProvider,
  Layout,
  Button,
  Form,
  Input,
  message,
  Typography,
  Checkbox,
  Select,
  Row,
  Col,
} from "antd";
import useSignin from "./query/useSignin";
import { FormSignin } from "./SigninPage.types";
import "../LoginPage/LoginPage.scss";
import { useTranslation } from "react-i18next";
import useBreakpoint from "use-breakpoint";
import { useNavigate, useSearchParams } from "react-router-dom";
import NavBar from "../LandingPage/sections/NavBar";
import { useQueryClient } from "react-query";

const BREAKPOINTS = { mobile: 0, tablet: 768, desktop: 1280 };

const { Content } = Layout;

const SigninPage: React.FC = () => {
  const [form] = Form.useForm();
  const queryClient = useQueryClient();

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { breakpoint } = useBreakpoint(BREAKPOINTS, "desktop");

  const [, setSigninData] = useState<FormSignin>({
    name: "",
    email: "",
    password: "",
  });

  const [code, setCode] = useState<string | null>(searchParams.get("code"));

  const { mutate: userSignin, isLoading } = useSignin(
    !!code
  );

  const { t } = useTranslation();

  const [oldIdValues, setOldIdValues] = useState<any>();

  useEffect(() => {
    const old_id = document.cookie
      .split("; ")
      .filter((row) => row.startsWith("old_id="))
      .map((c) => c.split("=")[1])[0];
    const codeValue = searchParams.get("code");
    if (old_id !== undefined) {
      fetch("old_id.json")
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          setOldIdValues(data);
        })
        .catch((e: Error) => {
          console.log(e.message);
        });
    } else {
    }
    if (codeValue === null) {
    } else setCode(codeValue);
  }, []);

  useEffect(() => {
    const isAuthenticated = localStorage.getItem("token");
    if (isAuthenticated !== null && queryClient.getQueryData("token"))
      navigate("/dashboard/vouchers");
  }, []);

  //The function triggered when values changes
  const handleSigninData = (changedValues: any, allValues: any) => {
    allValues = delete allValues.confirmPassword;
    setSigninData(allValues);
  };

  //The function triggered when click Enviar button
  const onFinish = (formSignin: FormSignin) => {
    if (code === null) delete formSignin.code;
    else {
    }
    if (
      formSignin.old_id === undefined ||
      formSignin.old_id === "" ||
      formSignin.old_id === null
    )
      delete formSignin.old_id;
    else {
    }
    delete formSignin.confirmPassword;
    delete formSignin.termsCheckbox;
    userSignin(formSignin);
  };

  //Show an error message on the top of the window when there is an error
  const errorMessage = () => {
    message.open({
      type: "error",
      content: `${t("signinPage.signinForm.error")}`,
    });
  };

  const [isMonotributist, setIsMonotributist] = useState<boolean>();

  const handleSelect = (value: boolean) => {
    setIsMonotributist(value);
  };

  const signinForm = (
    <Form
      form={form}
      name="signin"
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={errorMessage}
      onValuesChange={handleSigninData}
      autoComplete="off"
      size="large"
      style={{
        padding: "1rem",
        display: "block",
        width: breakpoint === "mobile" ? "100%" : "70%",
        fontSize: "1.5rem",
        alignItems: "center",
        marginLeft: "auto",
        marginRight: "auto",
      }}
    >
      <Form.Item
        hidden
        name="old_id"
        initialValue={
          document.cookie
            .split("; ")
            .filter((row) => row.startsWith("old_id="))
            .map((c) => c.split("=")[1])[0]
        }
      ></Form.Item>
      {/* <Form.Item
        hidden
        name="isMonotributist"
        initialValue={isMonotributist}
      ></Form.Item> */}
      <Form.Item hidden name="code" initialValue={code}></Form.Item>

      <Form.Item
        labelAlign="left"
        label={t("signinPage.signinForm.isMonotributistLabel")}
        name="isMonotributist"
        initialValue={isMonotributist}
        rules={[
          {
            required: true,
            message: `${t("signinPage.signinForm.isMonotributistError")}`,
          },
        ]}
      >
        <Select
          style={{}}
          options={[
            { value: true, label: "Sí" },
            { value: false, label: "No" },
          ]}
          onSelect={(value) => handleSelect(value)}
          placeholder={`${t(
            "signinPage.signinForm.isMonotributistPlaceholder"
          )}`}
        ></Select>
      </Form.Item>
      <Form.Item
        labelAlign="left"
        label={t("signinPage.signinForm.nameLabel")}
        name="name"
        initialValue={oldIdValues !== undefined ? oldIdValues.name : ""}
        rules={[
          {
            required: true,
            message: `${t("signinPage.signinForm.nameError")}`,
          },
        ]}
      >
        <Input
          data-testid="name"
          placeholder={`${t("signinPage.signinForm.namePlaceholder")}`}
        />
      </Form.Item>
      <Form.Item
        labelAlign="left"
        label={t("signinPage.signinForm.emailLabel")}
        name="email"
        initialValue={oldIdValues !== undefined ? oldIdValues.email : ""}
        rules={[
          {
            type: "email",
            message: `${t("signinPage.signinForm.emailError")}`,
          },
          {
            required: true,
            message: `${t("signinPage.signinForm.emailError")}`,
          },
        ]}
      >
        <Input
          data-testid="email"
          placeholder={`${t("signinPage.signinForm.emailPlaceholder")}`}
        />
      </Form.Item>
      <Form.Item
        labelAlign="left"
        label={t("signinPage.signinForm.phoneLabel")}
        name={"phone"}
      >
        <Input type="tel" style={{ width: "100%", borderRadius: "4px" }} />
      </Form.Item>
      <Form.Item
        style={{ borderRadius: "4px", lineHeight: "54px" }}
        labelAlign="left"
        label={t("signinPage.signinForm.passwordLabel")}
        name="password"
        rules={[
          {
            required: true,
            message: `${t("signinPage.signinForm.passwordError")}`,
          },
          () => ({
            validator(_, value) {
              if (value.length < 8) {
                return Promise.reject(
                  new Error(`${t("signinPage.signinForm.passwordLengthError")}`)
                );
              } else return Promise.resolve();
            },
          }),
        ]}
      >
        <Input.Password
          data-testid="password"
          placeholder={`${t("signinPage.signinForm.passwordPlaceholder")}`}
        />
      </Form.Item>

      <Form.Item
        style={{ borderRadius: "4px" }}
        labelAlign="left"
        label={t("signinPage.signinForm.confirmPasswordLabel")}
        name="confirmPassword"
        dependencies={["password"]}
        rules={[
          {
            required: true,
            message: `${t("signinPage.signinForm.confirmPasswordError")}`,
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue("password") === value) {
                return Promise.resolve();
              }
              return Promise.reject(
                new Error(`${t("signinPage.signinForm.confirmPasswordError")}`)
              );
            },
          }),
        ]}
      >
        <Input.Password
          data-testid="confirm-password"
          placeholder={`${t(
            "signinPage.signinForm.confirmPasswordPlaceholder"
          )}`}
        />
      </Form.Item>
      <Form.Item
        style={{ borderRadius: "4px" }}
        labelAlign="left"
        name="cuit"
        initialValue={oldIdValues !== undefined ? oldIdValues.cuit : undefined}
        label={t("signinPage.signinForm.cuitLabel")}
      >
        <Input
          placeholder={`${t("signinPage.signinForm.inputCuitPlaceholder")}`}
        />
      </Form.Item>
      <Form.Item
        style={{ borderRadius: "4px" }}
        labelAlign="left"
        name="fiscalKey"
        label={t("signinPage.signinForm.fiscalKeyLabel")}
      >
        <Input
          placeholder={`${t(
            "signinPage.signinForm.inputFiscalKeyPlaceholder"
          )}`}
        />
      </Form.Item>
      <Form.Item
        name="termsCheckbox"
        valuePropName="checked"
        rules={[
          () => ({
            validator(_, checked) {
              if (checked) return Promise.resolve();
              else
                return Promise.reject(
                  new Error(`${t("signinPage.signinForm.termsCheckBoxError")}`)
                );
            },
          }),
        ]}
      >
        <Checkbox style={{ display: "flex" }}>
          <Button
            onClick={() => navigate("/tyc")}
            style={{
              fontSize: "17px",
              fontWeight: "regular",
              color: "#029389",
            }}
            type="link"
          >
            {t("signinPage.signinForm.termsCheckBox")}
          </Button>
        </Checkbox>
      </Form.Item>
      <Form.Item
        shouldUpdate
        style={{
          padding: "1rem",
          paddingBottom: "2rem",
          display: "flex",
          justifyContent: "center",
        }}
      >
        {({ getFieldsValue, getFieldsError }) => {
          const { name, email, password, confirmPassword } = getFieldsValue();
          const error = getFieldsError();
          const formIsComplete =
            !!email &&
            !!password &&
            !!name &&
            !!password &&
            !!confirmPassword &&
            error[0].errors.length === 0 &&
            error[1].errors.length === 0 &&
            error[2].errors.length === 0 &&
            error[3].errors.length === 0 &&
            error[4].errors.length === 0;
          return (
            <Button
              disabled={!formIsComplete}
              className="btnlogin"
              type="primary"
              loading={isLoading}
              htmlType="submit"
              style={{
                height: "auto",
                borderRadius: "0.25rem",
                marginTop: "25px",
                boxShadow: "none",
              }}
            >
              <span style={{}}>
                {localStorage.getItem("planId") !== null
                  ? t("signinPage.signinForm.subscribeButton")
                  : t("signinPage.signinForm.submitButton")}
              </span>
            </Button>
          );
        }}
      </Form.Item>
      {localStorage.getItem("planId") !== null ? (
        <Typography style={{ fontSize: "15px", color: "grey" }}>
          {t("signinPage.signinForm.disclaimer")}
          <a
            style={{ color: "#029389" }}
            href="mailto:soporte@monotributo.digital"
          >
            {"soporte@monotributo.digital"}
          </a>
        </Typography>
      ) : (
        <></>
      )}
    </Form>
  );

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#029389",
          colorBgBase: "white",
          colorBgContainer: "white",
          fontSize: 20,
          fontFamily: "Dosis",
          colorText: "#252422",
        },
      }}
    >
      <Layout
        className="layout"
        style={{
          background: "white",
          display: "flex",
          flexWrap: "wrap",
          alignContent: "center",
        }}
      >
        <NavBar></NavBar>
        <Content
          style={{
            display: "flex",
            flexDirection: "column",
            margin: "4rem",
            background: "white",
            maxWidth: "100vw",
            alignContent: "center",
          }}
        >
          <div
            className="site-layout-content container"
            style={
              breakpoint === "desktop" || breakpoint === "tablet"
                ? {
                    maxWidth: "60vw",
                    display: "flex",
                    flexDirection: "column",
                    background: "#f7f6f6de",
                    alignItems: "center",
                    backgroundImage:
                      "url('https://monotributo.digital/img/map.png')",
                    borderRadius: "4px",
                  }
                : {
                    width: "90vw",
                    display: "flex",
                    flexDirection: "column",
                    background: "#f7f6f6de",
                    backgroundImage:
                      "url('https://monotributo.digital/img/map.png')",
                    borderRadius: "4px",
                    alignItems: "center",
                  }
            }
          >
            <Typography.Title
              style={{
                display: "flex",
                justifyContent: "center",
                paddingTop: "4rem",
                color: "#000",
                fontSize: breakpoint === "mobile" ? "2rem" : "3rem",
              }}
              level={2}
            >
              {t("signinPage.signInTitle")}
            </Typography.Title>
            <Typography.Title
              style={{
                display: "flex",
                justifyContent: "center",
                paddingTop: "0rem",
                marginTop: "0.3rem",
                color: "#000",
                fontSize: breakpoint === "mobile" ? "1rem" : "1.5rem",
              }}
              level={5}
            >
              {t("signinPage.signInSubTitle")}
            </Typography.Title>
            {oldIdValues === undefined ? (
              <>
                {/* <Select
                  style={{
                    marginBottom: "2rem",
                    width: "65%",
                    fontSize: "1.5rem",
                    alignItems: "center",
                    height: "54px",
                  }}
                  placeholder={"¿Sos monotributista?"}
                  options={[
                    { value: true, label: "Sí" },
                    { value: false, label: "No" },
                  ]}
                  onSelect={(value) => handleSelect(value)}
                ></Select> */}
                {/* {isMonotributist === true ? (
                  signinForm
                ) : isMonotributist === false ? (
                  <Row
                    style={{
                      marginBottom: "2rem",
                      width: "70%",
                      fontSize: "1.5rem",
                      alignItems: "center",
                    }}
                  >
                    <Col
                      span={24}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        flexWrap: "wrap",
                        alignContent: "center",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <Button
                        style={{
                          display: "flex",
                          maxWidth: "280px",
                          justifyContent: "center",
                          borderRadius: "0.25rem",
                          marginTop: "25px",
                          boxShadow: "none",
                          padding: "auto",
                          margin: "auto",
                        }}
                        className="btnlogin"
                        type="primary"
                        href="https://monotributo.digital/"
                      >
                        {t("signinPage.simulateButton")}
                      </Button>
                      <Typography>{t("signinPage.simulateMessage")}</Typography>
                    </Col>
                  </Row>
                ) : (
                  <></>
                )} */}
                <>{signinForm}</>
              </>
            ) : (
              <>{signinForm}</>
            )}
          </div>
          <Button
            onClick={() => navigate("/login")}
            style={{
              fontSize: "20px",
              marginTop: "20px",
              fontWeight: "bold",
              color: "#029389",
            }}
            type="link"
          >
            {t("signinPage.alreadyHaveAnAccount")}
          </Button>
        </Content>
      </Layout>
    </ConfigProvider>
  );
};

export default SigninPage;
