import { message } from 'antd';
//Show an error message on the top of the window when there is an error 
export const errorMessageLogin = () => {
    message.open({
    type: 'error',
    content: 'Email o password incorrectas',
    });
};

export const errorMessage = (prop: string) => {
    message.open({
    type: 'error',
    content: `${prop}`,
    });
};