import React, { useEffect, useState } from 'react'
import { Button, DatePicker, Form, Input, InputNumber, Radio, RadioChangeEvent, Select, Space, Spin, Typography } from 'antd';
import { Option } from 'antd/es/mentions';
import { useTranslation } from 'react-i18next';
import { MinusCircleOutlined, PlusOutlined, LoadingOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons';
import axios from 'axios';
import { useQuery, useQueryClient } from 'react-query';
import { useBreakpoint } from 'use-breakpoint';
import useGetData from '../../hooks/useGetData';

const BREAKPOINTS = { mobile: 0, tablet: 768, desktop: 1280 }

const Step2: React.FC<{ saleConditionValue?: any, userId?: any }> = (props) => {
    const form = Form.useFormInstance();
    const { breakpoint, maxWidth, minWidth } = useBreakpoint(BREAKPOINTS);
    const queryClient = useQueryClient()

    const loadingIcon = <LoadingOutlined style={{ fontSize: 20, color: "#03b9ac" }} spin />;

    const baseUrl = process.env.REACT_APP_BASE_URL

    const token = JSON.parse(localStorage.getItem("token") || '{}')

    const config = {
        headers: {Authorization: `Bearer ${token}`}
    }

    const { t } = useTranslation()

    const voucherTypesData = form.getFieldValue("type")
    const docTypesData: string[] = queryClient.getQueryData("docTypes") || []
    const ivaConditionData = queryClient.getQueryData("ivaCondition") || {}
    const associatedVouchersTypes = queryClient.getQueryData("associatedVouchersTypes") || {}

    const associatedVouchersTypesFilteredByVoucherType = () => {
        switch (voucherTypesData) {
            case "Factura C": return ["Remito R"]
            case "Nota de Débito C": return ["Factura C", "Nota de Débito C", "Nota de Crédito C", "Recibo C", "Remito R"]
            case "Nota de Crédito C": return ["Factura C", "Nota de Débito C", "Nota de Crédito C", "Recibo C", "Remito R"]
            case "Recibo C": return []
            case "Nota de Débito Electrónica MiPyMEs (FCE) C": return ["Factura de Crédito Electrónica MiPyME (FCE) C", "Nota de Débito Electrónica MiPyME (FCE) C", "Nota de Crédito Electrónica MiPyME (FCE) C", "Remito R"]
            case "Nota de Crédito Electrónica MiPyMEs (FCE) C": return ["Factura de Crédito Electrónica MiPyME (FCE) C", "Nota de Débito Electrónica MiPyME (FCE) C", "Nota de Crédito Electrónica MiPyME (FCE) C", "Remito R"]
            case "Factura de Crédito Electrónica MiPyMEs (FCE) C": return ["Remito R"]
            default: return []
        }
    }

    const acceptedValuesForAll = ['CUIT']
    const acceptedValuesForForeignClients = ['CUIT', 'CI Extranjera', 'Pasaporte', 'Doc. (Otro)']

    const docTypesDataForAll = (docTypesData === undefined) ? [] : Object.values(docTypesData).filter(value => acceptedValuesForAll.includes(value))
    const docTypesDataForForeignClients = (docTypesData === undefined) ? [] : Object.values(docTypesData).filter(value => acceptedValuesForForeignClients.includes(value))
        
    const [condicionesVentaData, setCondicionesVentaData] = useState<string>("")
    
    const onChange = (e: RadioChangeEvent) => {
        setCondicionesVentaData(e.target.value);
    };

    const [ivaConditionSelected, setIvaConditionSelected] = useState<string>("")

    function handleIvaConditionSelect(value: string){
        setIvaConditionSelected(value)
        form.setFieldValue("doc_type", undefined)
    }

    const docTypeDataToDisplay = () => {
        switch(ivaConditionSelected) {
            case "Consumidor Final": return Object.values(docTypesData)
            case "Cliente del Exterior": return docTypesDataForForeignClients
            default: return docTypesDataForAll
    }}

    const [cuit, setCuit] = useState<string>("")
    const [isCuitFilled, setIsCuitFilled] = useState<boolean>(false)
    const [razonSocialData, setRazonSocialData] = useState<any>()
    const [, setRazonSocial] = useState<any>()
    const [docType, setDocType] = useState<any>()
  
    function handleDocType(value:string){
        setDocType(value)
    }

    const pointOfSaleData = useGetData((localStorage.getItem("role") === "admin") || queryClient.getQueryData("role") === "admin" ? `point-of-sale/by-user/${props.userId}` : "point-of-sale/me", "pointOfSale")

    const handleCuit = (event: any) => {
        setCuit(event.target.value)
        setRazonSocialData("")
        setRazonSocial({})
        form.setFieldsValue({"surname_name_or_business_name": ""})
        if ((event.target.value).length === 11) {setIsCuitFilled(true)
    }
        else {setIsCuitFilled(false)}
    }

    const fetchData = async () => {
        const res: any = await axios.get(`${baseUrl}information/business-name/${cuit}`, config)
        return res.data
      }

    const razonSocial = useQuery([`razon-social`, [cuit]], fetchData, {enabled: (isCuitFilled && docType === "CUIT"), retry: 1, retryDelay: 2, suspense: false})

    useEffect(() => {
        setDocType(form.getFieldValue("doc_type"))
        setCuit(form.getFieldValue("doc_number"))
        if (cuit === "" || cuit === undefined) {setIsCuitFilled(false); setCuit("")}
        else setIsCuitFilled((cuit.length === 11) ? true : false)
    }, [])
    
    useEffect(() => {
        const isCuit = (docType === "CUIT") ? true : false
        if (razonSocial.isSuccess && isCuitFilled === true && isCuit === true ){form.setFieldsValue({
                "business_name": razonSocial.data.p_razon_social
            }); setRazonSocialData(razonSocial.data.p_razon_social)}
        else if (isCuit === false){}
        else { if (form.getFieldValue("business_name") === undefined) {form.setFieldsValue({"business_name": ""}); setRazonSocialData("")} else {}}
        
    }, [razonSocial, cuit, razonSocialData, isCuitFilled, form, ivaConditionSelected, docType])



  return (
    <>
        <Form.Item label={t("vouchers.form.VAT_conditions")} name="vat_condition" rules={[{ required: false, message: `${t("vouchers.form.VAT_conditions")} ${" "} ${t("isRequired")}` }]}>
            <Select id='step7' style={{height: "58px"}} onChange={handleIvaConditionSelect}>
                {(ivaConditionData === undefined) ? <Option disabled></Option> : Object.values(ivaConditionData).map((data: any) => <Option key={data} value={data} >{data}</Option>)}
            </Select>
        </Form.Item>
        <Form.Item shouldUpdate label={t("vouchers.form.doc_type")} name="doc_type" rules={[{ required: false, message: `${t("vouchers.form.doc_type")} ${" "} ${t("isRequired")}` }]}>
            <Select id='step8' style={{height: "58px", lineHeight: "52px"}} onChange={(event) => handleDocType(event)}>
                {docTypeDataToDisplay().map((data: any) => <Option key={data} value={data} >{data}</Option>)}
            </Select>
        </Form.Item>
        <Form.Item shouldUpdate label={t("vouchers.form.doc_number")} name="doc_number" rules={[{ required: true, message: `${t("vouchers.form.doc_number")} ${" "} ${t("isRequired")}` }]}>
            <Input id='step9' onChange={(event) => handleCuit(event)} style={{fontFamily: "Dosis", fontSize: "18px"}} />
        </Form.Item>
        <Form.Item shouldUpdate initialValue={razonSocialData}
            label={
                (razonSocial.isLoading === true || (docType === "CUIT" && cuit.length === 11 && razonSocialData === "" && razonSocial.isError === false))
                ? 
                <>{t("vouchers.form.surname_name_or_business_name")} <Spin style={{paddingLeft: "10px"}} indicator={loadingIcon} /></>
                :
                (razonSocial.isSuccess === true && isCuitFilled === true && docType === "CUIT" && razonSocialData !== "")
                ?
                <>{t("vouchers.form.surname_name_or_business_name")} <CheckOutlined style={{ paddingLeft: "10px", fontSize: 20, color: "#03b9ac" }} /></>
                :
                (razonSocial.isError === true && isCuitFilled === true && docType === "CUIT")
                ?
                <>{t("vouchers.form.surname_name_or_business_name")} <CloseOutlined style={{ paddingLeft: "10px", fontSize: 20, color: "red" }} /><div style={{fontSize: "16px", fontWeight: "400"}}>{t("vouchers.form.cuit_not_found")}</div> </>
                :
                t("vouchers.form.surname_name_or_business_name")}
            name="business_name" rules={[ {required: false, message: `${t("vouchers.form.surname_name_or_business_name")} ${" "} ${t("isRequired")}` }]}> 
            <Input style={{fontFamily: "Dosis", fontSize: "18px"}} id='step10' value={razonSocialData} onChange={(event) => setRazonSocialData(event)} disabled={docType === "CUIT" && (razonSocial.isLoading === true || razonSocial.isSuccess === true)}/>
        </Form.Item>
        <Form.Item label={t("vouchers.form.address")} name="recidence">
            <Input style={{fontFamily: "Dosis", fontSize: "18px"}} />
        </Form.Item>
        <Form.Item label={t("vouchers.form.sale_conditions")} name="sale_conditions" rules={[{required: true}]}>
            <Radio.Group id='step11' onChange={onChange} value={condicionesVentaData}>
                <Radio defaultChecked={(props.saleConditionValue === undefined ? false : props.saleConditionValue.saleConditionValue === t("vouchers.form.cash"))} value={t("vouchers.form.cash")} >{t("vouchers.form.cash")}</Radio>
                <Radio defaultChecked={(props.saleConditionValue === undefined ? false : props.saleConditionValue.saleConditionValue === t("vouchers.form.creditCard"))} value={t("vouchers.form.creditCard")} >{t("vouchers.form.creditCard")}</Radio>
                <Radio defaultChecked={(props.saleConditionValue === undefined ? false : props.saleConditionValue.saleConditionValue === t("vouchers.form.debitCard"))} value={t("vouchers.form.debitCard")} >{t("vouchers.form.debitCard")}</Radio>
                <Radio defaultChecked={(props.saleConditionValue === undefined ? false : props.saleConditionValue.saleConditionValue === t("vouchers.form.bankAccount"))} value={t("vouchers.form.bankAccount")} >{t("vouchers.form.bankAccount")}</Radio>
                <Radio defaultChecked={(props.saleConditionValue === undefined ? false : props.saleConditionValue.saleConditionValue === t("vouchers.form.bankCheck"))} value={t("vouchers.form.bankCheck")} >{t("vouchers.form.bankCheck")}</Radio>
                <Radio defaultChecked={(props.saleConditionValue === undefined ? false : props.saleConditionValue.saleConditionValue === t("vouchers.form.ticket"))} value={t("vouchers.form.ticket")} >{t("vouchers.form.ticket")}</Radio>
                <Radio defaultChecked={(props.saleConditionValue === undefined ? false : condicionesVentaData !== "" && props.saleConditionValue.saleConditionValue !== "" && props.saleConditionValue.saleConditionValue !== t("vouchers.form.cash") && props.saleConditionValue.saleConditionValue !== t("vouchers.form.creditCard") && props.saleConditionValue.saleConditionValue !== t("vouchers.form.debitCard") && props.saleConditionValue.saleConditionValue !== t("vouchers.form.bankAccount") && props.saleConditionValue.saleConditionValue !== t("vouchers.form.bankCheck") && props.saleConditionValue.saleConditionValue !== t("vouchers.form.ticket"))} value={(props.saleConditionValue === undefined ? "" : condicionesVentaData !== "" && props.saleConditionValue.saleConditionValue !== t("vouchers.form.cash") && props.saleConditionValue.saleConditionValue !== t("vouchers.form.creditCard") && props.saleConditionValue.saleConditionValue !== t("vouchers.form.debitCard") && props.saleConditionValue.saleConditionValue !== t("vouchers.form.bankAccount") && props.saleConditionValue.saleConditionValue !== t("vouchers.form.bankCheck") && props.saleConditionValue.saleConditionValue !== t("vouchers.form.ticket")) ? props.saleConditionValue.saleConditionValue  : ""}>
                    {t("vouchers.form.other") +
                        (condicionesVentaData === t("vouchers.form.other"))
                        ?
                        <input onChange={(e) => form.setFieldValue("sale_conditions", e.target.value)} style={{border: "1px solid", borderRadius: "4px", borderColor: "lightgrey", paddingLeft: "5px", marginLeft: "5px"}} defaultValue={(props.saleConditionValue === undefined ? false : props.saleConditionValue.saleConditionValue !== t("vouchers.form.cash") && props.saleConditionValue.saleConditionValue !== t("vouchers.form.creditCard") && props.saleConditionValue.saleConditionValue !== t("vouchers.form.debitCard") && props.saleConditionValue.saleConditionValue !== t("vouchers.form.bankAccount") && props.saleConditionValue.saleConditionValue !== t("vouchers.form.bankCheck") && props.saleConditionValue.saleConditionValue !== t("vouchers.form.ticket") ) ? props.saleConditionValue.saleConditionValue : ""} /> 
                        : 
                        <></>}
                </Radio>
            </Radio.Group>
        </Form.Item>
        <Typography style={{fontSize: "20px", fontWeight: "bold", fontFamily: "Dosis", color: "#606266", paddingBottom: "8px"}}>{t("vouchers.form.associated_vouchers")}</Typography>
        <Form.List name="associated_voucher" >
            {(fields, {add, remove}) => (
            <>
                {fields.map(({key, name, ...restField}) => (
                <Space key={key} align="baseline" style={(breakpoint !== "desktop") ? {marginBottom: "50px", border: "1px solid", borderColor: "#f5f5f5", padding: "16px", borderRadius: "6px"} : {}}>
                    <Form.Item shouldUpdate={(prevValues, curValues) => prevValues.additional !== curValues.additional}>
                    {() => {
                    return(
                    <div style={(breakpoint !== "desktop") ? {display: "flex", flexDirection: "column", width: "80vw"} : {display: "flex", flexDirection: "row", gap: "30px"}}>
                    <Form.Item  {...restField} label={t("vouchers.form.type")} name={[name, "type"]} rules={[{required: true}]} >
                        <Select style={{height: "58px"}}>
                            {(associatedVouchersTypes === undefined) ? <Option disabled></Option> : (associatedVouchersTypesFilteredByVoucherType()).map((data: any) => <Option key={data} value={data} >{data}</Option>)}
                        </Select>
                    </Form.Item>
                    <Form.Item {...restField} label={t("vouchers.form.point_of_sale")} name={[name, "point_of_sale"]} rules={[{required: true}]} >
                        <Select id='step3' style={{height: "58px"}} defaultValue={''}>
                            {(pointOfSaleData === undefined) ? [] : Object.values(pointOfSaleData).map((data: any) => <Option key={data.id} value={data.name} >{data.name}</Option>)}
                        </Select>
                    </Form.Item>
                    <Form.Item {...restField} label={t("vouchers.form.voucher_number")} name={[name, "number"]} rules={[{required: true}]} >
                        <Input type='number'/>
                    </Form.Item>
                    <Form.Item {...restField} label={t("vouchers.form.date")} name={[name, "date"]} rules={[{required: true}]} >
                        <DatePicker format={"DD-MM-YYYY"} style={{height: "58px", fontFamily: "Dosis", fontSize: "18px", borderRadius: "4px"}}></DatePicker>
                    </Form.Item>
                    <span onClick={() => remove(name)} style={{marginTop: (breakpoint !== "desktop") ? "0px" : "72px"}}><MinusCircleOutlined style={(breakpoint !== "desktop") ? {padding: "0 5px"} : {padding: "0 20px"}} />{(breakpoint !== "desktop") ? "Borrar linea" : ""}</span>
                    </div>
                    )}}
                    </Form.Item>
                </Space>
                ))}
                <Form.Item name={"key"}>
                <Button id='step12' type="dashed" onClick={() => add()}  block icon={<PlusOutlined />}>
                    {t("add")}
                </Button>
                </Form.Item>
            </>
            )}
        </Form.List>
  </>
  )
}

export default Step2