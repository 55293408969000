export const PADDING_CONTENT = 24
export const SIDER_BACKGROUND_COLOR = '#4E7A99'
export const DASHBOARD_BACKGROUND_COLOR = 'white'
export const DASHBOARD_MENU_BACKGROUND_COLOR = 'rgba(255, 255, 255, 0.2)'
export const DASHBOARD_MENU_HEIGHT = '32px'
export const DASHBOARD_MENU_MARGIN = '16px'
export const DASHBOARD_MENU_TEXT_COLOR = '#ffffff'
export const RESUME_VALUES = {fontFamily: "Dosis", fontWeight: "lighter", fontSize: "16px"}
export const RESUME_VALUES_WHITE = {fontFamily: "Dosis", fontWeight: "lighter", fontSize: "16px", color: "white"}

export const RESUME_TITLES = {fontFamily: "Dosis", fontWeight: "bolder", fontSize: "16px"}
export const RESUME_TITLES_WHITE = {fontFamily: "Dosis", fontWeight: "bolder", fontSize: "16px", color: "white"}

export const BUTTON_SECONDARY = {padding: "10 34px", fontSize: "14px", }
export const DASHBOARD_SECTION_TITLES = {display:'flex', justifyContent: 'center', paddingTop: '1rem', color: '#000', fontFamily: "Dosis", fontSize: "25px", paddingBottom: "2rem"}
export const DASHBOARD_TITLES = {display:'flex', justifyContent: 'center', paddingTop: '1rem', color: '#000', fontFamily: "Dosis", fontSize: "2rem", paddingBottom: "2rem"}