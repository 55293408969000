import React from 'react'
import { Navigate} from 'react-router-dom'
import { useQueryClient } from 'react-query'

//The idea is to show dashboard component to users and adminDashboard to Admins
interface Props {
    componentUser?: React.ComponentType
    componentAdmin?: React.ComponentType
  }

export const PrivateRoute: React.FC<Props> = ({ componentUser: RouteComponent, componentAdmin: RouteComponentAdmin }) => {
    
    const queryClient = useQueryClient()
    const state = queryClient.getQueryData("token")
    const role = queryClient.getQueryData("role")
    
    const isAdmin = (localStorage.getItem('role') === "admin" && role === "admin" )
    const isAuthenticated = (localStorage.getItem('token')) || state ? true : false

    if (isAuthenticated && isAdmin) {
      if (!RouteComponentAdmin) return null;
      return <RouteComponentAdmin />
    }
  
    if (isAuthenticated) {
      if (!RouteComponent) return null;
      return <RouteComponent />
    }
  
    return <Navigate to="/" />
  }