import React, { useEffect, useState } from 'react'
import { Accordion, Col, Container, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';
import {PlusCircleOutlined} from '@ant-design/icons';
import useGetData from '../../../hooks/useGetData';
import axios from 'axios';
import { useBreakpoint } from 'use-breakpoint';

const BREAKPOINTS = { mobile: 0, tablet: 768, desktop: 1280 }

const Faq: React.FC = () => {

    const { t } = useTranslation();
    const baseUrl = process.env.REACT_APP_BASE_URL
    const { breakpoint, maxWidth, minWidth } = useBreakpoint(BREAKPOINTS);


    const [faqsData, setFaqsData] = useState<any>([])

    useEffect(() => {
        axios.get(`${baseUrl}faq/get-all/`).then( res => setFaqsData(res.data))
    }, [])

  return (
        <Container data-testid="faq" fluid id="faq" className='pb-5' style={{position: "static", background: "#d3e7e5", paddingTop: (breakpoint === "mobile") ? "30px" : "100px"}}>
            <Row >
                <Col className='d-flex flex-column align-items-center pb-3'>
                    <h2 className='z-5' style={{fontSize: "38px", fontFamily: "Poppins", color: "#181818"}}>{t("landingPage.faqSection.title")}</h2>
                    <h3 style={{fontSize: "14px", fontFamily: "Poppins", color: "#585757"}}>{t("landingPage.faqSection.subtitle")}</h3>
                </Col>
            </Row>
            <Container>
                    <Row className='d-flex flex-column align-items-center'>
                        <Col sm={8}>
                            <Accordion flush className='shadow' style={{borderRadius: "4px", padding: "10px", color: "#69B2A8", fontFamily: "Poppins", backgroundColor: "#fff"}}>
                                {
                                    faqsData.map((faq: any) => {
                                        return (
                                            <Accordion.Item eventKey={faq.id} >
                                                <Accordion.Header className='' style={{fontFamily: "Poppins", fontWeight: "600"}} ><PlusCircleOutlined style={{color: '#69B2A8', paddingRight: "5px"}} />{faq.title}</Accordion.Header>
                                                <Accordion.Body>{faq.description}</Accordion.Body>
                                            </Accordion.Item>
                                        )
                                    })
                                }
                            </Accordion>
                        </Col>
                    </Row>
            </Container>
        </Container>

  )
}

export default Faq