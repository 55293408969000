import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, DatePicker, Drawer, Input, Modal, Popover, Row, Select, Table, Tag, Tour, TourProps, Typography } from 'antd';
import {useTranslation} from "react-i18next";
import VoucherRequestForm from '../../../../components/VoucherRequest/VoucherRequestForm';
import "./vouchers.scss"
import axios from 'axios';
import { useQuery, useQueryClient } from 'react-query';
import { DASHBOARD_TITLES } from '../../../../components/common/utils/styles';
import Prices from '../../../LandingPage/sections/Prices';
import dayjs from 'dayjs';
import { CaretUpOutlined, CaretDownOutlined, LoadingOutlined, ControlOutlined, CloseOutlined } from '@ant-design/icons';
import { useSearchParams } from 'react-router-dom';
import { useBreakpoint } from 'use-breakpoint';
import { Popup } from 'antd-mobile';
import InfiniteScroll from 'react-infinite-scroll-component';

const { Column } = Table;
const BREAKPOINTS = { mobile: 0, tablet: 768, desktop: 1280 }

export default function Vouchers() {

  const { breakpoint, maxWidth, minWidth } = useBreakpoint(BREAKPOINTS);
  const queryClient = useQueryClient()
  const userData: any = queryClient.getQueryData("userData")

  const [open, setOpen] = useState(false);
  const [voucherId, setVoucherId] = useState<number>()
  const [userCanUpdate, setUserCanUpdate]= useState<boolean>()

  const loadingIcon = <LoadingOutlined style={{ fontSize: 50, color: "#03b9ac" }} spin />;

  //Filters states
  const [dateRange, setDateRange] = useState<any>(["",""])
  const [page, setPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(10)
  const [query, setQuery] = useState("")
  const [order, setOrder] = useState("desc")
  const [orderBy, setOrderBy] = useState("id")
  const [statusFilter, setStatusFilter] = useState<string>("Todos")

  const { RangePicker } = DatePicker;

  const [searchParams, setSearchParams] = useSearchParams();

  const [totalItems, setTotalItems] = useState(10)

  const [userIsDemo, setUserIsDemo] = useState(true)

  const isDemo = async() => {
    if (userData.subscription === null) return setUserIsDemo(true)
        else {
          if (
            userData.subscription.status === "authorized" ||
            userData.subscription.status === "oneshoot" || 
            userData.subscription.status === "manual"
          )
          setUserIsDemo(false);
          else setUserIsDemo(true);
        }
  }

  useEffect(() => {
    axios.get(`${baseUrl}voucher-request/my-voucher-requests/?pageSize=1000000`, config).then(res => setTotalItems(res.data.length));
    isDemo()
  }, [])

  useEffect(() => {
    if (searchParams.get("page") !== "" ) {setPage(Number(searchParams.get("page")) || 1)}
    else if (searchParams.get("createAtFrom") !== "") {setDateRange([(searchParams.get("createAtFrom")), ""])}
    else if (searchParams.get("createAtTo") !== "") {setDateRange([(searchParams.get("createAtFrom")), (searchParams.get("createAtTo"))])}
    else if (searchParams.get("pageSize") !== "") {setPageSize(Number(searchParams.get("pageSize")) || 10)}
    else if (searchParams.get("query") !== "") {setQuery(searchParams.get("query") || "")}
    else if (searchParams.get("order") !== "") {setOrder(searchParams.get("order") || "asc")}
    else if (searchParams.get("orderBy") !== "") {setOrderBy(searchParams.get("orderBy") || "id")}
    else if (searchParams.get("voucherStatus") !== "") {setStatusFilter(searchParams.get("voucherStatus") || "")}
    else {}
  }, [])

  const showDrawer = () => {
      setOpen(true)
  }

  const onClose = (data: boolean) => {
    setOpen(false);
    queryClient.removeQueries(["razon-social"])
  }

  const { t } = useTranslation();

  const baseUrl = process.env.REACT_APP_BASE_URL

  const token = JSON.parse(localStorage.getItem("token") || '{}')

  const config = {
    headers: {Authorization: `Bearer ${token}`}
  }

  const [counter, setCounter] = useState(1)

  const fetchData = async () => {
    const res: any = await axios.get(`${baseUrl}voucher-request/my-voucher-requests/?page=${page}&pageSize=${pageSize}&query=${query}&createAtFrom=${dateRange[0]}&createAtTo=${dateRange[1]}&order=${order}&orderBy=${orderBy}&voucherStatus=${statusFilter}`, config)
    setTotalItems(res.total)
    return res.data
  }
  const { data, status } = useQuery(["vouchers", [dateRange, query, order, orderBy, page, pageSize, statusFilter]], fetchData, {suspense: false, retry: 1, onSuccess: (breakpoint === "mobile" && counter !== 1) ? () => setTimeout(() => { window.scroll(0, (document.documentElement.clientHeight)); setCounter(counter+1)}, 400) : () => {window.scrollBy(0, 0) ; setCounter(counter+1)}})





  //Modal that show plans
  const [openModal, setOpenModal] = useState(false);

  const showModal = () => {
    setOpenModal(true);
  };

  //Show the pdf in a new tab
  const showPdf = async (id) => {
    await axios.get(`${baseUrl}voucher-request/get-file/${id}`, {
      headers: {Authorization: `Bearer ${token}`},
      responseType: 'blob'
    }).then(response => {
  //Create a Blob from the PDF Stream
      const file = new Blob(
        [response.data],
        {type: 'application/pdf'});
        //Build a URL from the file
        const fileURL = URL.createObjectURL(file);
        //Open the URL on new Window
        window.open(fileURL);
    })
    .catch(error => {
        console.log(error);
    });
  };

  const updateStatus = (id: number) => {
    axios.patch(`${baseUrl}voucher-request/update-status-pending/${id}`, {} , config)
    queryClient.invalidateQueries("vouchers")
  }

  //Filter invoices by dates
  const handleDateRange = (dates: any) => {
    setCounter(1)
    if (dates[0] !== ""  && dates[1] !== "") setDateRange([dayjs(dates[0]).unix(), dayjs(dates[1]).unix()])
    else {}
  }

  //Filter by word
  const handleInputWordToFilter = (e) => {
    setCounter(1)
    setQuery(e.target.value)
  }

  //Clear Filters
  const clearFilters = () => {
    setCounter(1)
    setQuery("")
    setDateRange(["",""])
    setStatusFilter("")
  }

  //Status Filter
  const handleStatusFilter = (value: any) => {
    setCounter(1)
    if (value === "Demo") setStatusFilter("demo")
    else if (value === "Pendiente") setStatusFilter("pending")
    else if (value === "En Tramite") setStatusFilter("inProccess")
    else if (value === "Completadas") setStatusFilter("completed")
    else setStatusFilter("")
  }
  const selectStatusOptions = [{value: "Demo"}, {value: "Pendiente"}, {value: "En Tramite"}, {value: "Completadas"}, {value: "Todos"}]

  const selectStatus = <Select onChange={handleStatusFilter} showArrow defaultValue={["Todos"]} style={{ width: '100%' }} options={selectStatusOptions}/> 

  const popoverContentOnInProgress = (
    <div style={{display: "flex", flexDirection: "column", maxWidth: "150px"}}>
      <Typography style={{display:'flex', justifyContent: 'center', paddingTop: '1rem', color: '#000', fontFamily: "Poppins", fontSize: "16px", paddingBottom: "1rem"}}>{t("vouchers.table.in_progress_popover_message_line_1")}</Typography>
      <a href="mailto:soporte@monotributo.digital" style={{display:'flex', justifyContent: 'center', color: '#03b9ac', fontFamily: "Poppins", fontWeight: "bold", fontSize: "14px"}}>{t("vouchers.table.in_progress_popover_message_line_2")}</a>
    </div>
  )

  const popoverContentOnCompleted = (
    <div style={{display: "flex", flexDirection: "column", maxWidth: "150px"}}>
      <Typography style={{display:'flex', justifyContent: 'center', paddingTop: '1rem', color: '#000', fontFamily: "Poppins", fontSize: "16px", paddingBottom: "1rem"}}>{t("vouchers.table.completed_popover_message_line_1")}</Typography>
      <a href="mailto:soporte@monotributo.digital" style={{display:'flex', justifyContent: 'center', color: '#03b9ac', fontFamily: "Poppins", fontWeight: "bold", fontSize: "14px"}}>{t("vouchers.table.completed_popover_message_line_2")}</a>
    </div>
  )

  //Tour
  const [openTour, setOpenTour] = useState(false)

  useEffect(() => {
    if (queryClient.getQueryData("isFirstLogin") === true && queryClient.getQueryData("skipTour") !== true) setOpenTour(true)
  }, [])

  const step1 = useRef(null)
  const step2: any = useRef(null)
  const step3: any = useRef(null)
  const step4: any = useRef(null)
  const step5: any = useRef(null)
  const step6: any = useRef(null)
  const step7: any = useRef(null)
  const step21: any = useRef(null)

  const tourStyleOnMobile = {width: "90vw"}

  // Tour Steps
  const steps: TourProps['steps'] = [
    {
      title: t("tour.1.title"),
      description: <>{t("tour.1.description")}<div style={{cursor: "pointer", color: "#029389"}} onClick={() => {setOpenTour(false); queryClient.setQueryData("skipTour", true)}}>{t("tour.skip")}</div></>,
      target: () => step1.current!,
      nextButtonProps: {children: <>{t("tour.nextStepButton")}</>},
      style: (breakpoint !== "desktop") ? tourStyleOnMobile : {}
    },
    {
      title: t("tour.2.title"),
      description: <>{t("tour.2.description")}<div style={{cursor: "pointer", color: "#029389"}} onClick={() => {setOpenTour(false); queryClient.setQueryData("skipTour", true)}}>{t("tour.skip")}</div></>,
      target: () => step2.current!,
      nextButtonProps: {children: <>{t("tour.nextStepButton")}</>, onClick: () => showDrawer()},
      prevButtonProps: {children: <>{t("tour.prevStepButton")}</>, style: {borderColor: "#03b9ac", margin: "0px", color: "#03b9ac", fontWeight: "bold"}},
      style: (breakpoint !== "desktop") ? tourStyleOnMobile : {}
    },
    {
      title: t("tour.3.title"),
      description: <>{t(`tour.3${(userIsDemo) ? "demo" : ""}.description`)}{(userIsDemo) ? <></> : <a href='/dashboard/user' target='blank' style={{color: '#69b2a8'}}>{'aquí'}</a>}<div style={{cursor: "pointer", color: "#029389"}} onClick={() => {setOpenTour(false); queryClient.setQueryData("skipTour", true)}}>{t("tour.skip")}</div></>,
      target: () => document.getElementById("step3") || step3,
      nextButtonProps: {children: <>{t("tour.nextStepButton")}</> },
      prevButtonProps: {children: <>{t("tour.prevStepButton")}</>, onClick: () => setOpen(false), style: {borderColor: "#03b9ac", margin: "0px", color: "#03b9ac", fontWeight: "bold"}},
      style: (breakpoint !== "desktop") ? tourStyleOnMobile : {}
    },
    {
      title: t("tour.4.title"),
      description: <>{t("tour.4.description")}<div style={{cursor: "pointer", color: "#029389"}} onClick={() => {setOpenTour(false); queryClient.setQueryData("skipTour", true)}}>{t("tour.skip")}</div></>,
      target: () => document.getElementById("step4") || step4,
      nextButtonProps: {children: <>{t("tour.nextStepButton")}</>},
      prevButtonProps: {children: <>{t("tour.prevStepButton")}</>, style: {borderColor: "#03b9ac", margin: "0px", color: "#03b9ac", fontWeight: "bold"}},
      style: (breakpoint !== "desktop") ? tourStyleOnMobile : {}
    },
    {
      title: t("tour.5.title"),
      description: <>{t("tour.5.description")}<div style={{cursor: "pointer", color: "#029389"}} onClick={() => {setOpenTour(false); queryClient.setQueryData("skipTour", true)}}>{t("tour.skip")}</div></>,
      target: () => document.getElementById("step5") || step5,
      nextButtonProps: {children: <>{t("tour.nextStepButton")}</>},
      prevButtonProps: {children: <>{t("tour.prevStepButton")}</>, style: {borderColor: "#03b9ac", margin: "0px", color: "#03b9ac", fontWeight: "bold"}},
      style: (breakpoint !== "desktop") ? tourStyleOnMobile : {}
    },
    {
      title: t("tour.6.title"),
      description: <>{t("tour.6.description")}<div style={{cursor: "pointer", color: "#029389"}} onClick={() => {setOpenTour(false); queryClient.setQueryData("skipTour", true)}}>{t("tour.skip")}</div></>,
      target: () => document.getElementById("step6") || step6,
      nextButtonProps: {children: <div id='tour-next-btn'>{t("tour.nextStepButton")}</div>, onClick: () =>  document.getElementById("step6click")?.click()},
      prevButtonProps: {children: <>{t("tour.prevStepButton")}</>, style: {borderColor: "#03b9ac", margin: "0px", color: "#03b9ac", fontWeight: "bold"}},
      style: (breakpoint !== "desktop") ? tourStyleOnMobile : {}
    },
    {
      title: t("tour.7.title"),
      description: <>{t("tour.7.description")}<div style={{cursor: "pointer", color: "#029389"}} onClick={() => {setOpenTour(false); queryClient.setQueryData("skipTour", true)}}>{t("tour.skip")}</div></>,
      target: () => 
        document.getElementById("step3")
       || step7,
      nextButtonProps: {children: <div id='tour-next-btn'>{t("tour.nextStepButton")}</div>},
      prevButtonProps: {children: <>{t("tour.prevStepButton")}</>, onClick: () => document.getElementById("prev-step")?.click(), style: {borderColor: "#03b9ac", margin: "0px", color: "#03b9ac", fontWeight: "bold"}},
      onClose: () => {document.getElementById("tour-next-btn")?.click(); setOpenTour(false)},
      style: (breakpoint !== "desktop") ? tourStyleOnMobile : {}
    },
    {
      title: t("tour.8.title"),
      description: <>{t("tour.8.description")}<div style={{cursor: "pointer", color: "#029389"}} onClick={() => {setOpenTour(false); queryClient.setQueryData("skipTour", true)}}>{t("tour.skip")}</div></>,
      target: () => 
        document.getElementById("step8")
       || step7,
      nextButtonProps: {children: <>{t("tour.nextStepButton")}</>},
      prevButtonProps: {children: <>{t("tour.prevStepButton")}</>, style: {borderColor: "#03b9ac", margin: "0px", color: "#03b9ac", fontWeight: "bold", cursor: "not-allowed", pointerEvents: "none"}},
      style: (breakpoint !== "desktop") ? tourStyleOnMobile : {}
    },
    {
      title: t("tour.9.title"),
      description: <>{t("tour.9.description")}<div style={{cursor: "pointer", color: "#029389"}} onClick={() => {setOpenTour(false); queryClient.setQueryData("skipTour", true)}}>{t("tour.skip")}</div></>,
      target: () => 
        document.getElementById("step9")
       || step7,
      nextButtonProps: {children: <>{t("tour.nextStepButton")}</>},
      prevButtonProps: {children: <>{t("tour.prevStepButton")}</>, style: {borderColor: "#03b9ac", margin: "0px", color: "#03b9ac", fontWeight: "bold"}},
      style: (breakpoint !== "desktop") ? tourStyleOnMobile : {}
    },
    {
      title: t("tour.10.title"),
      description: <>{t("tour.10.description")}<div style={{cursor: "pointer", color: "#029389"}} onClick={() => {setOpenTour(false); queryClient.setQueryData("skipTour", true)}}>{t("tour.skip")}</div></>,
      target: () => 
        document.getElementById("step10")
       || step7,
      nextButtonProps: {children: <>{t("tour.nextStepButton")}</>},
      prevButtonProps: {children: <>{t("tour.prevStepButton")}</>, style: {borderColor: "#03b9ac", margin: "0px", color: "#03b9ac", fontWeight: "bold"}},
      style: (breakpoint !== "desktop") ? tourStyleOnMobile : {}
    },
    {
      title: t("tour.11.title"),
      description: <>{t("tour.11.description")}<div style={{cursor: "pointer", color: "#029389"}} onClick={() => {setOpenTour(false); queryClient.setQueryData("skipTour", true)}}>{t("tour.skip")}</div></>,
      target: () => 
        document.getElementById("step11")
       || step7,
      nextButtonProps: {children: <>{t("tour.nextStepButton")}</>},
      prevButtonProps: {children: <>{t("tour.prevStepButton")}</>, style: {borderColor: "#03b9ac", margin: "0px", color: "#03b9ac", fontWeight: "bold"}},
      style: (breakpoint !== "desktop") ? tourStyleOnMobile : {}
    },
    {
      title: t("tour.12.title"),
      description: <>{t("tour.12.description")}<div style={{cursor: "pointer", color: "#029389"}} onClick={() => {setOpenTour(false); queryClient.setQueryData("skipTour", true)}}>{t("tour.skip")}</div></>,
      target: () => 
        document.getElementById("step12")
       || step7,
      nextButtonProps: {children: <div id='tour-next-btn'>{t("tour.nextStepButton")}</div>, onClick: () => document.getElementById("step12click")?.click()},
      prevButtonProps: {children: <>{t("tour.prevStepButton")}</>, style: {borderColor: "#03b9ac", margin: "0px", color: "#03b9ac", fontWeight: "bold"}},
      style: (breakpoint !== "desktop") ? tourStyleOnMobile : {}
    },
    {
      title: t("tour.13.title"),
      description: <>{t("tour.13.description")}<div style={{cursor: "pointer", color: "#029389"}} onClick={() => {setOpenTour(false); queryClient.setQueryData("skipTour", true)}}>{t("tour.skip")}</div></>,
      target: () => document.getElementById("step8") || step6,
      nextButtonProps: {children: <div id='tour-next-btn'>{t("tour.nextStepButton")}</div>},
      prevButtonProps: {children: <>{t("tour.prevStepButton")}</>, onClick: () => document.getElementById("prev-step")?.click(), style: {borderColor: "#03b9ac", margin: "0px", color: "#03b9ac", fontWeight: "bold"}},
      onClose: () => {document.getElementById("tour-next-btn")?.click(); setOpenTour(false)},
      style: (breakpoint !== "desktop") ? tourStyleOnMobile : {}
    },
    {
      title: t("tour.14.title"),
      description: <>{t("tour.14.description")}<div style={{cursor: "pointer", color: "#029389"}} onClick={() => {setOpenTour(false); queryClient.setQueryData("skipTour", true)}}>{t("tour.skip")}</div></>,
      target: () => document.getElementById("step14") || step6,
      nextButtonProps: {children: <>{t("tour.nextStepButton")}</>},
      prevButtonProps: {children: <>{t("tour.prevStepButton")}</>, style: {borderColor: "#03b9ac", margin: "0px", color: "#03b9ac", fontWeight: "bold", cursor: "not-allowed", pointerEvents: "none"}},
      style: (breakpoint !== "desktop") ? tourStyleOnMobile : {}
    },
    {
      title: t("tour.15.title"),
      description: <>{t("tour.15.description")}<div style={{cursor: "pointer", color: "#029389"}} onClick={() => {setOpenTour(false); queryClient.setQueryData("skipTour", true)}}>{t("tour.skip")}</div></>,
      target: () => document.getElementById("step15") || step6,
      nextButtonProps: {children: <>{t("tour.nextStepButton")}</>},
      prevButtonProps: {children: <>{t("tour.prevStepButton")}</>, style: {borderColor: "#03b9ac", margin: "0px", color: "#03b9ac", fontWeight: "bold"}},
      style: (breakpoint !== "desktop") ? tourStyleOnMobile : {}
    },
    {
      title: t("tour.16.title"),
      description: <>{t("tour.16.description")}<div style={{cursor: "pointer", color: "#029389"}} onClick={() => {setOpenTour(false); queryClient.setQueryData("skipTour", true)}}>{t("tour.skip")}</div></>,
      target: () => document.getElementById("step16") || step6,
      nextButtonProps: {children: <>{t("tour.nextStepButton")}</>},
      prevButtonProps: {children: <>{t("tour.prevStepButton")}</>, style: {borderColor: "#03b9ac", margin: "0px", color: "#03b9ac", fontWeight: "bold"}},
      style: (breakpoint !== "desktop") ? tourStyleOnMobile : {}
    },
    {
      title: t("tour.17.title"),
      description: <>{t("tour.17.description")}<div style={{cursor: "pointer", color: "#029389"}} onClick={() => {setOpenTour(false); queryClient.setQueryData("skipTour", true)}}>{t("tour.skip")}</div></>,
      target: () => document.getElementById("step17") || step6,
      nextButtonProps: {children: <>{t("tour.nextStepButton")}</>},
      prevButtonProps: {children: <>{t("tour.prevStepButton")}</>, style: {borderColor: "#03b9ac", margin: "0px", color: "#03b9ac", fontWeight: "bold"}},
      style: (breakpoint !== "desktop") ? tourStyleOnMobile : {}
    },
    {
      title: t("tour.18.title"),
      description: <>{t("tour.18.description")}<div style={{cursor: "pointer", color: "#029389"}} onClick={() => {setOpenTour(false); queryClient.setQueryData("skipTour", true)}}>{t("tour.skip")}</div></>,
      target: () => document.getElementById("step18") || step6,
      nextButtonProps: {children: <>{t("tour.nextStepButton")}</>, onClick: () => document.getElementById("step18click")?.click()},
      prevButtonProps: {children: <>{t("tour.prevStepButton")}</>, style: {borderColor: "#03b9ac", margin: "0px", color: "#03b9ac", fontWeight: "bold"}},
      style: (breakpoint !== "desktop") ? tourStyleOnMobile : {}
    },
    {
      title: t("tour.19.title"),
      description: <>{t("tour.19.description")}<div style={{cursor: "pointer", color: "#029389"}} onClick={() => {setOpenTour(false); queryClient.setQueryData("skipTour", true)}}>{t("tour.skip")}</div></>,
      target: () => document.getElementById("step19") || step6,
      nextButtonProps: {children: <div id='tour-next-btn'>{t("tour.nextStepButton")}</div>},
      prevButtonProps: {children: <>{t("tour.prevStepButton")}</>, onClick: () => document.getElementById("prev-step")?.click(), style: {borderColor: "#03b9ac", margin: "0px", color: "#03b9ac", fontWeight: "bold"}},
      onClose: () => {document.getElementById("tour-next-btn")?.click(); setOpenTour(false)},
      style: (breakpoint !== "desktop") ? tourStyleOnMobile : {}
    },
    {
      title: t("tour.20.title"),
      description: <>{t("tour.20.description")}<div style={{cursor: "pointer", color: "#029389"}} onClick={() => {setOpenTour(false); queryClient.setQueryData("skipTour", true)}}>{t("tour.skip")}</div></>,
      target: () => document.getElementById("step20") || step6,
      nextButtonProps: {children: <>{t("tour.nextStepButton")}</>},
      prevButtonProps: {children: <>{t("tour.prevStepButton")}</>, style: {borderColor: "#03b9ac", margin: "0px", color: "#03b9ac", fontWeight: "bold"}},
      style: (breakpoint !== "desktop") ? tourStyleOnMobile : {}
    },
    {
      title: t("tour.21.title"),
      description: <>{t("tour.21.description")}<div style={{cursor: "pointer", color: "#029389"}} onClick={() => {setOpenTour(false); queryClient.setQueryData("skipTour", true)}}>{t("tour.skip")}</div></>,
      target: () => document.getElementById("step20click") || step6,
      nextButtonProps: {children: <>{t("tour.finishTourButton")}</>},
      prevButtonProps: {children: <>{t("tour.prevStepButton")}</>, style: { display: "none", borderColor: "#03b9ac", margin: "0px", color: "#03b9ac", fontWeight: "bold"}},
      style: (breakpoint !== "desktop") ? tourStyleOnMobile : {}
    }
  ]

  // Popup (only on mobile)
  const [popupVisible, setPopupVisible] = useState(false)

  //Popover state
  const [popoverVisible, setPopoverVisible] = useState(false)

  //InfiniteScroll
  const [hasMore, setHasMore] = useState(true)
  const loadMoreItems = () => {
    setPageSize(pageSize+50)
    setHasMore(data.length < totalItems)
  }

  //Popup with voucher details
  const [voucherDetailsPopupVisible, setVoucherDetailsPopupVisible] = useState(false)
  const [singleVoucherData, setSingleVoucherData] = useState<any>(null)
  //Get one voucher details
  const getOneVoucher = (id: any) => {
    axios.get(`${baseUrl}voucher-request/by-user/${id}`, config).then(res => setSingleVoucherData(res.data)).catch(e => console.log(e))
  }

  return(
    <div style={{maxHeight: "100vh", zIndex: 2, display: "flex", flexDirection: "column", alignItems: "center"}}>
      {(userIsDemo) ? <><Typography onClick={() => setOpenModal(true)} style={{fontSize: "1.2rem", background: "linear-gradient(232.69deg, #69B2A8 28.37%, #4E7A99 95.18%)", border: "none", color: "white"}} className='resumeDiv'>{t("vouchers.table.bannerDemo") }<span style={{color: "white", cursor: "pointer", border: (breakpoint !== "desktop") ? "none" : "1px solid white", padding: "3px", paddingLeft: "6px", paddingRight: "6px", borderRadius: "4px", marginLeft: "10px"}}> {t("vouchers.table.upgrade")}</span></Typography></> : <></>}
      <Typography ref={step1} style={DASHBOARD_TITLES} >{t("vouchers.title")}</Typography>
      <Button ref={step2} id='btn-secondary' style={(userIsDemo) ? {position: (breakpoint !== "desktop") ? "inherit" : "absolute", top: "100px", right: "40px", borderRadius: "4px", marginBottom: (breakpoint !== "desktop") ? "40px" : "0px"} : {position: (breakpoint !== "desktop") ? "inherit" : "absolute", top: "40px", right: "40px", borderRadius: "4px", marginBottom: (breakpoint !== "desktop") ? "40px" : "0px", maxWidth: (breakpoint === "mobile") ? "260px" : "auto"}} onClick={() => {showDrawer(); setUserCanUpdate(false)}} >{t("vouchers.addInvoiceButton")}</Button>
      <Drawer style={{zIndex: 1003}} keyboard={false} maskClosable={false} title={t("vouchers.form.title")} placement="right"  onClose={() => {onClose(false)}} open={open} width={(breakpoint !== "desktop") ? "100vw" : 1000}>
        <VoucherRequestForm resetOnClose={open} closeDrawer={onClose} voucherId={voucherId} userCanUpdate={userCanUpdate} />
      </Drawer>
      {(breakpoint === "mobile") ? 
      <Row style={{display: "flex", flexDirection: "row", gap: "10px", width: "100vw", justifyContent: "center"}}>
        <Col >
          <Input value={query} style={{fontFamily: "Poppins", fontSize: "18px", height: "38px", width: "80vw", borderColor: "lightgrey"}} placeholder={t("vouchers.table.filterInputPlaceholder") || ""} onChange={(e) => handleInputWordToFilter(e)}/>
        </Col>
        <Col>
          <div onClick={() => setPopupVisible(true)} style={{width: "11vw",height: "38px", borderRadius: "4px", border: "1px solid", borderColor: "lightgrey", display: "flex", justifyContent: "center"}}>
            <ControlOutlined style={{fontSize: "24px", color: "lightgray"}} color='#ADADAD'/>
          </div>
        </Col>
      </Row>
      :
      <Row style={{display: "flex", justifyContent: "normal", gap: "5px", width: "100%", paddingBottom: "15px"}}>
        <Col style={{display: "flex", gap: "20px"}}>
          <RangePicker size='small' value={(dateRange[0] !== "") ? [dayjs.unix(dateRange[0]), dayjs.unix(dateRange[1])] : [null, null]} format={"DD-MM-YYYY"} popupStyle={{margin: "auto"}} style={{height: "38px", borderRadius: "4px"}} onChange={(dates) => handleDateRange(dates)}></RangePicker>
        </Col>
        <Col style={{display: "flex", gap: "10px"}}>
          <Input value={query} style={{fontFamily: "Dosis", fontSize: "18px", height: "38px",}} placeholder={t("vouchers.table.filterInputPlaceholder") || ""} onChange={(e) => handleInputWordToFilter(e)}/>
        </Col>
        <Col style={{display: "flex"}}>
          <Button id='button-small-outline' onClick={clearFilters}>{t("vouchers.table.buttonClearFilters")}</Button>
        </Col>
      </Row>
      }
      {(breakpoint === "desktop") ?
      <Table ref={step21} loading={{spinning: (status === "loading"), indicator: loadingIcon}} style={{overflow: "hidden"}} scroll={{ y: "68vh" }} dataSource={( data === undefined || status === "error" || data.length === 0 ) ? [] : data.items } size="large" pagination={{showSizeChanger: true, showQuickJumper: false, responsive: true, total: (data === undefined) ? totalItems : data.total, onChange(page, pageSize) {setPage(page); setPageSize(pageSize) }, }}>
        <Column 
          width={"55px"}
          colSpan={1} 
          title={<div style={{display: "flex", alignItems: "center"}}>{t("vouchers.table.id")}<div style={{display: "flex", flexDirection: "column", height: "20px"}}><Button onClick={() => {setOrderBy("id"); setOrder("asc"); }} style={{border: "none", background: "none", height: "10px" }} icon={<CaretUpOutlined id='asc-desc-icons' style={{height: "10px", margin: "0px", padding: "0px", position: "absolute", top: 0, left: 5}}/>}></Button><Button onClick={() => {setOrderBy("id"); setOrder("desc")}} style={{border: "none", background: "none", height: "15px"}} icon={<CaretDownOutlined id='asc-desc-icons' style={{height: "10px", margin: "0px", padding: "0px", position: "absolute", top: 0, left: 5}}/>}></Button></div></div>} 
          dataIndex="id" 
          key="id" 
        />
        <Column
          width={"170px"}
          title={<div style={{display: "flex", alignItems: "center"}}>{t("vouchers.table.point_of_sale")}<div style={{display: "flex", flexDirection: "column", height: "20px"}}><Button onClick={() => {setOrderBy("point_of_sale"); setOrder("asc"); }} style={{border: "none", background: "none", height: "10px" }} icon={<CaretUpOutlined id='asc-desc-icons' style={{height: "10px", margin: "0px", padding: "0px", position: "absolute", top: 0, left: 5}}/>}></Button><Button onClick={() => {setOrderBy("point_of_sale"); setOrder("desc")}} style={{border: "none", background: "none", height: "15px"}} icon={<CaretDownOutlined id='asc-desc-icons' style={{height: "10px", margin: "0px", padding: "0px", position: "absolute", top: 0, left: 5}}/>}></Button></div></div>}
          dataIndex="point_of_sale"
          key="point_of_sale"
        />
        <Column width={"110px"} title={t("vouchers.table.type")} dataIndex="type" key="type" />
        <Column width={"130px"} title={t("vouchers.table.doc_number")} dataIndex="doc_number" key="doc_number" />
        <Column filterDropdown={selectStatus} width={"70px"} title={t("vouchers.table.status")} dataIndex="status" key="status" render={(_: any, { status }: any) => (
              <Tag color={(status === "pending")? "blue" : (status === "inProccess") ? "orange" : (status === "completed") ? "green" : "#c5c5c5"}>
                {(status === "pending") ? "Pendiente" : (status === "inProccess") ? "En Tramite" : (status === "completed") ? "Completada" : "Demo"}
              </Tag> )}
              />
        <Column width={"120px"} key={"modificar"} render={(_: any, record: any) => (
          (record.status === "inProccess" || record.status === "completed") ? (
          <Popover content={(record.status === "inProccess") ? popoverContentOnInProgress : popoverContentOnCompleted}>
            <Button id='button-small-outline' disabled style={{borderRadius: "4px"}} >{t("adminDashboardPage.vouchers.editButton")}</Button>
          </Popover>)
          :
          (<Button id='button-small-outline' style={{borderRadius: "4px"}} onClick={() => {setVoucherId(record.id); setUserCanUpdate(true); showDrawer()}} >{t("adminDashboardPage.vouchers.editButton")}</Button>)
        )} />
        <Column width={(userIsDemo) ? "120px" : "0px"} render={(_:any, record: any) => (
          (record.status === "demo" && userIsDemo === true) ?
          <Button onClick={() => showModal()} id='button-small-outline-green-bg' >{t("vouchers.table.processButton")}</Button>
          :
          (record.status === "demo" && userIsDemo === false) ?
          <Button onClick={() => {updateStatus(record.id)}} id='button-small-outline-green-bg'>{t("vouchers.table.processButton")}</Button>
          :
          <></>
        )}
         />
        <Column width={"120px"} render={(_:any, record: any) => (
          (record.status === "completed") ?
          <>
            <Button id='button-small-outline-green-bg' onClick={() => showPdf(record.id)}>{t("vouchers.table.downloadButton")}</Button>
          </>
          :
          <></>
        )}/>
      </Table>
      :
      <div style={{ marginTop: "15px", paddingBottom: "73px"}}>
        {
        (data === undefined) ? 
        <>{loadingIcon}</>
        :
        (data.lenght === 0 && data.status === "success") ?
        "No hay comprobantes"
        :
        <InfiniteScroll
        style={{display: "flex", flexDirection: "column", gap: "15px"}}
        loader={(data.length !== 0) ? <>{loadingIcon}</> : <div style={{display: "flex", flexDirection: "column", alignItems: "center", gap: "10px", fontFamily: "Poppins"}}>{t("vouchers.notFound")}<div style={{cursor: "pointer", color: "#03b9ac", textAlign: "center", border: "1px solid", borderRadius: "4px", width: "60%"}} onClick={() => clearFilters()}>{t("vouchers.deleteFilters")}</div></div>} 
        hasMore={hasMore}
        next={() => loadMoreItems()}
        dataLength={data.length} 
        endMessage={<div style={{ textAlign: 'center' }}>{"No hay más comprobantes"}</div>}
        >
        {
        data.items.map((item: any) => (
          <Row onClick={() => {getOneVoucher(item.id); setVoucherDetailsPopupVisible(true)}} style={{height: "130px", width: "95vw", backgroundColor: "#F2F0F0", borderRadius: "4px", display: "flex"}}>
            <Col span={(breakpoint === "mobile") ? 8 : 4  } style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
              <div style={{fontFamily: "Poppins", fontWeight: "600", width: "95px", height: "95px", borderRadius: "4px", backgroundColor: (item.status === "pending") ? "#9D9BFB" : (item.status === "demo") ? "#BDBDBD" : (item.status === "completed") ? "#6CD7A4" : (item.status === "inProccess") ? "#D7BF6C" : "#BDBDBD", color: "white", textTransform: "uppercase", display: "flex", justifyContent: "center", alignItems: "center", fontSize: "12px" }}>{(item.status === "pending") ? "Pendiente" : (item.status === "inProccess") ? "En Tramite" : (item.status === "completed") ? "Completada" : "Demo"}</div>
            </Col>
            <Col span={16} style={{fontFamily: "Poppins", display: "flex", flexDirection: "column", justifyContent: "space-evenly", paddingTop: "5px", paddingBottom: "5px", paddingRight: "5px"}}>
              <Row>
                <Typography style={{fontSize: "0.85rem", fontWeight: "600", fontFamily: "Poppins"}}>{t("vouchers.table.id") + ":"}</Typography><Typography style={{ fontSize: "0.85rem",fontWeight: "300", paddingLeft: "5px", fontFamily: "Poppins"}}>{item.id}</Typography>
              </Row>
              <Row>
                <Typography style={{fontSize: "0.85rem", fontWeight: "600", fontFamily: "Poppins"}}>{t("vouchers.table.point_of_sale") + ":"}</Typography><Typography style={{fontSize: "0.85rem",fontWeight: "300", paddingLeft: "5px", fontFamily: "Poppins"}}>{item.point_of_sale}</Typography>
              </Row>
              <Row>
                <Typography style={{fontSize: "0.85rem", fontWeight: "600", fontFamily: "Poppins"}}>{t("vouchers.table.type") + ":"}</Typography><Typography style={{fontSize: "0.85rem",fontWeight: "300", paddingLeft: "5px", fontFamily: "Poppins"}}>{item.type}</Typography>
              </Row>
              <Row>
                <Typography style={{fontSize: "0.85rem", fontWeight: "600", fontFamily: "Poppins"}}>{t("vouchers.table.doc_number") + ":"}</Typography><Typography style={{fontSize: "0.85rem",fontWeight: "300", paddingLeft: "5px", fontFamily: "Poppins"}}>{item.doc_number}</Typography>
              </Row>
            </Col>
          </Row>
        )
        )}
        </InfiniteScroll>
      }
      </div>
      }
      <Popup showCloseButton visible={popupVisible} onMaskClick={() => setPopupVisible(false)} onClose={() => setPopupVisible(false)} bodyStyle={{ height: 'auto' }} mask={true} closeOnSwipe>
        <div style={{gap: "20px", display: "flex", flexDirection: "column", paddingLeft: "25px", paddingRight: "25px", paddingTop: "45px", paddingBottom: "25px"}}>
          <Row>
            <Col span={24}>
              <RangePicker size='small' value={(dateRange[0] !== "") ? [dayjs.unix(dateRange[0]), dayjs.unix(dateRange[1])] : [null, null]} format={"DD-MM-YYYY"} popupStyle={{margin: "auto"}} style={{borderColor: "lightgrey", color: "#535353", height: "38px", borderRadius: "4px", width: "100%"}} onChange={(dates) => handleDateRange(dates)}></RangePicker>
            </Col>
          </Row>
          <Row style={{display: "flex", flexDirection: "row", alignItems: "center", gap: "15px"}}>
            <Typography style={{width: "89px", fontSize: "14px", fontFamily: "Poppins"}}>{t("vouchers.status")}</Typography><Select value={statusFilter} onChange={(value) => handleStatusFilter(value)} options={selectStatusOptions} style={{height: "38px", borderColor: "lightgrey", width: "auto"}}></Select>
          </Row>
          <Row style={{display: "flex", flexDirection: "row", alignItems: "center", gap: "15px"}}>
            <Typography style={{width: "89px", fontSize: "14px", fontFamily: "Poppins"}}>{t("vouchers.orderBy")}</Typography><Select value={orderBy} onChange={(value) => setOrderBy(value)} options={[{value:"id", label: "id"}, {label: "Punto de venta", value: "point_of_sale"}]} style={{height: "38px", borderColor: "lightgrey", width: "auto"}}></Select>
          </Row>
          <Row style={{display: "flex", flexDirection: "row", alignItems: "center", gap: "15px"}}>
            <Typography style={{width: "89px", fontSize: "14px", fontFamily: "Poppins"}}>{t("vouchers.order")}</Typography><Select value={order} onChange={(value) => setOrder(value)} options={[{value: "asc", label: "Ascendente"}, {value: "desc", label: "Descendente"}]} style={{height: "38px", borderColor: "lightgrey", width: "auto"}}></Select>
          </Row>
          <Row style={{display: "flex", flexDirection: "row", gap: "10px", width: "100%", justifyContent: "space-between"}}>
            <Col span={11} style={{display: "flex"}}>
              <Button style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center", padding: "0px"}} id='button-small-outline' onClick={clearFilters}>{t("vouchers.table.buttonClearFilters")}</Button>
            </Col>
            <Col span={11} style={{display: "flex"}}>
              <Button id='btn-secondary' style={{height: "38px", display: "flex", width: "100%", alignItems: "center", justifyContent: "center"}} onClick={() => setPopupVisible(false)}>{t("vouchers.applyFiltersButton")}</Button>
            </Col>
          </Row>
        </div>
      </Popup>
      {
      (singleVoucherData === null) 
      ?
      <></>
      :
      <Popup showCloseButton visible={voucherDetailsPopupVisible} onMaskClick={() => {setVoucherDetailsPopupVisible(false); setPopoverVisible(false)}} onClose={() => {setVoucherDetailsPopupVisible(false); setPopoverVisible(false)}} bodyStyle={{ height: 'auto', maxHeight: "90vh", overflow: "auto" }} mask={true} closeOnSwipe>
        <div style={{gap: "20px", display: "flex", flexDirection: "column", paddingLeft: "25px", paddingRight: "25px", paddingTop: "45px", paddingBottom: "25px"}}>
          <Row style={{display: "flex", flexDirection: "row", gap: "15px"}}>
            <Col span={(breakpoint === "mobile") ? 8 : 3} style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
              <div style={{fontFamily: "Poppins", fontWeight: "600", width: "95px", height: "95px", borderRadius: "4px", backgroundColor: (singleVoucherData.status === "pending") ? "#9D9BFB" : (singleVoucherData.status === "demo") ? "#BDBDBD" : (singleVoucherData.status === "completed") ? "#6CD7A4" : (singleVoucherData.status === "inProccess") ? "#D7BF6C" : "#BDBDBD", color: "white", textTransform: "uppercase", display: "flex", justifyContent: "center", alignItems: "center", fontSize: "12px" }}>{(singleVoucherData.status === "pending") ? "Pendiente" : (singleVoucherData.status === "inProccess") ? "En Tramite" : (singleVoucherData.status === "completed") ? "Completada" : "Demo"}</div>
            </Col>
            <Col span={14} style={{fontFamily: "Poppins", display: "flex", flexDirection: "column", justifyContent: "space-evenly", paddingTop: "5px", paddingBottom: "5px"}}>
              <Row>
                <Typography style={{fontWeight: "600"}}>{t("vouchers.table.id") + ":"}</Typography><Typography style={{fontWeight: "300", paddingLeft: "6px"}}>{singleVoucherData.id}</Typography>
              </Row>
              <Row>
                <Typography style={{fontWeight: "600"}}>{t("vouchers.form.date") + ":"}</Typography><Typography style={{fontWeight: "300", paddingLeft: "0px"}}>{<>{dayjs.unix(singleVoucherData.date).format("DD/MM/YYYY")}</>}</Typography>
              </Row>
            </Col>
          </Row>
          <div style={{width: "100%", fontSize: "10px", display: "flex", flexDirection: "column", gap: "10px"}}>
            <Row>
              <Typography style={{fontWeight: "600"}}>{t("vouchers.table.point_of_sale") + ":"}</Typography><Typography style={{fontWeight: "300", paddingLeft: "6px"}}>{singleVoucherData.point_of_sale}</Typography>
            </Row>
            <Row>
              <Typography style={{fontWeight: "600"}}>{t("vouchers.table.type") + ":"}</Typography><Typography style={{fontWeight: "300", paddingLeft: "6px"}}>{singleVoucherData.type}</Typography>
            </Row>
            <Row>
              <Typography style={{fontWeight: "600"}}>{t("vouchers.form.concept_selector") + ":"}</Typography><Typography style={{fontWeight: "300", paddingLeft: "6px"}}>{singleVoucherData.concept}</Typography>
            </Row>
            <Row>
              <Typography style={{fontWeight: "600"}}>{t("vouchers.form.VAT_conditions") + ":"}</Typography><Typography style={{fontWeight: "300", paddingLeft: "6px"}}>{singleVoucherData.vat_condition}</Typography>
            </Row>
            <Row>
              <Typography style={{fontWeight: "600"}}>{t("vouchers.form.doc_type") + ":"}</Typography><Typography style={{fontWeight: "300", paddingLeft: "6px"}}>{singleVoucherData.doc_type}</Typography>
            </Row>
            <Row>
              <Typography style={{fontWeight: "600"}}>{t("vouchers.form.doc_number") + ":"}</Typography><Typography style={{fontWeight: "300", paddingLeft: "6px"}}>{singleVoucherData.doc_number}</Typography>
            </Row>
            <Row>
              <Typography style={{fontWeight: "600"}}>{t("vouchers.form.surname_name_or_business_name") + ":"}</Typography><Typography style={{fontWeight: "300", paddingLeft: "6px"}}>{singleVoucherData.business_name}</Typography>
            </Row>
            <Row>
              <Typography style={{fontWeight: "600"}}>{t("vouchers.form.sale_conditions") + ":"}</Typography><Typography style={{fontWeight: "300", paddingLeft: "6px"}}>{singleVoucherData.sale_conditions}</Typography>
            </Row>
            <Row style={{paddingBottom: "5px"}}>
              <Typography style={{fontWeight: "600"}}>{t("vouchers.form.associated_vouchers") + ":"}</Typography>{(singleVoucherData.associated_vouchers.length === 0) ? <Typography style={{fontWeight: "300", paddingLeft: "6px"}}>{"No hay"}</Typography> : <></>}
            </Row>
            {
              (singleVoucherData.associated_vouchers.length === 0) ?
              <></>
              :
              <>
                {singleVoucherData.associated_vouchers.map(line => (
                  <Row>
                    <div style={{border: "1px solid", borderColor: "#535353", width: "100%", height: "34px", borderRadius: "4px", display: "flex", textAlign: "center", flexDirection: "row",  alignContent: "center", flexWrap: "wrap", justifyContent: "space-evenly"}}>
                      <Col  style={{fontSize: "10px"}}>
                        {line.type}
                      </Col>
                      <span style={{borderRight: "1px solid", borderColor: "#535353", marginTop: "-9px", marginBottom: "-9px"}}></span>
                      <Col  style={{fontSize: "10px"}}>
                        {line.point_of_sale}
                      </Col>
                      <span style={{borderRight: "1px solid", borderColor: "#535353", marginTop: "-9px", marginBottom: "-9px"}}></span>
                      <Col  style={{fontSize: "10px"}}>
                        {line.number}
                      </Col>
                      <span style={{borderRight: "1px solid", borderColor: "#535353", marginTop: "-9px", marginBottom: "-9px"}}></span>
                      <Col style={{fontSize: "10px"}}>
                        {dayjs.unix(line.date).format("DD/MM/YYYY")}
                      </Col>
                    </div>
                  </Row>
                ))}
              </>
            }
            <Row style={{paddingBottom: "5px"}}>
              <Typography style={{fontWeight: "600"}}>{t("vouchers.form.fourthStepTitle") + ":"}</Typography>
            </Row>
            {
              (singleVoucherData.voucher_request_lines.map(line =>(
                <Row>
                <div style={{border: "1px solid", borderColor: "#535353", width: "100%", height: "34px", borderRadius: "4px", display: "flex", textAlign: "center", flexDirection: "row",  alignContent: "center", flexWrap: "wrap", justifyContent: "space-evenly"}}>
                  <Col  style={{fontSize: "10px"}}>
                    {line.description}
                  </Col>
                  <span style={{borderRight: "1px solid", borderColor: "#535353", marginTop: "-9px", marginBottom: "-9px"}}></span>
                  <Col  style={{fontSize: "10px"}}>
                    {line.measure}
                  </Col>
                  <span style={{borderRight: "1px solid", borderColor: "#535353", marginTop: "-9px", marginBottom: "-9px"}}></span>
                  <Col  style={{fontSize: "10px"}}>
                    {line.quantity}
                  </Col>
                  <span style={{borderRight: "1px solid", borderColor: "#535353", marginTop: "-9px", marginBottom: "-9px"}}></span>
                  <Col  style={{fontSize: "10px"}}>
                    {Intl.NumberFormat("es-AR", {style: "currency", currency: "ARS"}).format(Number(line.unit_price))}
                  </Col>
                  <span style={{borderRight: "1px solid", borderColor: "#535353", marginTop: "-9px", marginBottom: "-9px"}}></span>
                  <Col style={{fontSize: "10px"}}>
                    {Intl.NumberFormat("es-AR", {style: "currency", currency: "ARS"}).format(Number(line.amount))}
                  </Col>
                </div>
              </Row>
              )))
            }
          </div>
          <Row style={{display: "flex", flexDirection: "row", gap: "10px", width: "100%", justifyContent: "space-between"}}>
            <Col span={11} style={{display: "flex"}}>
              {
                (singleVoucherData.status === "completed" || singleVoucherData.status === "inProccess") ?
                <Popover defaultOpen={false} open={popoverVisible} onOpenChange={(newOpen: boolean) => setPopoverVisible(newOpen)} title={<CloseOutlined onClick={() => setPopoverVisible(false)} />} content={(singleVoucherData.status === "inProccess") ? popoverContentOnInProgress : popoverContentOnCompleted}>
                  <Button disabled={singleVoucherData.status === "completed" || singleVoucherData.status === "inProccess"} style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center", padding: "0px"}} id='button-small-outline'>{t("vouchers.table.support")}</Button>
                </Popover>
                :
                <Button style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center", padding: "0px"}} id='button-small-outline' onClick={() => {setVoucherId(singleVoucherData.id); setUserCanUpdate(true); showDrawer()}}>{t("vouchers.editButton")}</Button>
              }
            </Col>
            <Col span={11} style={{display: "flex"}}>
              <Button hidden={singleVoucherData.status === "pending"} id='btn-secondary' style={{height: "38px", display: "flex", width: "100%", alignItems: "center", justifyContent: "center"}} onClick={(singleVoucherData.status === "completed") ? () => showPdf(singleVoucherData.id) : (singleVoucherData.status === "demo" && userIsDemo) ? () => showModal() : (singleVoucherData.status === "demo" && userIsDemo === false) ? () => {updateStatus(singleVoucherData.id)} : () => showModal()}>{(singleVoucherData.status === "completed") ? t("vouchers.table.downloadButton") : (singleVoucherData.status === "demo") ? t("vouchers.table.processButton") : ""}</Button>
            </Col>
          </Row>
        </div>
      </Popup>
      }
      {(breakpoint === "mobile")?
      <Popup showCloseButton visible={openModal && breakpoint === "mobile" } onClose={() => setOpenModal(false)} onMaskClick={() => setOpenModal(false)} bodyStyle={{ height: '95vh', overflow: "auto", display: "flex", flexDirection: "column" }} mask={true} closeOnSwipe>
        <Prices demoSelected={true} />
      </Popup>
      :
      <Modal open={openModal} onCancel={() => setOpenModal(false)} width={ "75vw"} maskClosable={true} closable footer={null}>
                    <Prices demoSelected={userIsDemo} />
                </Modal>}

      <Tour 
        open={openTour}
        onClose={() => {setOpenTour(false); queryClient.setQueryData("skipTour", true)}}
        steps={steps}
        indicatorsRender={(current: any, total: any) => (<></>)}
      />
    </div>
  )};