import React, { useEffect, useState } from 'react';
import {
  SyncOutlined,
  CopyOutlined,
  UserOutlined,
  LogoutOutlined,
  BackwardOutlined,
  LoadingOutlined,
  FormatPainterOutlined
} from '@ant-design/icons';
import { Button, Layout, Menu, MenuProps, Modal } from 'antd';
import '../DashBoardPage/DashboardPage.scss'
import { useLocation, useNavigate } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { DASHBOARD_MENU_HEIGHT, DASHBOARD_MENU_TEXT_COLOR, SIDER_BACKGROUND_COLOR } from '../../components/common/utils/styles';
import DashboardLayout from '../../components/common/DashboardLayout';
import {useTranslation} from "react-i18next";
import Drift from "react-driftjs";
import axios from 'axios';
import Prices from '../LandingPage/sections/Prices';
import { useBreakpoint } from 'use-breakpoint';
import { Header } from 'antd/es/layout/layout';
import { TabBar } from 'antd-mobile';
import {Helmet} from 'react-helmet'

const BREAKPOINTS = { mobile: 0, tablet: 768, desktop: 1280 }

const { Sider } = Layout;

const Dashboard: React.FC = () => {

  const { breakpoint, maxWidth, minWidth } = useBreakpoint(BREAKPOINTS);

  const queryClient = useQueryClient()
  const [collapsed, setCollapsed] = useState(false);
  const driftID = process.env.REACT_APP_DRIFT_ID || ""

  const { t } = useTranslation()
  const baseUrl = process.env.REACT_APP_BASE_URL
  const token = queryClient.getQueryData("adminToken")
  const config = {headers: {Authorization: `Bearer ${token}`}}

  const navigate = useNavigate()

  const [logoutIsLoading, setLogoutIsloading] = useState(false)

  const logout = () => {
    setLogoutIsloading(true)
    localStorage.removeItem("token");
    localStorage.removeItem("role");
    queryClient.clear();
    setTimeout(() => {
      navigate(0);
    }, 1000);
  }

  const showButtonBackToAdmin = queryClient.getQueryData("showButtonBackToAdmin")
  const userData: any = queryClient.getQueryData("userData")

  const backToAdmin = async () => {
    localStorage.removeItem("role")
    localStorage.removeItem("token")
    const adminToken: string = queryClient.getQueryData("adminToken") || ""
    localStorage.setItem("token", adminToken)
    localStorage.setItem("role", "admin")
    queryClient.setQueryData("role", "admin")
    queryClient.setQueryData("token", adminToken)
    await axios.get(`${baseUrl}user/me`, {headers: {Authorization: `Bearer ${JSON.parse(adminToken)}`}}).then( res => queryClient.setQueryData('userData', res.data)).catch(e => console.log(e))
    setTimeout(() => {
      navigate('/admin/users')
    }, 500);
    
  }

  //Modal that show plans
  const [open, setOpen] = useState(false);

  const showModal = () => {
    setOpen(true);
  };

  //Control the highlighted menu item
  const location = useLocation();
  const [selected, setSelected] = useState<[string]>([""])

  useEffect(() => {
    if (breakpoint !== "desktop") setCollapsed(true)
  }, [])
  

  useEffect(() => {
    if (location.search !== "") axios.get(`${baseUrl}user/me`, {headers: {Authorization: `Bearer ${token}`}}).then( res => queryClient.setQueryData('userData', res.data)).catch(e => console.log(e))
  }, [])

  useEffect(() => {
    if (location.pathname === '/dashboard/home' || location.pathname === '/dashboard/' ) navigate('/dashboard/vouchers');
    else if (location.pathname === '/dashboard/user') setSelected(["2"])
    else if (location.pathname === '/dashboard/billing') setSelected(["3"])
    else if (location.pathname === '/dashboard/vouchers') setSelected(["4"])
    else if (location.pathname === '/dashboard/customize') setSelected(["7"])

  }, [location.pathname])

  const isDemo = () => {
    if (userData.subscription === undefined || userData.subscription === null || userData.subscription.authorized === false) return true
    else {
      if (
        (userData.subscription.status === "authorized" ||
          userData.subscription.status === "oneshoot") &&
        userData.subscription.authorized === true
      )
        return false;
      else return true;
    }
  }

  useEffect(() => {
    const userData: any = queryClient.getQueryData("userData")

    if (userData !== undefined || userData !== null) {
      if (userData.lastLoginAt === null || userData.lastLoginAt === undefined) {queryClient.setQueryData("isFirstLogin", true)}
    }
  }, [])
  
  //Function that is triggered when click on menu items. It contains the routes inside the dasboard and the logout
  const onClick = (click: any) => {
    switch ( (breakpoint === "mobile" || breakpoint === "tablet") ? click : click.key) {
      case '1': navigate('/dashboard/home'); 
          break;
      case '2': navigate('/dashboard/user'); 
          break;
      case '3': navigate('/dashboard/billing'); 
          break;
      case '4': navigate('/dashboard/vouchers'); 
          break;
      case '7': navigate('/dashboard/customize'); 
          break;
      case '5': logout();
          break;
      case '6': backToAdmin()
          break;
      default: navigate('/dashboard/vouchers')
          break;
  }
  };

  const items: MenuProps['items'] = [
    {
      label: t('dashboardPage.menu.user'),
      key: 2,
      icon: <UserOutlined />,
      style: {height: "58px", borderRadius: "4px", display: "flex", width: (breakpoint === "mobile") ? "56px" : "auto", justifyContent: (breakpoint === "mobile") ? "center" : "inherit"}
    },
    {
      label: t('dashboardPage.menu.subscription'),
      key: 3,
      icon: <SyncOutlined />,
      style: {height: "58px", borderRadius: "4px",  display: "flex", width: (breakpoint === "mobile") ? "56px" : "auto", justifyContent: (breakpoint === "mobile") ? "center" : "inherit"}
    },
    {
      label: t('dashboardPage.menu.vouchers'),
      key: 4,
      icon: <CopyOutlined  />,
      style: {height: "58px", borderRadius: "4px",  display: "flex", width: (breakpoint === "mobile") ? "56px" : "auto", justifyContent: (breakpoint === "mobile") ? "center" : "inherit"}

    },
    {
      label: t('dashboardPage.menu.customize'),
      key: 7,
      icon: <FormatPainterOutlined />,
      style: {height: "58px", borderRadius: "4px",  display: "flex", width: (breakpoint === "mobile") ? "56px" : "auto", justifyContent: (breakpoint === "mobile") ? "center" : "inherit"}
    },
    {
      label: t('dashboardPage.menu.logout'),
      key: 5,
      icon: (logoutIsLoading) ? <LoadingOutlined spin /> : <LogoutOutlined />,
      style: {height: "58px", borderRadius: "4px",  display: "flex", width: (breakpoint === "mobile") ? "56px" : "auto", justifyContent: (breakpoint === "mobile") ? "center" : "inherit"}
    },
    {
      label: t('dashboardPage.menu.back_to_admin_button'),
      key: 6,
      icon: <BackwardOutlined />,
      style: {height: "58px", borderRadius: "4px",  display: "flex", width: (breakpoint === "mobile") ? "56px" : "auto", justifyContent: (breakpoint === "mobile") ? "center" : "inherit"},
      className: (showButtonBackToAdmin === undefined) ? "hidden" : ""
    }
  ]

  return (
    <Layout style={{ flexDirection: (breakpoint === "mobile" || breakpoint === "tablet") ? "column" : "row"}}>
      <Sider hidden={(breakpoint !== "desktop")} style={{ background: SIDER_BACKGROUND_COLOR, overflow: 'auto', height: '100vh', position: 'fixed', left: 0, top: 0, bottom: 0,}} collapsible collapsed={collapsed} onCollapse={(value) => {setCollapsed(value)}}>
        <div style={{ height: DASHBOARD_MENU_HEIGHT, padding: "15px", background: SIDER_BACKGROUND_COLOR , marginBottom: "25px"}} ><img src={`../../../logo-monotributo-white${(collapsed) ? "-mobile" : ""}.png`} alt='logo' width={"100%"}/></div>
        <Menu items={items} style={{background: SIDER_BACKGROUND_COLOR, color: DASHBOARD_MENU_TEXT_COLOR}} selectedKeys={selected} mode="inline" onClick={onClick} ></Menu>
      </Sider>
      <DashboardLayout collapsed={collapsed}></DashboardLayout>
      {(showButtonBackToAdmin !== undefined || breakpoint === "mobile") ? 
      <></>
      :
      (
          <Helmet>
            <script
              src="https://widget.flowxo.com/embed.js"
              data-fxo-widget="eyJ0aGVtZSI6IiMwM2I5YWMiLCJ3ZWIiOnsiYm90SWQiOiI2NDk5MjI4NjcyNzgyYzAwNTYwMDQxMjEiLCJ0aGVtZSI6IiMwM2I5YWMiLCJsYWJlbCI6Ik1vbm8gYm90In19"
              async
              defer
            ></script>
          </Helmet>
        )
      }
      <Modal open={open} onCancel={() => setOpen(false)} width={(breakpoint !== "desktop") ? "99vw" : "75vw"} maskClosable={true} closable  footer={null}>
        <Prices demoSelected={true} />
      </Modal>
      {(breakpoint === "mobile" || breakpoint === "tablet") ? 
      <TabBar safeArea={true} activeKey={selected[0] || "0"} style={{position: 'fixed', bottom: "0px", backgroundColor: "white", width: "100vw", height: "58px", display: "flex", flexDirection: "column", justifyContent: "center"}} onChange={onClick}>
        {
          items.map((item: any) => (
            <TabBar.Item className={(item.className === "hidden") ? "hidden" : ""} key={item.key} icon={item.icon} title={item.label} style={{borderRadius: "4px", fontSize: "12px"}}></TabBar.Item>
          ))
        }
      </TabBar>
      :
      <></>
      }
    </Layout>
  );
};

export default Dashboard;